import React, { useRef, useState, useEffect } from 'react';

function PhotoWidgetButton({ data, setData, title }) {
  const inputRef = useRef();

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setData(selectedFile);
    }
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const getTruncatedFileName = (fileName) => {
    if (fileName.length <= 50) {
      return fileName;
    }
    const extension = fileName.split('.').pop();
    return `...${fileName.slice(-47)}.${extension}`;
  };

  return (
    <div className="p-2">
      <h4 className="text-[12pt] font-semibold">{title}</h4>
      <div className="flex flex-col ">
        <button
          className="bg-blue-500 hover:bg-blue-400 text-white px-2 h-[30px] rounded flex justify-center align-middle items-center"
          onClick={handleClick}
        >
          <p className='text-[10pt] p-0 m-0'>Adicionar imagem</p>
        </button>
        <input
          type="file"
          onChange={handleFileSelect}
          accept="image/*"
          hidden
          ref={inputRef}
        />
        {data.imagem && (
          <p className="text-center mt-2">
            {getTruncatedFileName(data.imagem.name)}
          </p>
        )}
      </div>
    </div>
  );
}

export default PhotoWidgetButton;