import React from 'react'
import IndexCTA from 'Loja/components/compLoja/IndexCTA'
import ApresentacaoGenerica from 'Loja/components/compLoja/ApresentacaoGenerica'

function AplicativosIndex() {
    const data = {
        titulo: "SEU APLICATIVO",
        subtitulo: 'Tem uma ideia inovadora, ou até mesmo uma grande necessidade de automação?!',
        descricao: 'Aqui na Always Online, criamos sistemas e aplicativos personalizados, multiplataforma para qualquer demanda!'
    }

    return (
        <div className='flex flex-col min-h-[80vh]'>
            <ApresentacaoGenerica data={data} />
            <div className='w-full py-8 px-4 md:px-20 bg-gradient-to-r from-blue-500 to-red-500'>
                <div className='flex flex-col p-4 md:p-20 rounded-md bg-slate-100 shadow-md'>
                    <h3 className='text-blue-900 font-bold'>Criamos seu Aplicativo!</h3>
                    <p className='text-slate-800 font-semibold pl-2 mt-4'>Já parou para pensar que talvez <span className='text-blue-800'>seu concorrente não tenha a melhor ferramenta?</span></p>
                    <p className='text-slate-800 font-semibold pl-2'>Muitos sistemas surgem de uma ideia, e muitos de uma necessidade, seja para <span className='text-blue-800'>facilitar processos e rotinas</span> ou até mesmo uma sacada de utilidade que gera valor.</p>
                    <p className='text-slate-800 font-semibold pl-2'>O mercado de aplicativos ainda é <span className='text-blue-800'>uma das poucas áreas digitais</span> que poucos investem e exploram, por esse motivo é <span className='text-blue-800'>uma ótima oportunidade!</span></p>
                    <p className='text-slate-800 font-semibold pl-2'><span className='text-blue-800'>Mas por que poucas pessoas estão nesse mercado?</span> <br /> Pois muitos negócios ou empresas <span className='text-red-800'>não conseguem se enxergar nesse contexto digital</span> e optam por escolher soluções já presentes no mercado, o que pode ser mais custoso e menos eficiente que criar seu próprio app, <br />que <span className='text-blue-800'>além de ser uma utilidade poderosa, pode gerar uma enorme renda</span> e virar um negócio independente por si só!</p>

                </div>
            </div>
            <IndexCTA />
        </div>
    )
}

export default AplicativosIndex
