import React, {useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { cookie } from 'state/axios';

import{
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  IconButton,
  InputAdornment } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const LOGIN_URL = 'auth/authenticate';

export default function LoginForm(props) {
  const[user, setUser] = useState('');
  const[pwd, setPwd] = useState('');
  const[errMsg, setErrMsg] = useState(null); 
  const[token, setToken] = useState(null); 
  const[scssMsg, setScssMsg] = useState(null); 
  const nav = useNavigate();
  const [showPassword, setShowPassword] = useState(false);


  useEffect(()=> {
    getUser();
  },[])

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      nav('/admin');
    }
  }, []);



  async function getUser(){
    await cookie.get('/sanctum/csrf-cookie');
  }

const handleSubmit = async (e) => {
    e.preventDefault();
  
    try{
      const response = await axios({
        url: LOGIN_URL,
        method: 'POST',
        data: JSON.stringify({email: user, password: pwd}),
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',},
        withCredentials:true,     
      });
      localStorage.setItem('token' , response?.data?.token); 
      setToken(response?.data?.token)   

        setScssMsg('Login com feito sucesso');
        setTimeout(() => {
          setScssMsg(null);
        }, 2000);
        window.location.reload()

    }catch (err) {
      setErrMsg('Falha em Login.')
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
  };

  return (
    
    <Container component="main" maxWidth="xs">
      <Box
        sx={{  
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Faça seu Login
        </Typography>
        {errMsg ? 
          <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
            <p>  {errMsg} </p>
          </Box>
        : null}

        {scssMsg ? 
          <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
            <p> {scssMsg} </p>
          </Box>
        : null}

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Seu email"
            name="email"
            onChange={(e) => setUser(e.target.value)}
            value={user}
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Sua senha"
            type={showPassword ? 'text' : 'password'}
            id="password"
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Link onClick={() => props.onClose('/recuperarsenha')} variant="body2">
                esqueceu a senha?
              </Link>
            </Grid>
            <Grid item>
              <Link onClick={() => props.onClose('/novousuario')} variant="body2">
                {"Não tem uma conta? Registrar"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
