import React from 'react'
import IndexCTA from 'Loja/components/compLoja/IndexCTA'
import ApresentacaoGenerica from 'Loja/components/compLoja/ApresentacaoGenerica'

function SitesIndex() {
    const data = {
        titulo:"SEU SITE",
        subtitulo:'Um site para sua apresentação profissional ou sua empresa?!',
        descricao:'Aqui na Always Online, te ajudamos a ter sua presença digital profissional!'
    }
  return (
    <div>
        <ApresentacaoGenerica data={data}/>
        <div className='w-full py-8 px-4 md:px-20 bg-gradient-to-r from-blue-500 to-red-500'>
            <div className='flex flex-col p-4 md:p-20 rounded-md bg-slate-100 shadow-md'>
                <h3 className='text-blue-900 font-bold'>Sites Personalizados</h3>
                <p className='text-slate-800 font-semibold pl-2 mt-4'>Criamos seu site <span className='text-blue-800'>com sua identidade</span>. Nossos sites buscam <span className='text-blue-800'>passar a mensagem clara, objetiva e profissional sua ou do seu negócio</span>.</p>
                <p className='text-slate-800 font-semibold pl-2'>Ter um site passa mais profissionalismo, confiança e autoridade, essencial para a atualidade <span className='text-blue-800'>para se manter competitivo em um mercado</span> que cada dia é mais <span className='text-red-800'>digital!</span></p>
                <p className='text-slate-800 font-semibold pl-2'>Criamos sites institucionais, sites para negócios locais e para profissionais que têm como objetivo publicar com detalhes o seu trabalho, <span className='text-blue-800'>permitindo que o cliente te encontre</span>, te conheça, para assim aumentar suas chances e <span className='text-blue-800'>oportunidades.</span></p>

            </div>

        </div>
        <IndexCTA/>
    </div>
  )
}

export default SitesIndex