import React, {useEffect, useState} from 'react';
import { 
  Box, 
  CircularProgress,
} from "@mui/material";
import { GenGet } from 'state/api';
import CustomDataGrid from 'Admin/components/AdminComponents/CustomDataGrid';
import { useNavigate } from 'react-router-dom';

function NovoEcard() {
    const nav = useNavigate();
    const [metricas, setMetricas] = useState({});
    const [ativos, setAtivos] = useState(null);
    const [inativos, setInativos] = useState(null);
    const [ ativos_pendentes, setAtivosPendentes] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [refresh, setRefresh] = useState(false)
  
    useEffect(() => {
      async function getUsers() {
        try {
          const response = await GenGet('/adminuser/index');
          console.log(response)
          const ecardResponse = await GenGet(`/ecards/dashboard`);
          console.log(ecardResponse.ecards.ativos)
          setMetricas(response.metricas);
          setAtivosPendentes(response.users.ativos_pendentes);
          setAtivos(ecardResponse.ecards.ativos);
          setInativos(response.users.inativos);
          setIsLoading(false);
          setRefresh(false)
  
        } catch (err) {
          setIsLoading(false);
          setRefresh(false)
        }
      }
      getUsers();
    }, [refresh]);

    const handleEditarAtivo = (id) =>{
        const ecard = ativos.find(item => item.id === id);
        if (ecard) {
          nav(`/admin/ecards/gerenciar/${ecard.user_id}`);
        } else console.log(id)
      }
    
    const handleCriarInativo = (id) =>{
        const user = inativos.find(item => item.id === id);
        if (user) {
          nav(`/admin/ecards/gerenciar/${user.id}`);
        } else console.log(id)
      }    
    
    const handleCriarInativoPendente = (id) =>{
        const user = ativos_pendentes.find(item => item.id === id);
        if (user) {
          nav(`/admin/ecards/gerenciar/${user.id}`);
        } else console.log(id)
      }        

    const columns = ['ID', 'Nome','Email','Telefone'];
    const ecardColumns = ['id','Titulo','ID de Usuário','Usuário'];
    const actionsAtivos = [{ action: 'Editar', function: handleEditarAtivo }];
    const actionsInativosPendentes = [{ action: 'Criar', function: handleCriarInativoPendente }];
    const actionsInativos = [{ action: 'Criar', function: handleCriarInativo }];
  return (
    <div className={'flex flex-col gap-2 flex-wrap w-full text-[10pt] p-2'}>
    <h3 className='text-[14pt]'>Gerencie um Ecard</h3>
    <p className='text-[9pt] -mt-2'>Crie ou altere ecard, para um usuário cadastrado.</p>
    <hr/>
    {ativos && <CustomDataGrid rows={ativos} columns={ecardColumns} per_page={25} actions={actionsAtivos} title="Gerencie ecards cadastrados"/>}
    {ativos_pendentes && <CustomDataGrid rows={ativos_pendentes} columns={columns} per_page={25} actions={actionsInativosPendentes} title="Usuários ativos, sem ecard"/>}
    {inativos && <CustomDataGrid rows={inativos} columns={columns} per_page={25} actions={actionsInativos} title="Crie um ecard para um usuário inativo"/>}
    </div>
  )
}

export default NovoEcard