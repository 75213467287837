import React, { useState, useEffect } from 'react';
import Logo from 'Loja/assets/AlwaysMiniLogoNoBorder.png';
import ButtonsClasses from 'Loja/scenes/ecards/ButtonsClasses';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { GenGet, GenPost } from 'state/api';
const GLOBAL_URL = process.env.REACT_APP_BASE_URL;

function Ecard() {
    const navigate = useNavigate();
    const [conteudoCarregado, setConteudoCarregado] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [msg, setMsg] = useState(null);
    const [formData, setFormData] = useState({
      nome:'',
      email:''
    })
    const handleInput = (e) =>{
      setFormData({
        ...formData,
        [e.target.name] : e.target.value
      })
    }
    const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
    };

    const handleFormSubmit = () => {
      async function postData() {
        try {
          const response = await GenPost(`/user/lead/${data.slug}`, formData)
          setMsg(response.message);
  
          setTimeout(() => {
            setMsg(null);
            toggleModal();
          }, 3000);
  
        } catch (err) {

          setMsg(err.errors);
          setTimeout(() => {
            setMsg(null);
          }, 3000);
        }
      }
      postData();
    };
    const { slug } = useParams();
    const handleAlwaysClick = () => {
    navigate('/');
    };

    useEffect(() => {
        setTimeout(() => {
          setConteudoCarregado(true);
        }, 1000);
      }, []);
    
    useEffect(() => {
        async function getData() {
          try {
            const response = await GenGet(`/myecard/public/${slug}`);
            if (response.message === 'Página não encontrada.') {
              setNotFound(true);
            } else {
              setData(response);
              setLoading(false);
            }
          } catch (err) {
            setNotFound(true);
          }
        }
    
        getData();
      }, [slug]);

      const backgroundImageStyle = () => {
        if (data.background_pic) {
          return `url(${GLOBAL_URL}/storage/${data.background_pic})`;
        } else {
          return null;
        }
      };

  if (notFound) {
        return <Navigate to="/ecard" />;

  } else {
    return (
      <>
      { loading ? (<div className='h-[100vh] w-[100vw] flex justify-center align-middle items-center'><CircularProgress color="info" sx={{marginTop:'150px'}} /></div>) : (
        <div className=" flex flex-col w-[100%] h-[100%] min-h-[100vh] rounded-lg justify-center align-middle items-center overflow-hidden pt-10" 
        style={{ 
          backgroundColor:data.background_color, 
          background: backgroundImageStyle(),  
          backgroundSize: 'cover',
          backgroundRepeat:'no-repeat' }}>
          {conteudoCarregado ? (
            <>
              <div className={`flex flex-col justify-center align-middle items-center mb-2 p-0 ${data.font}`} 
                  style={{typography: { fontFamily: data.font} }}>
                  <div className={`flex flex-col justify-center max-w-[340px] align-middle items-center mb-2 p-0 ${data.font}`} 
                  style={{typography: { fontFamily: data.font} }}>
                    <div
                      className='relative w-[100%] min-w-[250px] max-w-[350px] sm:max-w-[50vw] cursor-pointer mb-2 rounded-full overflow-hidden border-2'
                      style={{ borderColor: data.ring_color, paddingBottom: '100%' }}
                    >
                      <div className='absolute inset-0 w-full max-w-[340px] h-full flex justify-center items-center'>
                        <div className='flex justify-center items-center'>
                          <img
                            src={data.temp_profile ? data.temp_profile : `${GLOBAL_URL}/storage/${data.profile_pic}`}
                            alt="Foto de Perfil"
                            className='w-full h-full object-cover'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
    
                  <div className='relative flex flex-col justify-center align-middle items-center'>
                    <h2 className={`text-[18pt] mx-4`} style={{color:data.font_color}}>{data.title}</h2>
                  </div>
                    <p className={`text-[12pt] -mt-3 -mr-14`} style={{color:data.font_color}}>{data.subtitle}</p>
                </div>
      
                <div className="flex flex-col justify-center items-center mb-10 w-[100%]">
                  {data.links.filter(item => item.active === 1).map((link, index) => (
                    <ButtonsClasses key={index} prop={link} type={{font: data.font, type:data.button_type, bgcolor:data.button_color, textcolor:data.button_text_color}} />
                  ))}
                </div>
                {data.lead_active === 1 && (
                  <div className='flex flex-col w-full'>
                  <hr/>
                  <ButtonsClasses key={99} prop={{name:'Receba minhas novidades!'}} action={toggleModal} type={{font: data.font, type:'lead', bgcolor:data.button_color, textcolor:data.button_text_color}} />
                  </div>
                  
                )}
                {isModalOpen && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-4 rounded w-[80%]">
                      <h2 className="text-xl mb-4">Fique por dentro das novidades!</h2>
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Nome</label>
                        <input type="text" name={'nome'} value={formData.nome} placeholder={'seu nome'} onChange={handleInput} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" />
                      </div>
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Email</label>
                        <input type="text" name={'email'} value={formData.email} placeholder={'seu email'} onChange={handleInput} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" />
                      </div>
                      {msg && (<p>{msg}</p>)}
                      <div className='flex flex-row-reverse gap-2'>
                        <button className="bg-blue-400 hover:bg-blue-500 admin-button-small px-2" onClick={handleFormSubmit}>
                          Confirmar
                        </button>
                        <button className="bg-gray-600 hover:bg-gray-500 admin-button-small px-2" onClick={toggleModal}>
                          Cancelar
                        </button>
                      </div>

                    </div>
                  </div>
                )}   
              <img src={Logo} className="w-[50px] h-[50px] cursor-pointer" onClick={handleAlwaysClick} />
            </>
          ) : (
            <CircularProgress color="info" sx={{marginTop:'150px'}} />
          )}
        </div>
      )
  
      }
      </>
    )
  }   

}

export default Ecard