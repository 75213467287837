import React, {useState, useEffect} from 'react'

const Admin = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  return(
    <div className='p-2 text-[10pt]'>
      <h3 className='text-[14pt] m-0'>Dashboard</h3>
      <p className='text-[9pt] -mt-1 py-0 pl-4'>Veja um seruso me performace.</p>
      <hr/>

    </div>
  );
};

export default Admin;