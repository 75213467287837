import React, {useEffect} from 'react'
import { CssBaseline} from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import EcommerceRoot from "./Ecommerce";
import AdminRoot from "./Admin";
import RequiredAuth from './Loja/components/RequireAuth';
import Login from './Admin/scenes/auth/Login';

function App() {


  return (
    <div className="App">

        <BrowserRouter>
            <CssBaseline />
            <Routes>
              <Route path="/*" element={<EcommerceRoot />} /> 
              <Route path="/admin/login" element={<Login/>} />  
              <Route element={<RequiredAuth/>}>
                <Route path="/admin/*" element={<AdminRoot/>} />
              </Route> 
            </Routes>
           
        </BrowserRouter>

    </div>
  );
}

export default App;