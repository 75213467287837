import React, { useState, useEffect } from 'react';

const ColorPicker = ({ setColor, field, current }) => {
  const [selectedColor, setSelectedColor] = useState(current);
  const [currentColor, setCurrentColor] = useState(current);
  const [cursorVisible, setCursorVisible] = useState(false);
  const [cursorColor, setCursorColor] = useState(current);
  const [hexValue, setHexValue] = useState(current);

  const updateCursor = (event, canvas) => {
    const boundingRect = canvas.getBoundingClientRect();
    const x = (event.touches ? event.touches[0].clientX : event.clientX) - boundingRect.left;
    const y = (event.touches ? event.touches[0].clientY : event.clientY) - boundingRect.top;
    handleColorChange(x, y, canvas);

    if (cursorVisible) {
      canvas.style.cursor = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='${encodeURIComponent(cursorColor)}'><circle cx='10' cy='10' r='10'/></svg>") 10 10, auto`;
    }
  };

  const handleColorChange = (x, y, canvas) => {
    const context = canvas.getContext('2d');
    const pixel = context.getImageData(x, y, 1, 1).data;
    const color = `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;
    setSelectedColor(color);
    setCursorColor(color);
  };

  const handleMouseEnter = () => {
    setCursorVisible(true);
  };

  const handleMouseLeave = () => {
    setCursorVisible(false);
  };

  const handleColorClick = () => {
    const hexColor = rgbToHex(selectedColor);
    setCurrentColor(hexColor);
    setHexValue(hexColor);
    setColor(field, hexColor);
  };

  useEffect(() => {}, [currentColor]);

  const rgbToHex = (rgb) => {
    const [r, g, b] = rgb.match(/\d+/g);
    const hex = (Number(r) << 16 | Number(g) << 8 | Number(b)).toString(16).padStart(6, '0');
    return `#${hex}`;
  };

  const drawColorWheel = (canvas) => {
    const context = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = canvas.width / 2;

    for (let angle = 0; angle < 360; angle++) {
      const startAngle = (angle - 1) * (Math.PI / 180);
      const endAngle = angle * (Math.PI / 180);

      context.beginPath();
      context.moveTo(centerX, centerY);
      context.arc(centerX, centerY, radius, startAngle, endAngle);
      context.closePath();

      const gradient = context.createRadialGradient(
        centerX,
        centerY,
        0,
        centerX,
        centerY,
        radius
      );
      gradient.addColorStop(0.1, 'white');
      gradient.addColorStop(0.5, `hsl(${angle}, 100%, 45%)`);
      gradient.addColorStop(1, `hsl(${angle}, 100%, 20%)`);

      context.fillStyle = gradient;
      context.fill();
    }
  };

  const handleHexChange = (event) => {
    const value = event.target.value;
    setHexValue(value);

    if (/^#[0-9A-F]{6}$/i.test(value)) {
      setSelectedColor(value);
      setCurrentColor(value);
      setColor(field, value);
    }
  };

  const textColorStyle = {
    backgroundColor: currentColor,
  };

  return (
    <div className='flex w-full items-center p-1 gap-2'>
      <div className='flex flex-col'>
        <input
          type='text'
          value={hexValue}
          onChange={handleHexChange}
          maxLength={7}
          className='rounded p-1 font-semibold w-[100px] border mb-1'
        />
        <div className={`font-semibold shadow-md pt-0 w-[40px] h-[40px] rounded-xl`} style={textColorStyle}></div>
      </div>
      <div className='w-[100px]'>
        <canvas
          width="80"
          height="80"
          style={{
            cursor: 'none',
            border: '4px solid silver',
            borderRadius: '50%',
          }}
          className='flex items-center align-middle justify-center'
          onMouseMove={(e) => updateCursor(e, e.target)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleColorClick}
          onTouchStart={(e) => {
            setCursorVisible(true);
            updateCursor(e, e.target);
          }}
          onTouchMove={(e) => updateCursor(e, e.target)}
          onTouchEnd={(e) => {
            handleColorClick();
            setCursorVisible(false);
          }}
          ref={(canvas) => {
            if (canvas) {
              drawColorWheel(canvas);
            }
          }}
        ></canvas>
      </div>
      <div className='flex flex-col'></div>
    </div>
  );
};

export default ColorPicker;
