import React, { useEffect, useState } from 'react';
import Apresentacao from 'Loja/components/compLoja/Apresentacao';
import MaisSobre from 'Loja/components/compLoja/MaisSobre';
import Carrocel from 'Loja/components/compLoja/Carrocel';
import ProductDescribe from 'Loja/components/compLoja/ProductDescribe';
import IndexCTA from 'Loja/components/compLoja/IndexCTA';
import ProductDrawer from 'Loja/components/compLoja/ProductDrawer';

function LojaIndex() {
  return (
    <div className="lpmain-container">
      <Apresentacao />
      <MaisSobre />    
      <ProductDescribe/>
      <ProductDrawer/>
      <IndexCTA/>
    </div>
  );
}

export default LojaIndex;