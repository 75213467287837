import React, { useState, useEffect } from 'react'
import DataGridLeads from 'Admin/components/DataGridsLeads';
import FormInputsLeads from 'Admin/components/Inputs/FormInputsLeads';
import { GenGet } from 'state/api';
import { CircularProgress } from '@mui/material';
import CustomDataGrid from 'Admin/components/AdminComponents/CustomDataGrid';

function LeadsIndex() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({})
  const [msg , setMsg] = useState(null);
  const [leadData, setLeadData] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (id) => {
    const lead = data.leads.find(item => item.id === id);
    if (lead) {
      setLeadData(lead);
      setIsModalOpen(true);
    } else console.log(id)
    };

    const handleCloseModal = () => {
        setLeadData(null)
        setIsModalOpen(false);
    };

  useEffect(() => {
      async function getData() {
        try {
          const response = await GenGet("/leads/admin")
          console.log(response)
          setData(response);
          setLoading(false);
          
        } catch (err) {
        }
      }
      getData();
    }, []);

    const handlePost = () =>{
      async function postData() {
        try {
          const response = await GenGet(`leads/update/${leadData.id}`, leadData)
          setMsg(response.message);
          setTimeout(() => {
            setMsg(null);
          }, 3000);

        } catch (err) {
          setMsg(err.message);
          setTimeout(() => {
            setMsg(null);
          }, 3000);
        }
      }

      if (leadData){
        postData();
      } else {
        setMsg('Nenhum Lead selecionado.');
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }

    const cols = ['ID', 'Nome', 'Email','Ramo'];
    const actions = [{ action: 'Visualizar', function: handleOpenModal }];

  return (<>
    { !loading ? (
      <div className='p-2 text-[10pt]'>
        <h3 className='text-[14pt] m-0'>Painel de Leads</h3>
        <p className='text-[9pt] -mt-1 py-0 pl-4'>visualize seus leads.</p>
        <hr/>
        <CustomDataGrid rows={data.leadsData} columns={cols} actions={actions} per_page={25} title={"Leads"} />
        <div className='flex flex-col'>
          <h3 className='font-bold text-[12pt]'>Dados Sobre Cadastros:</h3>
          <div className='flex justify-start gap-4 mt-4 p-4 lg:flex-row flex-col'>
            <div className='flex justify-start md:flex-row flex-col mt-4 p-4 gap-4 rounded-lg bg-slate-300'>
                <div className='flex flex-row'>
                  <p>Leads cadastrados: </p>  
                  <p className='ml-2'>{data.leads_total}</p>
                </div>
            </div>
          </div>
          {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                        <h3 className="text-[14pt] m-0">Lead</h3>
                        <p className="text-[9pt] -mt-1 py-0 pl-4">visualize ou edite seu lead</p>
                        <hr/>
                        <p className='m-0 p-0'>nome : {leadData.nome}</p>
                        <p className='m-0 p-0'>email : {leadData.email}</p>
                        <p className='m-0 p-0'>telefone : {leadData.telefone}</p>
                        <p className='m-0 p-0'>origem : {leadData.nome}</p>
                        <p className='m-0 p-0'>ramo: {leadData.ramo}</p>
                        <br/>
                        <p >endereço : {leadData.endereco} - {leadData.end_numero}</p>
                        <p className='m-0 p-0'>descrição:</p>
                        <p >{leadData.descricao}</p>
                        <div className="flex justify-end space-x-4">
                            <button
                                className="bg-gray-500 hover:bg-gray-600 admin-button-small"
                                onClick={handleCloseModal}
                            >
                                Fechar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
  </div>
    ) : (
      <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
      <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
        <div className='flex flex-col items-center'>
          <CircularProgress />
        </div>
      </div>
    </div>
    )}
  </>
  )
}

export default LeadsIndex