import axios, {cookie, formMulti} from "./axios";

async function GenPost(url, data) {
  try{
    const response = await axios.post(url, data);
    return response.data
  
  } catch (err) {
    return err.response.data;
  }
}

async function GenGet(url) {
  try{
    const response = await axios.get(url);
    return response.data
  
  } catch (err) {
    return err.response.data;
  }
}

async function GenPostMultiform(url, data) {
  try{
    const response = await formMulti.post(url, data);
    return response.data
  
  } catch (err) {
    return err.response.data;
  }
}

export {
  GenPost,
  GenGet,
  GenPostMultiform,
  }

