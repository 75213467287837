import React, { useState, useEffect } from 'react'
import { GenPost, GenGet } from 'state/api';
import { useParams } from 'react-router-dom';
import DadosCliente from './DadosCliente';
import EditarDadosCliente from './EditarDadosCliente';
import UsuarioAssinatura from '../Assinaturas/UsuarioAssinatura';

function EditarUsuario() {
    const { id } = useParams();
    const [data, setData]= useState({});
    const [refresh, setRefresh]= useState(false);
    const [clienteData, setClienteData]= useState({});
    const [clienteCadastro, setClienteCadastro] = useState(false);
    const [ loading, setLoading] = useState(true);

      const [formData, setFormData] = useState({
          name:                   "",
          email:                  "",   
          telefone:               "",
          password:               "", 
          confirmpass:            "",
        });

      const [validationErrors, setValidationErrors] = useState({
        name:                   "",
        email:                  "",   
        telefone:               "",
        password:               "", 
        confirmpass:            "",
        });

        const handleChange = (e) => {     
          setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
          setValidationErrors({
            ...validationErrors,
            [e.target.name]: e.target.value ? "" : "Campo obrigatório.",
          });
      }


      const handlePost = () =>{
        async function postData() {
          try {
            const response = await GenPost("/auth/register", formData)
            setData(response);
            console.log(response)
          } catch (err) {
            console.log(formData);
          }
        }
        postData();
      }
      async function getUser() {
        try {
          const response = await GenGet(`/user/show/${id}`);
          setFormData({
            ...formData,
            name: response.usuario.name,
            email: response.usuario.email,
            telefone: response.usuario.telefone,      
          });
          if (response.cliente){
            setClienteData(response.cliente);
            setClienteCadastro(true);
          }
                      
          setLoading(false);
          setRefresh(false)
        } catch (err) {
        }
      }

      useEffect(() => {
        if (id) {  
          getUser();
        }
      }, [id]);

      useEffect(() => {
        if (refresh) {  
          getUser();
        }
      }, [refresh]);


  return (
    <div className='rounded-md flex w-full bg-slate-50 overflow-x-hidden overflow-y-scroll no-scrollbar'>
                    <div className=' w-[100vw] sm:w-[85vw] h-[89vh] p-2 '>
                        <div className='flex flex-col text-left overflow-hidden'>
                            <h2 className='input-classes text-lg'>Atualizar Usuário</h2>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Nome: 
                                </label><input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="name"
                                    name="name"
                                    value={formData.name} 
                                    onChange={handleChange}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Telefone: 
                                </label><input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="telefone"
                                    name="telefone"
                                    value={formData.telefone} 
                                    onChange={handleChange}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>E-mail: 
                                </label><input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Senha: 
                                </label><input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="password"
                                    name="password"
                                    type='password'
                                    value={formData.password}
                                    onChange={handleChange}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Confirmar Senha: 
                                </label><input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="confirmpass"
                                    type='password'
                                    name="confirmpass"
                                    value={formData.confirmpass}
                                    onChange={handleChange}  
                                    />
                            </div>
                            
                            <div className='flex w-full justify-end gap-2 mt-4 p-4'>
                                <button className='bg-cyan-600 w-[200px] rounded hover-bg-cyan-500' onClick={() => handlePost}>
                                    Cadastrar
                                </button>

                            </div>
                        </div>
                        {clienteCadastro && clienteData ? (<EditarDadosCliente clientData={clienteData} handleRefresh={setRefresh}/>):(<DadosCliente user_id={id} handleRefresh={setRefresh}/>)}
                        <UsuarioAssinatura/>
                    </div>
                </div>
  )
}

export default EditarUsuario