import React, { useState } from 'react'
import { GenPost } from 'state/api';

function NovoUsuario() {
  const [msg, setMsg] = useState(null);
  const [formData, setFormData] = useState({
    nome:'',
    sobrenome:'',
    email:'',
    cpf:'',
    telefone:'',
    password:'',
    password_confirmation:'',
  });

  const handleChange = (e) =>{
    setFormData({
      ...formData,
      [e.target.name]:e.target.value
    })
  }

  const validateForm = () => {
    const { nome, sobrenome, email, cpf, telefone, password, password_confirmation } = formData;

    // Verifica se algum campo está vazio
    if (!nome || !sobrenome || !email || !cpf || !telefone || !password || !password_confirmation) {
      setMsg('Todos os campos são obrigatórios');
      setTimeout(() => {
        setMsg(null);
      }, 4000);
      return false;
    }

    // Verifica se as senhas coincidem
    if (password !== password_confirmation) {
      setMsg('Os campos de senha não conferem');
      setTimeout(() => {
        setMsg(null);
      }, 4000);
      return false;
    }

    return true;
  };

  const formatarTelefone = (valor) => {
    // Remove todos os caracteres não numéricos
    const cleaned = valor.replace(/\D/g, '');
    // Aplica a máscara
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return `(${match[1]})${match[2]}-${match[3]}`;
    }
    return valor;
  };

  const handlePost = () =>{
    if (!validateForm()){
      return;
    }

        async function postData() {
          try {

            const response = await GenPost("/auth/adminregister", formData)
            setMsg(response.message)
            console.log(response)
            setTimeout(() => setMsg(null), 3000);

          } catch (err) {
            setMsg(err.message)
            console.log(err)
            setTimeout(() => setMsg(null), 3000);
          }
        }
        postData();
  }

  return (
      <div className='flex flex-col w-full p-2 gap-1 text-gray-900 '>
          <h3 className='text-[14pt] text-gray-900 m-0 p-0'>CRIE UMA CONTA!</h3>
          <p className='text-[10pt] text-gray-900 m-0 px-4 py-0'>cadastre um usuário em nosso sistema</p>
          <hr/>
          <div className='flex flex-col px-4 gap-2'>
            <input 
              className='p-1 rounded-lg custom-input text-slate-600' 
              placeholder="Nome"
              name='nome' 
              value={formData.nome}
              onChange={handleChange}/>
            <input 
              className='p-1 rounded-lg custom-input text-slate-600' 
              placeholder="Sobrenome"
              name='sobrenome' 
              value={formData.sobrenome}
              onChange={handleChange}/>
            <input 
              className='p-1 rounded-lg custom-input text-slate-600' 
              placeholder="E-mail"
              name='email'  
              value={formData.email}
              onChange={handleChange}/>
            <input
              className='p-1 rounded-lg custom-input text-slate-600'
              id="telefone"
              name="telefone"
              maxLength={14}
              placeholder="Telefone"
              value={formatarTelefone(formData.telefone)}
              onChange={handleChange}/>
            <input
              className='p-1 rounded-lg custom-input text-slate-600'
              id="cpf"
              name="cpf"
              placeholder="CPF"
              maxLength={11}
              value={formData.cpf} 
              onChange={(e) => {
                // Remove all non-numeric characters
                const value = e.target.value.replace(/\D/g, '');
                
                // Call handleChange once with the modified value
                handleChange({
                  target: {
                    name: 'cpf',
                    value,
                  },
                });
              }}
            />
            <input 
              className='p-1 rounded-lg custom-input text-slate-600' 
              placeholder="Senha" 
              name='password' 
              value={formData.password}
              onChange={handleChange}
              type={'password'}/>
            <input 
              className='p-1 rounded-lg custom-input text-slate-600' 
              placeholder="Confirme a senha" 
              name='password_confirmation' 
              value={formData.password_confirmation}
              type={'password'}
              onChange={handleChange}/>
          </div>
          <div className='h-[40px] flex flex-row-reverse'>
          {msg && (
            <p>{msg}</p>
          )}   
          </div>
       
          <div className='w-full flex flex-row-reverse p-2'>
            <button className='bg-blue-400 p-1 hover:bg-blue-500 text-white flex justify-center align-middle items-center rounded-sm min-w-[150px]' onClick={handlePost}> cadastrar </button>
          </div>
        </div>
  )
}

export default NovoUsuario