import React from 'react';
import ecard1 from '../assets/ecard/ecardBruno.png';
import ecard2 from '../assets/ecard/ecardNani.png';
import ecard3 from '../assets/ecard/ecardPet.png';
function EcardCarrocel() {
  const backgroundImageStyle1 = {
    backgroundImage: `url(${ecard1})`,
    backgroundSize: '100% 100%', // Cobrir toda a área da div
    backgroundRepeat: 'no-repeat', // Evita a repetição da imagem de fundo
    backgroundPosition: 'center', 
  };
  const backgroundImageStyle2 = {
    backgroundImage: `url(${ecard2})`,
    backgroundSize: '100% 100%', // Cobrir toda a área da div
    backgroundRepeat: 'no-repeat', // Evita a repetição da imagem de fundo
    backgroundPosition: 'center', 
  };
  const backgroundImageStyle3 = {
    backgroundImage: `url(${ecard3})`,
    backgroundSize: '100% 100%', // Cobrir toda a área da div
    backgroundRepeat: 'no-repeat', // Evita a repetição da imagem de fundo
    backgroundPosition: 'center', 
  };

  return (
      <div className=' flex w-full h-[100%] p-2 absolute'>
        <div className='carrossel-item-left shadow-xl rounded-lg flex justify-center items-center align-middle border-2 border-red-400' style={backgroundImageStyle2}></div>
        <div className='carrossel-item shadow-xl rounded-lg flex justify-center items-center align-middle  border-2 border-cyan-600' style={backgroundImageStyle1}></div>
        <div className='carrossel-item-right shadow-xl rounded-lg flex justify-center items-center align-middle border-2 border-yellow-500' style={backgroundImageStyle3}></div>
      </div>
  );
}

export default EcardCarrocel;