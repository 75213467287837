import React, { useRef } from 'react';

function PhotoWidgetButtonV2({ data, setData, element: Element }) {
  const inputRef = useRef();

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setData(selectedFile);
    }
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div className="p-2 cursor-pointer" onClick={handleClick}>
      <div className="flex flex-col items-center">
        <input
          type="file"
          onChange={handleFileSelect}
          accept="image/*"
          hidden
          ref={inputRef}
        />
        {data && <Element {...data} />}
      </div>
    </div>
  );
}

export default PhotoWidgetButtonV2;