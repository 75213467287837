import {useLocation, Navigate, Outlet} from "react-router-dom";
import {useEffect, useState} from 'react';
import { CombinedAuth, UserAuth, UserSubscription } from "context/Auth";
import { Box, CircularProgress } from "@mui/material";

const RequiredAuth = () => {   
  const location = useLocation();
  const [userAllowed, setUserAllowed] = useState(false);
  const [adminAllowed, setAdminAllowed] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [userIsLogged, setUserIsLogged] = useState(false);
  const [userIsAdmin, setUserIsAdmin] = useState(false);
    
  useEffect(() => {
    async function AuthAttempt() {
      try {
        const authResult = await CombinedAuth();
        const isLogged = authResult.user;
        const isAdmin = authResult.adm;
              
        if (isLogged && isAdmin) {
          setAdminAllowed(true);
        } else if (isLogged && !isAdmin){
          setUserAllowed(true);
        }
              
        setIsCheckingAuth(false);
        setUserIsLogged(isLogged);
        setUserIsAdmin(isAdmin);
      } catch (err) {
        setIsCheckingAuth(false);
      }
    }
    
    AuthAttempt();
  },[]);
    
  if (isCheckingAuth) {
    return       (<Box sx={{ p: 1, m: 1, width: {md:"100%"}, minHeight:'64vh'}} display="flex"
    justifyContent="center" flexDirection={'column'}>
      <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>
    </Box>);
  }
    
  if (userIsLogged && userIsAdmin && adminAllowed) {
    return <Outlet />;
  } else if (userIsLogged && !userIsAdmin && userAllowed) {
    return <Navigate to="/" replace state={{ from: location }} />;
  } else {
    localStorage.removeItem("token");
    return <Navigate to="/admin/login" replace state={{ from: location }} />;
  }
}

export default RequiredAuth;



export function ReqUserAuth(){   
  const location = useLocation();
  const [userAllowed, setUserAllowed] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [userIsLogged, setUserIsLogged] = useState(false);

    
  useEffect(() => {
    async function AuthAttempt() {
      try {
        const authResult = await UserAuth();
        const isLogged = authResult;
        if (isLogged) {
          setUserAllowed(isLogged);
        }
              
        setIsCheckingAuth(false);
        setUserIsLogged(isLogged);

      } catch (err) {
        setIsCheckingAuth(false);
      }
    }
    
    AuthAttempt();
  },[]);
    
  if (isCheckingAuth) {
    return (      <Box sx={{ p: 1, m: 1, width: {md:"100%"}, minHeight:'64vh'}} display="flex"
    justifyContent="center" flexDirection={'column'}>
      <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>
    </Box>);
  }
    
  if (userAllowed) {
    return <Outlet />;

  } else {
    localStorage.removeItem("token");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
}

export function ReqUseSubscription(){   
  const location = useLocation();
  const [userAllowed, setUserAllowed] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    
  useEffect(() => {
    async function AuthAttempt() {
      try {
        const authResult = await UserSubscription();
        const isLogged = authResult;
        if (isLogged) {
          setUserAllowed(isLogged);
        }
              
        setIsCheckingAuth(false);

      } catch (err) {
        setIsCheckingAuth(false);
      }
    }
    
    AuthAttempt();
  },[]);
    
  if (isCheckingAuth) {
    return (      
    <Box sx={{ p: 1, m: 1, width: {md:"100%"}, minHeight:'64vh'}} display="flex"
    justifyContent="center" flexDirection={'column'}>
      <Box display={'flex'} justifyContent={'center'} sx={{width:'100%', marginTop:"10vh", marginBottom:"10vh"}}><CircularProgress color="success"/></Box>
    </Box>);
  }
    
  if (userAllowed) {
    return <Outlet />;

  } else {
    return <Navigate to="/subscribe" state={{ from: location }} replace />;
  }
}

