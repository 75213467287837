import React, { useState } from 'react'
import { GenPost } from 'state/api';
import { Alert } from '@mui/material';

function NovoCupom({handleRefresh}) {
    const [data, setData]= useState({});
    const [showErrors, setShowError] = useState(false);
    const [msg, setMsg] = useState(null);
    const [formData, setFormData] = useState({
        nome:                   "",
        descricao:              "",
        user_id:                1,
        codigo:                 "",
        tipo:                   "campanha",   
        validade:               "",
        tipo_desconto:          "porcentagem", 
        valor_desconto:         "",
        ativo:                  false,
      });

    const [validationErrors, setValidationErrors] = useState({
        nome:                   "",
        descricao:              "",
        user_id:                "",
        codigo:                 "",
        tipo:                   "",   
        validade:               "",
        tipo_desconto:          "", 
        valor_desconto:         "",
        ativo:                  "",
      });

    const handleChange = (e) => {    
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value; 
          setFormData({
            ...formData,
            [e.target.name]: value,
          });
          setValidationErrors({
            ...validationErrors,
            [e.target.name]: value ? "" : "Campo obrigatório.",
          });
      }

    const handlePost = () =>{
      const errors = {};
      let hasErrors = false;
  
      for (const key in formData) {
        if (formData[key] === "") { 
          errors[key] = "Campo obrigatório.";
          hasErrors = true;
        }
      }
  
      setValidationErrors(errors);
  
      if (hasErrors) {
        setShowError(true);
        setMsg("Por favor, preencha todos os campos.");
  
        setTimeout(() => {
          setShowError(false);
          setMsg(null);
        }, 4000);
  
        return;
      }

      async function postData() {
        try {
          console.log(formData)
          const response = await GenPost("/cupoms/store", formData)
          setData(response);
          handleRefresh(true);
          console.log(response)
        } catch (err) {
          console.log(formData);
        }
      }
      postData();
    }

  return (
    <div className='rounded-md flex w-full bg-slate-50 overflow-x-hidden overflow-y-scroll no-scrollbar'>
                    <div className=' w-[100vw] sm:w-[85vw] h-[89vh] p-2 '>
                        <div className='flex flex-col text-left overflow-hidden'>
                            <h2 className='input-classes text-lg'>Novo Cupom</h2>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Nome: 
                                </label><input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="nome"
                                    name="nome"
                                    value={formData.nome} 
                                    onChange={handleChange}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Descrição: 
                                </label><input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="descricao"
                                    name="descricao"
                                    value={formData.descricao} 
                                    onChange={handleChange}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Código: 
                                </label><input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="codigo"
                                    name="codigo"
                                    value={formData.codigo} 
                                    onChange={handleChange}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Tipo: 
                                </label>
                                <select
                                        className="w-[200px] bg-slate-300 rounded m-2 flex p-1.5"
                                        aria-label="tipo"
                                        onChange={handleChange}
                                        id="tipo"
                                        name="tipo"
                                        value={formData.tipo}
                                    >
                                        <option value={'campanha'} >Campanha</option>
                                        <option value={'parceria'} >Parceria</option>
                                        <option value={'afiliacao'} >Afiliaçao</option>
                                </select>
                            </div>

                            <div className='flex flex-col justify-between'>
                                <label className=''>Validade: 
                                </label><input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="validade"
                                    name="validade"
                                    type="date"
                                    value={formData.validade}
                                    onChange={handleChange}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Tipo do Desconto: 
                                </label>
                                <select
                                        className="w-[200px] bg-slate-300 rounded m-2 flex p-1.5"
                                        aria-label="tipo_desconto"
                                        onChange={handleChange}
                                        id="tipo_desconto"
                                        name="tipo_desconto"
                                        value={formData.tipo_desconto}
                                        error={!!validationErrors.tipo_desconto}
                                        helperText={validationErrors.tipo_desconto}
                                    >
                                        <option value={'valor'} >Valor</option>
                                        <option value={'porcentagem'} >Porcentagem</option>

                                    </select>
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Valor: 
                                </label><input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="valor_desconto"
                                    name="valor_desconto"
                                    value={formData.valor_desconto}
                                    onChange={handleChange}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Destinado a usuário: 
                                </label>
                                <select
                                        className="w-[200px] bg-slate-300 rounded m-2 flex p-1.5"
                                        aria-label="user_id"
                                        onChange={handleChange}
                                        id="user_id"
                                        name="user_id"
                                        value={formData.user_id}
                                        error={!!validationErrors.user_id}
                                        helperText={validationErrors.user_id}
                                    >
                                        <option value={1} >Usuario 1</option>
                                        <option value={2} >Usuario 2</option>
                                        <option value={3} >Usuario 3</option>

                                    </select>
                            </div>
                            <div className='flex flex-row justify-start gap-2'>
                                <label className=''>Ativo: 
                                </label><input className={` bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="ativo"
                                    name="ativo"
                                    type="checkbox"
                                    value={formData.ativo}
                                    onChange={handleChange}  
                                    />
                            </div>
                            
                            <div className='flex w-full justify-end gap-2 mt-4 p-4'>
                                {msg ? (
                                  <div className='h-[40px]'>
                                    <Alert severity="info" > {msg} </Alert>
                                  </div>) : (null)}
                                <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handlePost}>
                                    Cadastrar
                                </button>

                            </div>
                        </div>

                    </div>
                </div>
  )
}

export default NovoCupom