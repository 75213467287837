import React, {useEffect, useState} from 'react';
import { 
  Box, 
  CircularProgress,
} from "@mui/material";
import { GenGet } from 'state/api';
import CustomDataGrid from 'Admin/components/AdminComponents/CustomDataGrid';

function UsuariosIndex() {
  const [metricas, setMetricas] = useState({});
  const [ativos, setAtivos] = useState(null);
  const [inativos, setInativos] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    async function getUsers() {
      try {
        const response = await GenGet('/adminuser/index');
        setMetricas(response.metricas);
        setAtivos(response.users.ativos);
        setInativos(response.users.inativos);
        setIsLoading(false);
        setRefresh(false)

      } catch (err) {
        setIsLoading(false);
        setRefresh(false)
      }
    }
    getUsers();
  }, [refresh]);

    const columns = ['ID', 'Nome','Email','Telefone'];

  return (
    <div className={'p-2 text-[10pt]'}>
      <h3 className='text-[14pt] m-0'>Painel de Usuários</h3>
      <p className='text-[9pt] -mt-1 py-0 pl-4'>Visualize e gerencie seus usuários.</p>
      <hr/>
      <div className='flex flex-row w-full flex-wrap justify-between'>
        <div className='p-2 rounded-md border-[1px]'>
          <p>Cadastrados: {metricas.cadastrados}</p>
        </div>

        <div className='p-2 rounded-md border-[1px]'>
          <p>Ativos: {metricas.ativos}</p>
        </div>

        <div className='p-2 rounded-md border-[1px]'>
          <p>Inativos: {metricas.inativos}</p>
        </div>
        
          
      </div>
    {!isLoading ? (
          <div className='min-h-[400px] w-full p-4'>
          <h3 className='text-[12pt]' variant={'h6'}>Usuarios:</h3>
          {ativos && <CustomDataGrid rows={ativos} columns={columns} per_page={25}  title="Usuários Ativos"/>}
          {inativos && <CustomDataGrid rows={inativos} columns={columns} per_page={25} title="Usuários inativos"/>}
            <br/>
          </div>
    ) : (
      <div className='flex justify-center w-full mt-[10vh] mb-[10vh]'>
        <CircularProgress color="success"/>
      </div>)}
  </div>
  );
};
export default UsuariosIndex;