import React from 'react'

function CampanhasEmail() {
  return (
    <div className='p-2 text-[10pt]'>
      <h3 className='text-[14pt] m-0'>Campanhas de email</h3>
      <p className='text-[9pt] -mt-1 py-0 pl-4'>será implementado em breve</p>
      <hr/>
    </div>
  )
}

export default CampanhasEmail