import React, {useState} from "react";
import { GenPost } from "state/api";

function NovoLead() {
  const [msg, setMsg] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    nome: "",
    origem: "",
    endereco: "",
    end_numero: "",
    ramo: "",
    descricao: "",
    telefone: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePost = () => {
    if (!formData.email || !formData.nome || !formData.origem ){
        setMsg('Campos "email", "nome" e "origem" são obrigatórios' );
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        return;
    }


    async function postData() {
      try {
        const response = await GenPost("/leads/adminstore", formData);
        setMsg(response.message);
        setFormData({
          email: "",
          nome: "",
          origem: "",
          endereco: "",
          end_numero: "",
          ramo: "",
          descricao: "",
          telefone: "",
        });
        setTimeout(() => {
          setMsg(null);
        }, 3000);

      } catch (err) {
        setMsg(err.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    postData();
  };

  return (
    <div className="text-[10pt] p-2">
      <h3 className="text-[14pt] m-0">Cadastrar Lead</h3>
      <p className="text-[9pt] -mt-1 py-0 pl-4">cadastre um novo lead.</p>
      <hr />
      <div className="flex flex-col justify-between">
        <label className="">Nome *:</label>
        <input
          className={`min-w-[200px] bg-slate-300 rounded m-2 flex p-1.5`}
          id="nome"
          name="nome"
          value={formData.nome}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col justify-between">
        <label className="">Email *:</label>
        <input
          className={`min-w-[200px] bg-slate-300 rounded m-2 flex p-1.5`}
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col justify-between">
        <label className="">Telefone:</label>
        <input
          className={`min-w-[200px] bg-slate-300 rounded m-2 flex p-1.5`}
          id="telefone"
          name="telefone"
          value={formData.telefone}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col justify-between">
        <label className="">Origem *:</label>
        <input
          className={`min-w-[200px] bg-slate-300 rounded m-2 flex p-1.5`}
          id="origem"
          name="origem"
          value={formData.origem}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col justify-between">
        <label className="">Endereco:</label>
        <input
          className={`min-w-[200px] bg-slate-300 rounded m-2 flex p-1.5`}
          id="endereco"
          name="endereco"
          value={formData.endereco}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col justify-between">
        <label className="">End. Numero:</label>
        <input
          className={`min-w-[200px] bg-slate-300 rounded m-2 flex p-1.5`}
          id="end_numero"
          name="end_numero"
          value={formData.end_numero}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col justify-between">
        <label className="">Ramo:</label>
        <input
          className={`min-w-[200px] bg-slate-300 rounded m-2 flex p-1.5`}
          id="ramo"
          name="ramo"
          value={formData.ramo}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col justify-between">
        <label className="">Descrição:</label>
        <textarea
          className={`min-w-[200px] bg-slate-300 rounded m-2 flex p-1.5`}
          id="descricao"
          rows={3}
          name="descricao"
          value={formData.descricao}
          onChange={handleChange}
        />
      </div>
      <div className='flex flex-row-reverse h-[40px]'>
        {msg && (<p>{msg}</p>)}
      </div>
      <div className='flex flex-row-reverse'>
        <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handlePost}>Cadastrar</button>
      </div>
    </div>
  );
}

export default NovoLead;
