import React, {useState, useEffect, createContext} from 'react'
import { CssBaseline} from "@mui/material";
import { Navigate, Route, Routes, useLocation  } from "react-router-dom";
import Layout from "./Loja/scenes/layout/Index"
import LojaIndex from './Loja/scenes/lojaIndex/Index';
import Ecard from './Loja/scenes/ecards/Ecard';
import EcardIndex from './Loja/scenes/ecards/EcardIndex';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EcardMakerV2 from './Loja/scenes/ecards/EcardMakerV2';
import SignUp from './Loja/scenes/signUp/SignUp';
import LoginPage from './Loja/scenes/loginPage/LoginPage';
import PassForgot from './Loja/scenes/loginPage/PassForgot';
import UserPannel from './Loja/scenes/userResources/UserPannel';
import SitesIndex from './Loja/scenes/lojaIndex/SitesIndex';
import LojasIndex from './Loja/scenes/lojaIndex/LojasIndex';
import AplicativosIndex from './Loja/scenes/lojaIndex/AplicativosIndex';
import PainelLayout from 'Loja/scenes/layout/PainelLayout';
import { ReqUserAuth, ReqUseSubscription } from 'Loja/components/RequireAuth';
import UserSubscribe from 'Loja/scenes/userResources/UserSubscribe';
const GLOBAL_URL = process.env.REACT_APP_BASE_URL;


const theme = createTheme({
  typography: {
    fontFamily: 'Nunito", sans-serif',
  },
});

export const EnvContext = createContext();
const token = localStorage.getItem('token');

function EcommerceRoot() {

  const appUrl = GLOBAL_URL;
  
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  

  return (
    <div className="app">
        <ThemeProvider theme={theme}>
        <EnvContext.Provider value={{appUrl}}> 
        <CssBaseline />
        <Routes>
            <Route path="me/:slug" element={<Ecard />} />
            <Route element={<ReqUserAuth/>}> 
                  <Route element={<PainelLayout />}>
                    <Route path="/subscribe" element={<UserSubscribe />} />
                    <Route element={<ReqUseSubscription/>}> 
                      <Route path="/ecard-make" element={<EcardMakerV2 />} />
                      <Route path="/userpannel" element={<UserPannel />} />
                    </Route>
                  </Route>
            </Route>  
            <Route element={<Layout />}>
              <Route path="/" element={<LojaIndex/>} />
              <Route path="/sites" element={<SitesIndex/>} />  
              <Route path="/lojas" element={<LojasIndex/>} />  
              <Route path="/apps" element={<AplicativosIndex/>} />

              <Route path="/ecard" element={<EcardIndex />} />
                
                <Route path="/signup" element={<SignUp />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgotpass" element={<PassForgot/>} />

              <Route path="/*" element={<Navigate to="/" replace />}>
                          
              </Route>
            </Route>
        </Routes>
        </EnvContext.Provider>
        </ThemeProvider>
    </div>
  );
}

export default EcommerceRoot;

   
