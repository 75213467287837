import React from 'react';
import { Box } from '@mui/material';
import Carousel from 'react-bootstrap/Carousel';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, ThemeProvider, createTheme } from '@mui/material';

const GLOBAL_URL = 'http://127.0.0.1:8000';

const useStyles = makeStyles({
  carrocel: {
    position: 'relative',
    height: '100vh', // Ajuste a altura conforme necessário
    overflow: 'hidden', // Impede que a camada de fundo desfocado ultrapasse a altura do contêiner
  },
  background: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'blur(8px)', // Ajuste a quantidade de desfoque conforme necessário
    zIndex: -1,
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    margin: 'auto',
    display: 'block',
  },
});

function Carrocel() {
  const classes = useStyles();

  const carouselImages = require.context(
    '../../assets/carrocel',
    false,
    /^\.\/banner.*\.(png|jpe?g|svg)$/
  );

  const imageKeys = carouselImages.keys();

  return (
    <Box className={classes.carrocel}>
      <Carousel variant="light">
        {imageKeys.map((key, index) => {
          const image = carouselImages(key);
          return (
            <Carousel.Item key={index} className={classes.carrocel}>
              <img className={classes.img} src={`${image}`} alt={`Slide ${index + 1}`} />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </Box>
  );
}


export default Carrocel;