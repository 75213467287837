import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-brands-svg-icons';
import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import { GenPost } from 'state/api';

const handleMetrics = async (id) =>{
  await GenPost(`/link/click/${id}`); 
}

  const icones = {
    'facebook': {
      'icon': 'faFacebook',
      'type':'brand' },

    'whatsapp': {
      'icon': 'faWhatsapp',
      'type':'brand' },

    'linkedin': {
      'icon': 'faLinkedin',
      'type':'brand' },

    'instagram':{
      'icon':'faInstagram',
      'type':'brand'},

    'youtube': {
      'icon': 'faYoutube',
      'type':'brand' },

    'tiktok': {
      'icon': 'faTiktok',
      'type':'brand' },

    'twitter': {
      'icon': 'faTwitter',
      'type':'brand' },

    'github': {
      'icon': 'faGithub',
      'type':'brand' },

    'discord': {
      'icon': 'faDiscord',
      'type':'brand' },

    'shopify': {
      'icon': 'faShopify',
      'type':'brand' },

    'telegram': {
      'icon': 'faTelegram',
      'type':'brand' },

    'googleplay': {
      'icon': 'faGooglePlay',
      'type':'brand' },

    'spotify': {
      'icon': 'faSpotify',
      'type':'brand' },

    'twitch': {
      'icon': 'faTwitch',
      'type':'brand' },
    'site':{
      'icon':'faGlobe',
      'type':'solid'
    },
    'coração': {
      'icon': 'faHeart',
      'type':'solid' },
    'calendário': {
      'icon': 'faCalendarDays',
      'type':'solid' },
    'sino': {
      'icon': 'faBell',
      'type':'solid' },
    'carrinho': {
      'icon': 'faCartShopping',
      'type':'solid' },
    'compras': {
      'icon': 'faBagShopping',
      'type':'solid' },
    'livro': {
      'icon': 'faBook',
      'type':'solid' },
    'moeda': {
      'icon': 'faMoneyBill',
      'type':'solid' },
    'paleta': {
      'icon': 'faPalette',
      'type':'solid' },
    'negócio': {
      'icon': 'faHandshake',
      'type':'solid' },
    'gráfico': {
      'icon': 'faChartSimple',
      'type':'solid' },
    }
  
  const findKeyByIcon = (iconName) => {
    for (const [key, value] of Object.entries(icones)) {
      if (value.icon === iconName) {
        return key;
      }
    }
    return null; // Retorna null se o ícone não for encontrado
  };
const BlueDefault = ({prop, type, blockClick, dragable, action}) => {
    const iconObject = icones[findKeyByIcon(prop.icon)];
    const selectedIcon = iconObject.type === 'brand' ? Icons[prop.icon] : SolidIcons[prop.icon];
    //const selectedIcon = prop.icon !== 'Website' ? Icons[prop.icon] : SolidIcons['faGlobe'];
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseOver = () => {
      setIsHovered(true);
    };

    const handleMouseOut = () => {
      setIsHovered(false);
    };

    const buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        width: '60%',
        maxWidth:'350px', // ou ajuste a porcentagem desejada
        minWidth: '150px',
        height: '40px',
        borderRadius: '5px',
        backgroundColor: isHovered ? '#4da7e4' : '#3498db',
        color: '#fff',
        cursor: !dragable ? 'pointer' : 'grab',
        overflow: 'hidden',
        transition: 'background-color 0.3s',
      };

      const handleClick = async (link, id) =>{
        if (!blockClick){
          await handleMetrics(id);
          window.location.href = link;
        } else {
          if(action){
            action(id)
          }
        }
    };
    
    return (
      <div className="flex justify-center w-[100%] m-[4px]">
          <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            style={buttonStyle}
            onClick={ () => handleClick(prop.url, prop.id)}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingRight: '20px',
                fontSize: '12pt',
              }}
              className={`${type.font}`}
            >
              {prop.name}
            </div>
            <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <FontAwesomeIcon style={{ fontSize: '18pt', marginRight: '10px' }} icon={selectedIcon}/>
            </div>
          </div>
      </div>
    );
  };

const Default = ({prop, type, blockClick, dragable, action}) => {
  const darkenColor = (color, percent) => {
    const num = parseInt(color.slice(1), 16);
    let r = (num >> 16) - Math.round(2.55 * percent);
    let g = ((num >> 8) & 0x00FF) - Math.round(2.55 * percent);
    let b = (num & 0x0000FF) - Math.round(2.55 * percent);
  
    r = r < 0 ? 0 : r;
    g = g < 0 ? 0 : g;
    b = b < 0 ? 0 : b;
  
    return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
  };
    const [isDragging, setIsDragging] = useState(false);
    const iconObject = icones[findKeyByIcon(prop.icon)];
    const selectedIcon = iconObject.type === 'brand' ? Icons[prop.icon] : SolidIcons[prop.icon];
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseOver = () => {
      setIsHovered(true);
    };

    const handleDragStart = () => {
      setIsDragging(true);
    };
  
    const handleDragEnd = () => {
      setIsDragging(false);
    };

    const handleMouseOut = () => {
      setIsHovered(false);
    };

    const buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        width: '60%',
        maxWidth:'350px', // ou ajuste a porcentagem desejada
        minWidth: '150px',
        height: '40px',
        borderRadius: '5px',
        backgroundColor: isHovered ? darkenColor(type.bgcolor, 10) : type.bgcolor,
        color: type.textcolor,
        cursor: !dragable ? 'pointer' : isDragging ? 'grabbing' : 'grab',
        overflow: 'hidden',
        transition: 'background-color 0.3s',
        draggable: dragable,
        onDragStart: handleDragStart,
        onDragEnd: handleDragEnd,
      };

      const handleClick = async (link, id) =>{
        if (!blockClick){
          await handleMetrics(id);
          window.location.href = link;
        } else {
          if(action){
            action(id)
          }
        }
    };
    
    return (
      <div className="flex justify-center w-[100%] m-[4px]">
          <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            style={buttonStyle}
            onClick={ () => handleClick(prop.url, prop.id)}
          >
            <div
              style={{
                flex: 1,
                flexGrow:1,
                display: 'flex',
                justifyContent:'center',
                paddingLeft:4,
                alignItems: 'center',
                paddingRight: '20px',
                fontSize: '11pt',
              }}
              className={`${type.font}`}
            >
              {prop.name}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight:4 }}>
                <FontAwesomeIcon style={{ fontSize: '18pt', marginRight: '10px' }} icon={selectedIcon}/>
            </div>
          </div>
      </div>
    );
  };

const Lead = ({prop, type, action}) => {
    const darkenColor = (color, percent) => {
      const num = parseInt(color.slice(1), 16);
      let r = (num >> 16) - Math.round(2.55 * percent);
      let g = ((num >> 8) & 0x00FF) - Math.round(2.55 * percent);
      let b = (num & 0x0000FF) - Math.round(2.55 * percent);
    
      r = r < 0 ? 0 : r;
      g = g < 0 ? 0 : g;
      b = b < 0 ? 0 : b;
    
      return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
    };
  
      const [isHovered, setIsHovered] = useState(false);
      const handleMouseOver = () => {
        setIsHovered(true);
      };
  
      const handleMouseOut = () => {
        setIsHovered(false);
      };
  
      const buttonStyle = {
          display: 'flex',
          alignItems: 'between',
          width: '60%',
          maxWidth:'350px', // ou ajuste a porcentagem desejada
          minWidth: '150px',
          height: '40px',
          borderRadius: '5px',
          backgroundColor: isHovered ? darkenColor(type.bgcolor, 10) : type.bgcolor,
          color: type.textcolor,
          cursor: 'pointer',
          overflow: 'hidden',
          transition: 'background-color 0.3s',
        };
        
      return (
        <div className="flex justify-center w-[100%] m-[4px]">
            <div
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              style={buttonStyle}
              onClick={action}
            >
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '10pt',
                }}
                className={`${type.font}`}
              >
                {prop.name}
              </div>
            </div>
        </div>
      );
    };

const RedDefault = ({prop, type, blockClick, dragable, action}) => {
  const iconObject = icones[findKeyByIcon(prop.icon)];
  const selectedIcon = iconObject.type === 'brand' ? Icons[prop.icon] : SolidIcons[prop.icon];
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseOver = () => {
      setIsHovered(true);
    };

    const handleMouseOut = () => {
      setIsHovered(false);
    };

    const buttonStyle = {
        display: 'flex',
        alignItems: 'center',
        width: '80%', // ou ajuste a porcentagem desejada
        maxWidth: '200px',
        height: '60px',
        borderRadius: '10px',
        backgroundColor: isHovered ? '#0059b3' : '#cc0000',
        color: '#fff',
        cursor: !dragable ? 'pointer' : 'grab',
        overflow: 'hidden',
  
        transition: 'background-color 0.3s',
      };

      const handleClick = async (link, id) =>{
        if (!blockClick){
          await handleMetrics(id);
          window.location.href = link;
        } else {
          if(action){
            action(id)
          }
        }
    };
    
    return (
      <div className="container m-[4px]">
          <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            style={buttonStyle}
            onClick={ () => handleClick(prop.url, prop.id)}
          >
            {/* Ícone à Direita */}
            <div style={{ width: '30%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <FontAwesomeIcon style={{ fontSize: '8pt', marginRight: '10px' }} icon={selectedIcon}/>
            </div>

            {/* Texto à Esquerda */}
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingRight: '20px',
                fontSize: '11pt',
              }}
              className={`${type.font}`}
            >
              {prop.name}
            </div>
          </div>
      </div>
    );
  };
  
function ButtonsClasses({prop, type, action, blockClick, dragable}) {
    const dict ={
        'red':<RedDefault prop={prop} type={type} blockClick={blockClick} dragable={dragable} action={action}/>,
        'default':<BlueDefault prop={prop} type={type} blockClick={blockClick} dragable={dragable} action={action}/>,
        'blue':<Default prop={prop} type={type} blockClick={blockClick} dragable={dragable} action={action}/>,
        'lead':<Lead prop={prop} type={type} action={action} />
    }

  return dict[type.type];
}

export default ButtonsClasses;