import React, { useEffect, useState } from 'react';
import foguete from '../../assets/index-assets/rocket.jpeg';
import working from '../../assets/index-assets/working_zoom.jpeg';
import world from '../../assets/index-assets/world.jpeg';

function Carousel({ children }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % children.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + children.length) % children.length);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative w-full overflow-hidden">
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) => (
          <div className="w-full flex-shrink-0">{child}</div>
        ))}
      </div>
      <div className="absolute inset-y-0 left-0 flex items-center">
        <button onClick={handlePrev} className="bg-black text-white p-2">‹</button>
      </div>
      <div className="absolute inset-y-0 right-0 flex items-center">
        <button onClick={handleNext} className="bg-black text-white p-2">›</button>
      </div>
      <div className="absolute bottom-0 left-0 right-0 flex justify-center p-2">
        {children.map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full mx-1 ${index === currentIndex ? 'bg-white' : 'bg-gray-500'}`}
            onClick={() => handleDotClick(index)}
          ></button>
        ))}
      </div>
    </div>
  );
}

function AnimatedImage({ image, direction }) {
  return <img src={image} alt="" className="w-1/2 h-[350px] object-fill rounded-lg" />;
}

function MobileAnimatedImage({ image, direction }) {
  return <img src={image} alt="" className="w-full h-[350px] object-fill rounded-lg" />;
}

function MaisSobre() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sectionStyle = 'flex flex-col w-1/2 justify-center shadow-md p-4 rounded-lg bg-black text-slate-200 text-center text-md font-semibold border border-gray-400';

  return (
    <div className="p-4 flex flex-col bg-gradient-to-r from-blue-500 to-red-500 overflow-hidden h-full text-slate-100">
      {isSmallScreen ? (
        <Carousel>
          <div className="flex flex-col gap-4 w-full p-2">
            <div className="w-full shadow-md border border-gray-400 border-opacity-20 flex p-4 bg-gray-600 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">
              <div className='flex flex-col'>
                <h3 className="archivo-black-titles text-[18pt]">Seu negócio precisa estar Online!</h3>
                <hr/>
                <p className="px-2">
                Sua empresa atualizada com o mercado, se posicionando com autoridade e tecnologia!
                </p>
              </div>
            </div>
            <MobileAnimatedImage image={foguete} direction="right" />
          </div>
          <div className="flex flex-col gap-4 w-full p-2">
            <div className="w-full shadow-md border border-gray-400 border-opacity-20 flex p-4 bg-gray-600 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">
              <div className='flex flex-col'>
                <h3 className="archivo-black-titles text-[18pt]">Nossa Equipe, com você</h3>
                <hr/>
                <p className="px-2">
                Um compromisso para dar mais tecnologia e modernidade para seu negócio!
                </p>
              </div>
            </div>
            <MobileAnimatedImage image={working} direction="left" />
          </div>
          <div className="flex flex-col gap-4 w-full p-2">
            <div className="w-full shadow-md border border-gray-400 border-opacity-20 flex p-4 bg-gray-600 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">
              <div className='flex flex-col'>
                <h3 className="archivo-black-titles text-[18pt]">Faça parte do mundo inteiro</h3>
                <hr/>
                <p className="px-2">
                Você online, para o mundo inteiro, não fique de fora desse mar de oportunidades!
                </p>
              </div>
            </div>
            <MobileAnimatedImage image={world} direction="right" />
          </div>
        </Carousel>
      ) : (
        <div className="flex flex-col gap-4">
          <Carousel>
            <div className="flex flex-row gap-4 w-full p-10">
              <div className="w-full shadow-md border border-gray-400 border-opacity-20 flex p-12 bg-gray-600 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">
                <div className='flex flex-col'>
                  <h3 className="archivo-black-titles text-[18pt]">Seu negócio precisa estar Online!</h3>
                  <hr/>
                  <p className="px-2">
                  Sua empresa atualizada com o mercado, se posicionando com autoridade e tecnologia!
                  </p>
                </div>
              </div>
              <AnimatedImage image={foguete} direction="right" />
            </div>
            <div className="flex flex-row gap-4 w-full p-10">
              <AnimatedImage image={working} direction="left" />
              <div className="w-full shadow-md border border-gray-400 border-opacity-20 flex p-12 bg-gray-600 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">
                <div className='flex flex-col'>
                  <h3 className="archivo-black-titles text-[18pt]">Nossa Equipe, com você</h3>
                  <hr/>
                  <p className="px-2">
                  Um compromisso para dar mais tecnologia e modernidade para seu negócio!
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full p-10">
              <div className="w-full shadow-md border border-gray-400 border-opacity-20 flex p-12 bg-gray-600 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">
                <div className='flex flex-col'>
                  <h3 className="archivo-black-titles text-[18pt]">Faça parte do mundo inteiro</h3>
                  <hr/>
                  <p className="px-2">
                  Você online, para o mundo inteiro, não fique de fora desse mar de oportunidades!
                  </p>
                </div>
              </div>
              <AnimatedImage image={world} direction="right" />
            </div>
          </Carousel>
        </div>
      )}
    </div>
  );
}

export default MaisSobre;

