import React, { useEffect, useState } from 'react';
import CustomSelect from 'Admin/components/AdminComponents/CustomSelect';
import { GenGet, GenPost } from 'state/api';

function NovaAssinatura() {
    const [isLoading, setIsLoading] = useState(true);
    const [msg, setMsg] = useState(null);
    const [userList, setUserList] = useState([]);
    const [user, setUser] = useState(null);

    const [userData, setUserData] = useState(null);
    const [planosList, setPlanosList] = useState([]);
    const [planosData, setPlanosData] = useState([]);
    const [plano, setPlano] = useState(null);
    const [formData, setFormData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        async function getData() {
            try {
                const users = await GenGet('/nova/assinatura/users');
                const planos = await GenGet('/nova/assinatura/planos');
                setPlanosData(planos.planos);
                setPlanosList(planos.lockup);
                setUserList(users.lockup);
                setUserData(users.users);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
            }
        }
        getData();
    }, []);

    const handleUser = (id, value) => {
        setFormData({
            ...formData,
            user_id: id
        });
        const userSelect = userData.find(item => item.id === parseInt(id));
        if (userSelect) {
            setUser(userSelect);
        } else {
            console.log('ID não encontrado:', id);
        }
    };

    const handlePlanos = (id, value) => {
        setFormData({
            ...formData,
            plano_id: id
        });
        const planoSelect = planosData.find(item => item.id === parseInt(id));
        if (planoSelect) {
            setPlano(planoSelect);
        } else {
            console.log('ID não encontrado:', id);
        }
    };

    const handlePost = (e) => {
        e.preventDefault();
        async function postData(){
            try{
                const response = await GenPost('/nova/assinatura/avulsa', formData);
                setMsg(response.message);
                setTimeout(() => {
                    setMsg(null);
                  }, 3000);

            } catch (err) {
                setMsg(err.message);
                setTimeout(() => {
                    setMsg(null);
                  }, 3000);
            }
        }

        postData();
        setIsModalOpen(false);
    };

    const handleOpenModal = () => {
        if (!formData.plano_id || !formData.user_id ){
            setMsg('Escolha um usuário e um plano.');
            setTimeout(() => {
                setMsg(null);
              }, 3000);
              return;
        }

        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className='p-2 text-[10pt]'>
            <h3 className='text-[14pt] m-0'>Nova Assinatura</h3>
            <p className='text-[9pt] -mt-1 py-0 pl-4' >Crie uma nova assinatura.</p>
            <hr />
            {isLoading ? (
                <>Carregando...</>
            ) : (
                <div>
                    <div className='flex flex-row gap-2 flex-wrap w-full'>
                        <CustomSelect data={userList} label={'Usuário'} onChange={handleUser} search={true} />
                        <CustomSelect data={planosList} label={'Plano'} onChange={handlePlanos} search={true} />
                    </div>
                    {user && (
                        <div className='p-4'>
                            <h3 className='text-[12pt]'>Detalhes de usuário:</h3>
                            <h3 className='text-[11pt]'>{user.name}</h3>
                            <p className='text-[10pt] m-0'>{user.email}</p>
                            <div className='flex flex-row gap-1 flex-wrap'>
                                <p className='text-[10pt]'><b>Email:</b> {user.email} - </p>
                                <p className='text-[10pt]'><b>Telefone:</b> {user.telefone}</p>
                            </div>
                        </div>
                    )}
                    {plano && (
                        <div className='p-4'>
                            <h3 className='text-[12pt]'>Detalhes de plano:</h3>
                            <h3 className='text-[11pt]'>{plano.nome}</h3>
                            <p className='text-[10pt]'>{plano.descricao}</p>
                            <div className='flex flex-row gap-1 flex-wrap'>
                                <p className='text-[10pt] m-0'><b>Valor:</b> R${plano.valor}</p>
                                <p className='text-[10pt]'><b>Período:</b> {plano.periodo} {plano.tipo_periodo}</p>
                            </div>
                        </div>
                    )}
                    <div className='h-[40px] flex-row-reverse text-right'>
                        {msg && <p>{msg}</p>}
                    </div>
                    <div className='flex flex-row-reverse'>
                        <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handleOpenModal}>Ativar Plano</button>
                    </div>
                </div>
            )}

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                        <h2 className="text-lg font-semibold mb-4">Confirmação</h2>
                        <p className="mb-4">Tem certeza de que deseja ativar este plano?</p>
                        <div className="flex justify-end space-x-4">
                            <button
                                className="bg-gray-500 hover:bg-gray-600 admin-button-small"
                                onClick={handleCloseModal}
                            >
                                Cancelar
                            </button>
                            <button
                                className="bg-blue-400 hover:bg-blue-500 admin-button-small"
                                onClick={handlePost}
                            >
                                Confirmar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default NovaAssinatura;
