import axios from "axios";
const token = localStorage.getItem('token');
const GLOBAL_URL = process.env.REACT_APP_BASE_URL;

export default axios.create({
    baseURL:`${GLOBAL_URL}/api`,
    headers:{
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`},
        withCredentials: true,    
});

export const formMulti = axios.create({
    baseURL: `${GLOBAL_URL}/api`,
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': `Bearer ${token}`
    },
    withCredentials: true
  });

  export const cookie = axios.create({
    baseURL:`${GLOBAL_URL}`,
    headers:{
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`},
        withCredentials: true,    
  });