import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

function IndexCTA() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleWhatsappClick = () => {
    window.location.href = 'https://api.whatsapp.com/send?phone=554499433101';
  };
  return (
    <>{isSmallScreen ? (
      <div className='p-10 flex flex-col gap-2 text-slate-200 bg-blue-950'>
        <div className='text-right flex flex-col m-2 items-end'>
          <h3 className='archivo-black-regular -mb-0 text-4xl'>FIQUE ONLINE,</h3>
          <h3 className='archivo-black-regular2'> CONTE CONOSCO!</h3>
        <div onClick={handleWhatsappClick} className='bg-green-500 text-slate-200 font-extrabold p-3 rounded-xl shadow-md cursor-pointer hover:bg-green-400'> ENTRE EM CONTATO <FontAwesomeIcon icon={faWhatsapp}/></div>
      </div>
      <div className='font-semibold p-10 text-[13pt]'>    
      <p>Ao escolher a <span className='font-extrabold text-red-500 text-[14pt]'>Always Online</span>, você garante não apenas um site excepcional, mas também <span className='text-yellow-400 text-[14pt]'>o suporte de quem busca entregar sempre o melhor!</span> <br/><br/>Não espere mais. Transforme a consciência em ação. Escolha Always Online e leve sua presença online a novos patamares.</p></div>
      </div>
    ):(
      <div className='p-10 flex gap-2 text-slate-200 bg-blue-950'>
      <div className='text-right flex flex-col w-2/5 m-2 items-end'>
      <h3 className='archivo-black-regular -mb-0 text-4xl'>FIQUE ONLINE,</h3>
      <h3 className='archivo-black-regular2'> CONTE CONOSCO!</h3>
      <div onClick={handleWhatsappClick} className='bg-green-500 text-slate-200 font-extrabold p-3 rounded-xl shadow-md cursor-pointer hover:bg-green-400'> ENTRE EM CONTATO <FontAwesomeIcon icon={faWhatsapp}/></div>
      </div>
      <div className='font-semibold w-3/5 p-10 text-[13pt]'>    
      <p>Ao escolher a <span className='font-extrabold text-red-500 text-[14pt]'>Always Online</span>, você garante não apenas um site excepcional, mas também <span className='text-yellow-400 text-[14pt]'>o suporte de quem busca entregar sempre o melhor!</span> <br/><br/>Não espere mais. Transforme a consciência em ação. Escolha Always Online e leve sua presença online a novos patamares.</p></div>
      </div>
    )}</>

  )
}

export default IndexCTA