import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { GenPost } from 'state/api';

function SignUp() {
  const nav = useNavigate()
  const [msg, setMsg] = useState(null);
  const [formData, setFormData] = useState({
    nome:'',
    sobrenome:'',
    email:'',
    cpf:'',
    telefone:'',
    password:'',
    password_confirmation:'',
  });

  const handleChange = (e) =>{
    setFormData({
      ...formData,
      [e.target.name]:e.target.value
    })
  }

  const validateForm = () => {
    const { nome, sobrenome, email, cpf, telefone, password, password_confirmation } = formData;

    // Verifica se algum campo está vazio
    if (!nome || !sobrenome || !email || !cpf || !telefone || !password || !password_confirmation) {
      setMsg('Todos os campos são obrigatórios');
      return false;
    }

    // Verifica se as senhas coincidem
    if (password !== password_confirmation) {
      setMsg('Os campos de senha não conferem');
      return false;
    }
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)){
      setMsg('Senha deve conter ao menos 8 caracteres, incluindo letras e números.');
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return
    }
    return true;
  };
  // 
  const handlePost = (e) => {
    e.preventDefault();
    if (!validateForm){
        setTimeout(() => {
          setMsg(null);
        }, 3000);
        return;
    }


    async function postData() {
      try {
        const response = await GenPost("/auth/register", formData);
        localStorage.setItem('token', response?.token);
        setMsg(response.message);
        setTimeout(() => {
          setMsg(null);
          window.location.reload();
        }, 3000);

      } catch (err) {
        setMsg(err.message);
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    postData();
  };
  const formatarTelefone = (valor) => {
    // Remove todos os caracteres não numéricos
    const cleaned = valor.replace(/\D/g, '');
    // Aplica a máscara
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return `(${match[1]})${match[2]}-${match[3]}`;
    }
    return valor;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      nav('/userpannel');
    }
  }, []);

  return (
    <div className='w-full flex md:flex-row flex-col gap-4 h-full min-h-[80vh] justify-center items-center bg-blue-950 py-10 pt-0 p-2'>
      <div className='flex flex-col w-[100%] justify-center items-center'>
        <div className='w-full text-left px-4 bg-blue-950'>
          <h3 className='archivo-black-regular -mb-0 mt-4 text-3xl lg:text-[24pt] md:text-[20pt]'>FIQUE ONLINE!</h3>
          <h3 className='archivo-black-regular2'>CRIE SEU E-CARD</h3>
        </div>
          <div className='flex flex-col relative max-w-[350px] w-full p-4 bg-gradient-to-bl from-red-500 to-blue-500 gap-1 text-slate-200 rounded-md shadow-lg m-4'>
          {msg && (
            <div className='absolute -top-2 text-blue-500 rounded-md flex justify-center align-middle items-center px-2 py-1 m-0 -right-5 bg-white'>
              {msg}
            </div>
          )}  

          
          <h3 className='text-[18pt] italic'>CRIE UMA CONTA!</h3>
          <hr/>
          <div className='flex flex-col px-4 gap-2'>
            <input 
              className='p-1 rounded-lg custom-input text-slate-600' 
              placeholder="Nome"
              name='nome' 
              value={formData.nome}
              onChange={handleChange}/>
            <input 
              className='p-1 rounded-lg custom-input text-slate-600' 
              placeholder="Sobrenome"
              name='sobrenome' 
              value={formData.sobrenome}
              onChange={handleChange}/>
            <input 
              className='p-1 rounded-lg custom-input text-slate-600' 
              placeholder="E-mail"
              name='email'  
              value={formData.email}
              onChange={handleChange}/>
            <input
              className='p-1 rounded-lg custom-input text-slate-600'
              id="telefone"
              name="telefone"
              maxLength={14}
              placeholder="Telefone"
              value={formatarTelefone(formData.telefone)}
              onChange={handleChange}/>
            <input
              className='p-1 rounded-lg custom-input text-slate-600'
              id="cpf"
              name="cpf"
              placeholder="CPF"
              maxLength={11}
              value={formData.cpf} 
              onChange={(e) => {
                // Remove all non-numeric characters
                const value = e.target.value.replace(/\D/g, '');
                
                // Call handleChange once with the modified value
                handleChange({
                  target: {
                    name: 'cpf',
                    value,
                  },
                });
              }}
            />
            <input 
              className='p-1 rounded-lg custom-input text-slate-600' 
              placeholder="Senha" 
              name='password' 
              value={formData.password}
              onChange={handleChange}
              type={'password'}/>
            <input 
              className='p-1 rounded-lg custom-input text-slate-600' 
              placeholder="Confirme a senha" 
              name='password_confirmation' 
              value={formData.password_confirmation}
              type={'password'}
              onChange={handleChange}/>
            <div className='flex flex-row-reverse w-full bottom-0 left-0 '>
              <button className='bg-blue-400 hover:bg-blue-500 admin-button' onClick={handlePost}>Cadastrar </button>
            </div>
          </div>          
          <br/>

            <div className='flex justify-center w-[100%] bottom-0 left-0 absolute'>
              <p className='text-[8pt]'>Já tem uma conta? <a className='cursor-pointer underline text-cyan-400 font-semibold hover:text-cyan-500' onClick={() => nav('/login')}>Faça um login!</a></p>
            </div>
        </div>
      </div>




    </div>
  )
}

export default SignUp
{/*

        <EcardCarrocel/>
        <p className='absolute bottom-0 right-10 text-[14pt] font-semibold text-slate-100 underline'>planos a partir de <span className='text-blue-500 text-[18pt] font-extrabold italic'>R$29,90</span></p>
*/}