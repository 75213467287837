import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import SiteIcon from '../../assets/index-assets/WebsiteIcon.png';
import MobileIcon from '../../assets/index-assets/MobileIcon.png';
import LPIcon from '../../assets/index-assets/LandingPageIcon.png';
import EcardIcon from '../../assets/index-assets/ECardIcon.png';
import EcommerceIcon from '../../assets/index-assets/EcommerceIcon.png';
import { useNavigate } from "react-router-dom";

function AnimatedProduct({ title, icon,sm, route }) {
  const nav = useNavigate()
  const props = useSpring({
    opacity: 1,
    transform: 'translateX(0)',
    from: { opacity: 0, transform: 'translateX(100%)' },
    config: { duration: 700 },
  });

  return (
    <>{
      sm? (    
      <animated.div style={props} className='h-[300px] w-[200px] flex flex-col rounded-lg justify-center items-center' onClick={()=>nav(route)}>
        <p className='font-extrabold text-center text-lg'>{title}</p>
        <img className='w-[170px]' src={icon} alt={title} />
      </animated.div>
      ):(    
      <animated.div style={props} className='h-[200px] flex flex-col w-[22%] border-2 border-cyan-700 rounded-lg p-2 justify-center items-center cursor-pointer' onClick={()=>nav(route)}>
        <p className='font-extrabold text-center text-sm'>{title}</p>
        <img className='w-[120px]' src={icon} alt={title} />
      </animated.div>
      )
    }</>

  );
}

function ProductDescribe() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>{
      isSmallScreen ?(
        <div id="produtos" className='p-10 flex flex-col text-slate-800 justify-center items-center'>
        <h3 className='text-[18pt] mb-4 archivo-black-regular3'>Nossos produtos!</h3>
        <div className='flex flex-col gap-2'>
          <AnimatedProduct title='Landing Pages' icon={LPIcon} sm={true} route={'/sites'}/>
          <AnimatedProduct title='Lojas e Ecommerce' icon={EcommerceIcon} sm={true} route={'/lojas'}/>
          <AnimatedProduct title='Sites personalizados' icon={SiteIcon} sm={true} route={'/sites'}/>
          <AnimatedProduct title='Cartões Virtuais' icon={EcardIcon} sm={true} route={'/ecard'}/>      
          <AnimatedProduct title='Aplicativos Mobile' icon={MobileIcon} sm={true} route={'/apps'}/>
        </div>
      </div>
      ):(
        <div id="produtos" className='p-10 flex flex-col text-slate-800 justify-center items-center'>
        <h3 className='text-[18pt] mb-4 archivo-black-regular3'>Nossos produtos!</h3>
        <div className='flex flex-row gap-4'>
          <AnimatedProduct title='Sites personalizados' icon={SiteIcon} route={'/sites'}/>
          <AnimatedProduct title='Cartões Virtuais' icon={EcardIcon} route={'/ecard'}/>
          <AnimatedProduct title='Landing Pages' icon={LPIcon} route={'/sites'}/>
          <AnimatedProduct title='Lojas e Ecommerce' icon={EcommerceIcon} route={'/lojas'}/>
          <AnimatedProduct title='Aplicativos Mobile' icon={MobileIcon} route={'/apps'}/>
        </div>
      </div>
      )
    }</>
  );
}

export default ProductDescribe;