import React, { useEffect, useState, useRef } from 'react';

function CustomDataGrid({ rows, columns, actions, title, per_page, selectable, handleSelect }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(per_page); 
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredRows, setFilteredRows] = useState(rows);
  const [selectedRows, setSelectedRows] = useState([]);
  const tableRef = useRef(null);

  const handleSelectRows = () => {
    if (selectable && handleSelect) {
      handleSelect(selectedRows);
    }
  };

  useEffect(() => {
    handleSelectRows();
  }, [selectedRows]);

  // Handle pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(filteredRows) ? filteredRows.slice(indexOfFirstItem, indexOfLastItem) : [];

  // Calculate number of pages
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil((filteredRows?.length || 0) / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Handle checkbox selection
  const handleCheckboxChange = (event, row) => {
    const isChecked = event.target.checked;
    setSelectedRows(prevSelectedRows => {
      if (isChecked) {
        return [...prevSelectedRows, row.id];
      } else {
        return prevSelectedRows.filter(id => id !== row.id);
      }
    });
  };

  // Handle changing page
  const handleChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle filtering based on search term
  const handleFilter = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filteredData = rows.filter((row) =>
      Object.values(row).some((field) =>
        field.toString().toLowerCase().includes(value)
      )
    );
    setFilteredRows(filteredData);
    setCurrentPage(1); // Reset to first page when filtering
  };

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  return (
    <div>
      <h4 className='text-[12pt]'>{title}</h4>
      {/* Filter Input */}
      <div className="bg-gray-400 rounded-t-md p-[2px]">
        <input
          type="text"
          placeholder="Pesquisar..."
          value={searchTerm}
          onChange={handleFilter}
          className="px-1 py-1 border rounded-md shadow-sm"
        />
      </div>
      {/* Table */}
      <table ref={tableRef} className="w-full border-[1px]">
        <thead>
          <tr className="border-b-[1px]">
            {selectable && <th className="px-3 py-1 min-w-[60px]"></th>}
            {columns.map((item, index) => (
              <th key={index} className="px-3 py-1 text-left min-w-[60px] border-r-[1px]">
                {item}
              </th>
            ))}
            {actions && <th className="px-3 py-1 min-w-[200px]">Opções</th>}
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            currentItems.map((row, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-slate-100">
                {selectable && (
                  <td className="px-3 py-1 min-w-[60px]">
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(row.id)}
                      onChange={(e) => handleCheckboxChange(e, row)}
                    />
                  </td>
                )}
                {Object.values(row).map((field, colIndex) => (
                  <td key={colIndex} className="px-3 py-1 min-w-[60px] text-[11pt]">
                    {field}
                  </td>
                ))}
                {actions && (
                  <td className="px-6 py-2 min-w-[200px]">
                    <div className="flex gap-2">
                      {actions.map((item, index) => (
                        <button
                          key={index}
                          onClick={() => item.function(row.id)}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold text-[10pt] py-1 px-1 min-w-[80px] rounded-sm"
                        >
                          {item.action}
                        </button>
                      ))}
                    </div>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length + (actions ? 1 : 0)} className="px-6 py-4 text-center">
                Nenhum dado disponível
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="mt-4 flex justify-center">
        {pageNumbers && pageNumbers.length > 0 ? (
          pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              className={`mx-1 px-2 py-1 rounded-md text-[10pt] ${
                currentPage === pageNumber
                  ? 'bg-slate-700 text-slate-200'
                  : 'bg-slate-400 text-slate-200'
              }`}
              onClick={() => handleChangePage(pageNumber)}
            >
              {pageNumber}
            </button>
          ))
        ) : (
          null
        )}
      </div>
    </div>
  );
}

export default CustomDataGrid;
