import IndexCTA from 'Loja/components/compLoja/IndexCTA'
import React from 'react'
import ApresentacaoGenerica from 'Loja/components/compLoja/ApresentacaoGenerica'

function LojasIndex() {
    const data = {
        titulo:"SUA LOJA",
        subtitulo:'Quer vender para seus clientes na internet e online? Já tendo um negócio físico, ou até mesmo começando do zero, vender online é indispensável para os tempos atuais!',
        descricao:'Aqui na Always Online, te ajudamos ter sua loja digital!'
    }

  return (
    <div>
        <ApresentacaoGenerica data={data}/>
        <div className='w-full py-8 px-4 md:px-20 bg-gradient-to-r from-blue-500 to-red-500'>
            <div className='flex flex-col p-4 md:p-20 rounded-md bg-slate-100 shadow-md'>
                <h3 className='text-blue-900 font-bold'>Sua Loja Online!</h3>
                <p className='text-slate-800 font-semibold pl-2 mt-4'><span className='text-blue-800'>Vender Online</span>, em nível <span className='text-blue-800'>Brasil ou mundo?</span></p>
                <p className='text-slate-800 font-semibold pl-2'>Aqui na <span className='text-red-800'>Always Online</span>, temos a solução de vendas para seu negócio: sem mensalidades, sem limitações e sem taxa de porcentagem, <span className='text-blue-800'>um sistema totalmente seu!</span></p>
                <p className='text-slate-800 font-semibold pl-2'>Seu e-commerce 100% seu, personalizado <span className='text-blue-500'>com sua identidade</span>. Painel administrativo completo para gerenciar suas vendas, produtos, estoques, fretes, base de usuários e muito mais!</p>
                <p className='text-slate-800 font-semibold pl-2'>Nosso sistema está cada dia mais completo. Além disso, integramos com a ferramenta de sua preferência, seja de <span className='text-blue-500'>frete, pagamento, e-mail ou qualquer que seja sua demanda!</span></p>

            </div>
        </div>
        <IndexCTA/>
    </div>
  )
}

export default LojasIndex