import React, { useState } from "react";
import { GenPost } from "state/api";
import { Alert } from "@mui/material";

function NovoPlano({ handleRefresh }) {
  const [data, setData] = useState({});
  const [showErrors, setShowError] = useState(false);
  const [msg, setMsg] = useState(null);
  const [formData, setFormData] = useState({
    ativo: false,
    tipo_periodo: "meses",
    periodo: 12,
    valor: "",
    nome: "",
    descricao: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    tipo_periodo: "",
    periodo: "",
    valor: "",
    nome: "",
    descricao: "",
  });

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    setFormData({
      ...formData,
      [e.target.name]: value,
    });
    setValidationErrors({
      ...validationErrors,
      [e.target.name]: value ? "" : "Campo obrigatório.",
    });
  };

  const handlePost = () => {
    const errors = {};
    let hasErrors = false;

    for (const key in formData) {
      if (formData[key] === "") {
        errors[key] = "Campo obrigatório.";
        hasErrors = true;
      }
    }

    setValidationErrors(errors);

    if (hasErrors) {
      setShowError(true);
      setMsg("Por favor, preencha todos os campos.");

      setTimeout(() => {
        setShowError(false);
        setMsg(null);
      }, 4000);

      return;
    }

    async function postData() {
      try {
        const response = await GenPost("/planos/store", formData);
        setMsg(response.message);
        setTimeout(() => {
          setShowError(false);
          handleRefresh(true);
          setMsg(null);
        }, 4000);
      } catch (err) {
        setMsg(err.message);
        setTimeout(() => {
          setShowError(false);
          setMsg(null);
        }, 4000);
      }
    }
    postData();
  };
  return (
    <div className="p-2 text-[10pt]">
      <div className=" w-[100%]">
        <div className="flex flex-col text-left overflow-hidden">
          <h3 className='text-[14pt] m-0'>Cadastrar novo Plano</h3>
          <p className='text-[9pt] -mt-1 py-0 pl-4'>cadastre e veja seus planos.</p>
          <hr/>
          <div className="flex flex-col justify-between">
            <label className="">Nome:</label>
            <input
              className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}
              id="nome"
              name="nome"
              value={formData.nome}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col justify-between">
            <label className="">Descricão:</label>
            <textarea
              className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}
              id="descricao"
              rows={4}
              name="descricao"
              value={formData.descricao}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-row justify-between flex-wrap">
            <div className="flex flex-col justify-between">
              <label className="">Tipo de período: </label>
              <select
                className="w-[200px] bg-slate-300 rounded m-2 flex p-1.5"
                aria-label="tipo_periodo"
                onChange={handleChange}
                id="tipo_periodo"
                name="tipo_periodo"
                value={formData.tipo_periodo}
                error={!!validationErrors.tipo_periodo}
                helperText={validationErrors.tipo_periodo}
              >
                <option value={"dias"}>Dias</option>
                <option value={"meses"}>Meses</option>
                <option value={"anos"}>Anos</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="">Período:</label>
              <input
                className={`w-[200px] bg-slate-300 rounded m-2 flex p-1.5`}
                id="periodo"
                name="periodo"
                type="number"
                value={formData.periodo}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col min-w-[120px]">
              <label className="">Valor:</label>
              <input
                className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}
                id="valor"
                name="valor"
                value={formData.valor}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-row justify-start gap-2">
              <label className="">Ativo:</label>
              <input
                className={` bg-slate-300 rounded m-2 flex p-1.5`}
                id="ativo"
                name="ativo"
                type="checkbox"
                value={formData.ativo}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="flex w-full justify-end gap-2 mt-4 p-4">
            {msg ? (
              <div className="h-[40px] p-1 justify-center align-middle items-center">
                <p> {msg} </p>
              </div>
            ) : null}
            <button
              className="bg-blue-400 hover:bg-blue-500 admin-button"
              onClick={handlePost}
            >
              Cadastrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NovoPlano;
