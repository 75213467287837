import React, { useEffect, useState } from 'react';
import { GenGet } from 'state/api';
import { CircularProgress } from '@mui/material';
import CustomDataGrid from 'Admin/components/AdminComponents/CustomDataGrid';

function Assinaturas() {
  const [loading, setLoading] = useState(true);
  const [ativas, setAtivas] = useState(null);
  const [inativas, setInativas] = useState(null);


  async function getAssinaturas() {
    try {
      const response = await GenGet(`/assinaturas/index`);
      setAtivas(response.ativas);
      setInativas(response.inativas);

      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  }

  useEffect(() => {
    getAssinaturas();
  }, []);

  const cols = ['ID', 'Usuário', 'Plano', 'Status', 'Validade'];
  return (
    <>
      {!loading ? (
        <div className='p-2 text-[10pt]'>
          <h3 className='text-[14pt] m-0'>Painel de Assinaturas</h3>
          <p className='text-[9pt] -mt-1 py-0 pl-4'>visualize seus assinantes.</p>
          <hr/>
          {ativas && <CustomDataGrid rows={ativas} columns={cols} per_page={25} title={'Assinaturas ativas'} />}
          {inativas && <CustomDataGrid rows={inativas} columns={cols} per_page={25} title={'Assinaturas inativas'} />}
        </div>
      ) : (
        <div className="rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll">
          <div className="w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center">
            <div className="flex flex-col items-center">
              <CircularProgress />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Assinaturas;
