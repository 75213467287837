import React, { useState, useEffect } from 'react'
import { GenGet } from 'state/api';
import { CircularProgress } from '@mui/material';
import MeusAvisos from './Avisos/MeusAvisos';
import EnviarAviso from './Avisos/EnviarAviso';
import EnviarAvisoPublico from './Avisos/EnviarAvisoPublico';

function CampanhaAvisos() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({})

  useEffect(() => {
      async function getData() {
        try {
          const response = await GenGet("/avisos/index")
          setData(response);
          setLoading(false)
          console.log(response)
        } catch (err) {
        }
      }
      getData();
    }, []);
  
  return (
    <>{!loading ?(

    <div className='flex flex-col w-full'>
        <MeusAvisos/>
        <hr className='mt-2'/>
        <EnviarAviso/>
        <hr className='mt-2'/>
        <EnviarAvisoPublico/>
    </div>

    ) : (
    <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
      <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
        <div className='flex flex-col items-center'>
          <CircularProgress />
        </div>
      </div>
    </div>)}</>
    
    
  )
}

export default CampanhaAvisos