import React, { useState, useEffect, useRef } from 'react';
import FontPicker from 'Loja/components/FontPicker';
import PhotoWidgetButton from 'Loja/components/PhotoWidgetButton';
import ColorPicker from './ColorPicker';
import { GenGet, GenPostMultiform } from 'state/api';
import { CircularProgress } from '@mui/material';
import {  Add, MobileScreenShareOutlined, Settings } from '@mui/icons-material';
import * as Icons from '@fortawesome/free-brands-svg-icons';
import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import PhotoWidgetButtonV2 from 'Loja/components/PhotoWidgetButtonV2';
import ButtonsClasses from './ButtonsClasses';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GLOBAL_URL = process.env.REACT_APP_BASE_URL;

function EcardMakerV2() {
  const [formData, setFormData] = useState({ 
    name: '',
    type: 'site',
    icon: 'faFacebook',
    active:1  
  });
  const [editFormData, setEditFormData] = useState({ 
    name: '',
    type: 'site',
    icon: 'faFacebook',
    url:'',
    active:1  
  });
  const [urlValue, setUrlValue] = useState('');
  const [editUrlValue, setEditUrlValue] = useState('');
  const [urlTemplate, setUrlTemplate] = useState('https://www.--seulink--.com/');
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLeadOpen, setIsLeadOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [btnPointer, setbtnPointer] = useState(false);
  const [scssMsg, setScssMsg] = useState(null);
  const [modalMsg, setModalMsg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mock, setMock] = useState(false);
  const [ecardData, setEcardData] = useState({})

  useEffect(() => {
    async function getData() {
      try {
        const response = await GenGet(`/myecard/updating`);
          if (response.mock) {
            setMock(true)
            setEcardData(response.mock);
            setLoading(false)
          } else {
            setEcardData(response);
            setLoading(false)
          }

      } catch (err) {

      }
    }

    getData();
  }, []);

  const [profilePic, setProfilePic] = useState({
    imagem:null,
  });


  const [bgPic, setBgPic] = useState({
    imagem:null,
  });

  const handleProfilePic = (file) =>{
    const url = URL.createObjectURL(file);
    setProfilePic({
      ...profilePic,
      imagem: file})

      setEcardData({
        ...ecardData,
        temp_profile: url,
      });
  }

  const handleBGPic = (file) =>{
    const url = URL.createObjectURL(file);
    setBgPic({
      ...bgPic,
      imagem: file})

    setEcardData({
        ...ecardData,
        temp_bg: url,
      });
  }

  const tipos = {
    'site': {
      'url-template': 'https://--seulink--.com',
      'icon':'faGlobe'
    },
  };

  const icones = {
    'facebook': {
      'icon': 'faFacebook',
      'type':'brand' },

    'whatsapp': {
      'icon': 'faWhatsapp',
      'type':'brand' },

    'linkedin': {
      'icon': 'faLinkedin',
      'type':'brand' },

    'instagram':{
      'icon':'faInstagram',
      'type':'brand'},

    'youtube': {
      'icon': 'faYoutube',
      'type':'brand' },

    'tiktok': {
      'icon': 'faTiktok',
      'type':'brand' },

    'twitter': {
      'icon': 'faTwitter',
      'type':'brand' },

    'github': {
      'icon': 'faGithub',
      'type':'brand' },

    'discord': {
      'icon': 'faDiscord',
      'type':'brand' },

    'shopify': {
      'icon': 'faShopify',
      'type':'brand' },

    'telegram': {
      'icon': 'faTelegram',
      'type':'brand' },

    'googleplay': {
      'icon': 'faGooglePlay',
      'type':'brand' },

    'spotify': {
      'icon': 'faSpotify',
      'type':'brand' },

    'twitch': {
      'icon': 'faTwitch',
      'type':'brand' },
    'site':{
      'icon':'faGlobe',
      'type':'solid'
    },
    'coração': {
      'icon': 'faHeart',
      'type':'solid' },
    'calendário': {
      'icon': 'faCalendarDays',
      'type':'solid' },
    'sino': {
      'icon': 'faBell',
      'type':'solid' },
    'carrinho': {
      'icon': 'faCartShopping',
      'type':'solid' },
    'compras': {
      'icon': 'faBagShopping',
      'type':'solid' },
    'livro': {
      'icon': 'faBook',
      'type':'solid' },
    'moeda': {
      'icon': 'faMoneyBill',
      'type':'solid' },
    'paleta': {
      'icon': 'faPalette',
      'type':'solid' },
    'negócio': {
      'icon': 'faHandshake',
      'type':'solid' },
    'gráfico': {
      'icon': 'faChartSimple',
      'type':'solid' },
    }


  useEffect(() => {
    const selectedTipo = tipos[formData.type];
    if (selectedTipo && selectedTipo['url-template']) {
      setUrlTemplate(selectedTipo['url-template']);
    }
  }, [formData.type]);

  useEffect(() => {
    const selectedTipo = tipos[editFormData.type];
    if (selectedTipo && selectedTipo['url-template']) {
      setUrlTemplate(selectedTipo['url-template']);
    }
  }, [editFormData.type]);

  const handleIcon = (e) =>{

    setFormData({
      ...formData,
      icon: e.target.value
    });
  }

  const handleEditFormIcon = (e) =>{

    setEditFormData({
      ...editFormData,
      icon: e.target.value
    });
  }

  const handleUrlInputChange = (e) => {
    setUrlValue(e.target.value);
  };

  const handleTituloChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleEditNameChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value,
    });
  };


  const handleFormSubmit = () => {
    // Verifica se todos os campos estão preenchidos
    if (!formData.name || !formData.type || !formData.icon || !urlValue) {
      setModalMsg('Todos os campos devem ser preenchidos.');
      setTimeout(() => {
        setModalMsg(null);
      }, 3000);
      return;
    }
  
    // Verifica se a URL é válida
    if (!validateUrl(urlValue)) {
      setModalMsg('URL deve começar com https:// e terminar com um domínio válido como .com, .com.br, etc.');
      setTimeout(() => {
        setModalMsg(null);
      }, 3000);
      return;
    }
  
    // Verifica se já existe um link com o mesmo tipo, exceto para o tipo "site"
    const existingLink = ecardData.links.find(link => link.type === formData.type && link.type !== 'site');
    if (existingLink) {
      setModalMsg(`Para cadastrar mais de um registro do tipo ${formData.type}, use o tipo site.`);
      setTimeout(() => {
        setModalMsg(null);
      }, 3000);
      return;
    }
  
    const newButton = {
      id: ecardData.links.length + 1,
      name: formData.name,
      type: formData.type,
      url: urlValue,
      icon: formData.icon,
      active: 1
    };
  
    setEcardData({
      ...ecardData,
      links: [...ecardData.links, newButton]
    });
  
    setIsModalOpen(false);
    setFormData({
      type: 'site',
      name: '',
      icon: 'faFacebook',
      active: 1
    });
    setUrlValue('');
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleEditModal = (id) => {
    // Encontrar o item correspondente ao id
    const item = ecardData.links.find(item => item.id === id);
      if (item) {
        setEditFormData({
          type: item.type || '',
          name: item.name || '',
          link: item.link || '',
          icon: item.icon || '',
          active:1
        });
      setEditUrlValue(item.url);
      setbtnPointer(id);
    }
    setIsEditOpen(!isModalOpen);
  };

  const handleUpdate = () => {
    if (!validateUrl(editUrlValue)) {
      setModalMsg('URL deve começar com https:// e terminar com um domínio válido como .com, .com.br, etc.');
      setTimeout(() => {
        setModalMsg(null);
      }, 3000);
      return;
    }
  
    // Update the existing link
    const updatedLinks = ecardData.links.map(item =>
      item.id === btnPointer
        ? { ...item, ...editFormData, url: editUrlValue }
        : item
    );
    setEcardData({
      ...ecardData,
      links: updatedLinks,
    });
  
    setIsEditOpen(false); // Close edit modal
    setEditFormData({
      type: 'site',
      name: '',
      icon: 'faFacebook',
      active:1,
      url: '',
    });
    setEditUrlValue('');
  }

  useEffect(() => {
  }, [ecardData]);

  const handleFields = (e) => {
    const { name, value, type, checked } = e.target;
    setEcardData({
      ...ecardData,
      [name]: type === 'checkbox' ? (checked ? 1 : 0) : value,
    });
  };

  const handleColor = (field, color) =>{
    setEcardData({
      ...ecardData,
      [field]: color,
    });
  }

  const handleFont = (font) =>{
    setEcardData({
      ...ecardData,
      font: font,
    });
  }

  const validateUrl = (url) => {
    const regex = /^(https:\/\/)(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:\/[^\s]*)?$/;
    return regex.test(url);
  };

  const handleSubmit = () => {
    const finalData = { ...ecardData, temp_profile: profilePic.imagem, temp_bg: bgPic.imagem };
  
    // Função de validação
    const validate = (data) => {
      const requiredFields = [
        'title',
        'subtitle',
        'ring_color',
        'font',
        'font_color',
        'background_color',
        'button_type',
        'button_color',
        'button_text_color',
      ];
  
      for (const field of requiredFields) {
        if (!data[field] || data[field].trim() === '') {
          return `Preencha todos os campos, e adicione ao menos um link.`;
        }
      }
  
      if (!data.profile_pic && !data.temp_profile){
        return 'Uma foto de perfil é obrigatória.';
      }
  
      if (!data.links || data.links.length === 0) {
        return 'Pelo menos um link deve ser adicionado.';
      }
  
      return null;
    };
  
    // Chamada de validação
    const validationError = validate(finalData);
    if (validationError) {
      setScssMsg(validationError);
      setTimeout(() => {
        setScssMsg(null);
      }, 3000);
      return;
    }
  
    async function postData() {
      try {
        if (!mock) {
          const response = await GenPostMultiform("/myecard/update", finalData);
          setScssMsg(response.message);
        } else {
          const response = await GenPostMultiform("/myecard/create", finalData);
          setScssMsg(response.message);
        }
  
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
  
      } catch (err) {
        setScssMsg("Houve algum erro ao salvar Ecard.");
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
      }
    }
    
    postData();
  };
  

  const handleRemoveLink = (id) => {
    setEcardData({
      ...ecardData,
      links: ecardData.links.map(link =>
        link.id === id ? { ...link, active: 0 } : link
      )
    });
  };

  const handleButtonClose = () => {
    toggleModal();
    setFormData({ 
      type: 'site', 
      name: '', 
      icon: 'faFacebook',
      active:1 });
    setUrlValue('');
  }

  const BioImage = (data) => {
    return(
      <div className={`flex flex-col justify-center max-w-[340px] align-middle items-center mb-2 p-0 ${data.font}`} 
      style={{typography: { fontFamily: data.font} }}>
        <div
          className='relative w-[100%] min-w-[250px] max-w-[350px] sm:max-w-[50vw] cursor-pointer mb-2 rounded-full overflow-hidden border-2'
          style={{ borderColor: data.ring_color, paddingBottom: '100%' }}
        >
          <div className='absolute inset-0 w-full max-w-[340px] h-full flex justify-center items-center'>
            <div className='flex justify-center items-center'>
              <img
                src={data.temp_profile ? data.temp_profile : `${GLOBAL_URL}/storage/${data.profile_pic}`}
                alt="Foto de Perfil"
                className='w-full h-full object-cover'
              />
            </div>
          </div>
        </div>
      </div>

    );
  }

  const backgroundImageStyle = () => {
    if (ecardData.temp_bg) {
      return `url(${ecardData.temp_bg})`;
    } else if (ecardData.background_pic) {
      return `url(${GLOBAL_URL}/storage/${ecardData.background_pic})`;
    } else {
      return null;
    }
  };

  const DraggableButtons = ({ ecardData, setEcardData }) => {
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [links, setLinks] = useState([]);
    const [initialTouchPos, setInitialTouchPos] = useState(null);
  
    useEffect(() => {
      // Initialize links from ecardData, ensuring they are sorted by order
      const sortedLinks = [...ecardData.links].sort((a, b) => a.order - b.order);
      setLinks(sortedLinks);
    }, [ecardData.links]);
  
    const handleDragStart = (index) => {
      setDraggedIndex(index);
      document.body.classList.add('no-scroll'); // Disable scroll
    };
  
    const handleDragOver = (event) => {
      event.preventDefault(); // Necessary to allow drop
    };
  
    const handleDrop = (index) => {
      const updatedLinks = Array.from(links);
      const [movedItem] = updatedLinks.splice(draggedIndex, 1);
      updatedLinks.splice(index, 0, movedItem);
  
      // Update button order
      const reorderedLinks = updatedLinks.map((link, idx) => ({
        ...link,
        order: idx, // Adjust the `order` field based on the new position
      }));
  
      setLinks(reorderedLinks);
      setEcardData({ ...ecardData, links: reorderedLinks });
      setDraggedIndex(null);
      document.body.classList.remove('no-scroll'); // Enable scroll again
    };
  
    const handleDragEnd = () => {
      document.body.classList.remove('no-scroll'); // Ensure scroll is enabled even if drop doesn't occur
    };
  
    const handleTouchStart = (event, index) => {
      const touch = event.touches[0];
      document.body.classList.add('no-scroll');
      setInitialTouchPos({ x: touch.clientX, y: touch.clientY });
      handleDragStart(index);
    };
  
    const handleTouchMove = (event) => {
      event.preventDefault(); // Prevent scroll during touch drag
    };
  
    const handleTouchEnd = (event, index) => {
      const touch = event.changedTouches[0];
      document.body.classList.remove('no-scroll');
      const deltaX = touch.clientX - initialTouchPos.x;
      const deltaY = touch.clientY - initialTouchPos.y;
  
      if (Math.abs(deltaX) < 5 && Math.abs(deltaY) < 5) {
        // Consider it a click, not a drag
        handleDragEnd();
        return;
      }
  
      const dropIndex = Math.floor(index + deltaY / 50); // Adjust based on your item height
      handleDrop(dropIndex);
      handleDragEnd();
    };
  
    return (
      <div className="w-[90vw] h-[100%] flex flex-col justify-center">
        {links && links.length > 0 && (
          links
            .filter(link => link.active === 1)
            .map((link, index) => (
              <div
                key={link.id}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(index)}
                onDragEnd={handleDragEnd} // Add handleDragEnd here
                onTouchStart={(event) => handleTouchStart(event, index)}
                onTouchMove={handleTouchMove}
                onTouchEnd={(event) => handleTouchEnd(event, index)}
                
              >
                <div key={index}>
                  <ButtonsClasses
                    prop={link}
                    blockClick={true}
                    dragable={true}
                    action={toggleEditModal}
                    type={{
                      font: ecardData.font,
                      type: ecardData.button_type,
                      bgcolor: ecardData.button_color,
                      textcolor: ecardData.button_text_color
                    }}
                  />
                </div>
              </div>
            ))
        )}
      </div>
    );
  };

  const CustomDropdown = ({ icones, formData, handleIcon }) => {
    const [isOpen, setIsOpen] = useState(false);
    const findKeyByIcon = (iconName) => {
      for (const [key, value] of Object.entries(icones)) {
        if (value.icon === iconName) {
          return key;
        }
      }
      return null; // Retorna null se o ícone não for encontrado
    };
    const [selectedIcon, setSelectedIcon] = useState({ key: '', icon: '' });
    const dropdownRef = useRef(null);
  
    const toggleDropdown = () => setIsOpen(!isOpen);

    // Configura o estado inicial com base no formData
    useEffect(() => {
      const key = findKeyByIcon(formData.icon);
      setSelectedIcon({ key: key, icon: formData.icon });
    }, [formData.icon]);

    const handleSelect = (key) => {
      setSelectedIcon({'key':key, icon:key.icon });
      handleIcon({ target: { name: 'icon', value: key.icon } });
      setIsOpen(false);
    };
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    return (
      <div className="custom-dropdown" ref={dropdownRef}>
        <p className='p-0 m-0'>ícone</p>
        <button className="rounded-md w-[180px] dropdown-button" onClick={toggleDropdown}>
        {selectedIcon.key ? (
          <>
            {icones[selectedIcon.key]?.type && (
              <FontAwesomeIcon
                icon={
                  icones[selectedIcon.key].type === 'brand'
                    ? Icons[icones[selectedIcon.key].icon]
                    : SolidIcons[icones[selectedIcon.key].icon]
                }
                style={{ fontSize: '18pt', marginRight: '10px' }}
              />
            )}
            {selectedIcon.key}
          </>
        ) : 'Selecione um icone'}
        </button>
        {isOpen && (
          <div className="max-h-[250px] rounded-md w-[180px] overflow-y-scroll dropdown-content">
            {Object.keys(icones).map((key) => {
              const icone = icones[key];
              const iconComponent = icone?.type === 'brand' ? Icons[icone.icon] : SolidIcons[icone.icon];
              return (
                <div key={key} className="justify-between flex cursor-pointer hover:bg-gray-200 p-1" onClick={() => handleSelect(icone)}>
                  <p className='font-semibold'>{key}</p>
                  {icone?.type && (
                    <FontAwesomeIcon
                      style={{ fontSize: '18pt', marginRight: '10px' }}
                      icon={iconComponent}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="relative flex flex-col bg-gradient-to-bl from-blue-800 to-red-700 min-h-[100vh]">
      {loading ? (
        <div className='w-full h-[100vh] flex justify-center '>
          <CircularProgress/>
        </div>

      ) : (
        <>
        <div  className="flex flex-col w-[100%] h-[100%] min-h-[100vh] rounded-lg align-middle items-center mt-1 mb-1 overflow-hidden pt-10"
            style={{ 
              backgroundColor: ecardData.background_color, 
              backgroundImage: backgroundImageStyle(),  
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center' 
              }}>
            {ecardData.profile_pic ? (
              <PhotoWidgetButtonV2 data={ecardData} setData={handleProfilePic} element={BioImage} />
              
            ) : (
              <p>Carregando imagem...</p>
            )}
            <div className='relative flex w-[90vw] flex-col justify-center align-middle items-center'>
              <h2 className={`text-[18pt] mx-4 cursor-pointer`} style={{color:ecardData.font_color}} onClick={toggleDrawer}>{ecardData.title.length === 0 ? "Insira um título" : ecardData.title}</h2>
            </div>
            <p className={`text-[12pt] -mt-3 -mr-14 cursor-pointer`} style={{color:ecardData.font_color}} onClick={toggleDrawer}>{ecardData.subtitle.length === 0 ? "Insira um subtítulo" : ecardData.subtitle}</p>

            {ecardData.profile_pic && (
            <div style={{
              display: 'flex',
              flexDirection:'column',
              alignItems: 'center',
              width: '60%',
              maxWidth:'350px', // ou ajuste a porcentagem desejada
              minWidth: '150px',
              
            }}>
              <DraggableButtons ecardData={ecardData} setEcardData={setEcardData}/>
              <div className="flex justify-center m-[4px] w-[100%]">
                <div
                  className='flex align-middle items-center justify-between px-2 w-[100%] h-[40px] rounded-md border-dashed border-[2px] border-gray-600 cursor-pointer bg-blue-50 bg-opacity-60 hover:bg-blue-200 text-gray-600'
                  onClick={toggleModal}
                  >
                  <div className='flex flex-grow justify-center'><p className='p-0 m-0'>Novo Link</p></div>
                  <Add/>
                </div>
              </div>
            </div>
            )}
            {ecardData.lead_active === 1 && (
              <div className='flex flex-col w-full'>
              <hr/>
              <ButtonsClasses key={99} prop={{name:'Receba minhas novidades!'}} action={null} type={{font: ecardData.font, type:'lead', bgcolor:ecardData.button_color, textcolor:ecardData.button_text_color}} />
              </div>
              
            )}

        </div>
          {scssMsg && (
            <div className='absolute top-10 left-2 p-2 rounded-md z-1 shadow-md bg-gray-100 border flex justify-center align-middle items-center'><p className='font-semibold text-[11pt] p-0 m-0'>{scssMsg}</p></div>
          )}
          
        <div className={`lg:w-1/4 lg:max-w-[300px] bg-gray-200 fixed right-0 top-0 bottom-0 z-50 h-[100%] overflow-scroll transition-transform duration-300 ${isDrawerOpen ? 'translate-x-0' : 'translate-x-full'}`}>
          <div className="p-4">
            <h3 className="text-xl font-semibold mb-4">Opções</h3>
            <div className="mb-4">
              <div className="flex flex-col gap-4 mt-2">
              <PhotoWidgetButton data={bgPic} setData={handleBGPic} title={'imagem de fundo'}/>
              </div>
            </div>
            <div className="mb-4">
              <h4 className="text-lg font-semibold">Apresentação</h4>
              <div className="flex flex-col gap-2 mt-2">
                <p className='text-[9pt] font-semibold m-0 p-0'>Nome / Título</p>
                <input className="bg-slate-200 text-sm border-slate-400 border-1 rounded-lg text-center min-w-[220px] mt-0" type="text" placeholder="Título" name={'title'} value={ecardData.title} onChange={handleFields}/>
                <p className='text-[9pt] font-semibold m-0 p-0'>Sub-Título</p>
                <input className="bg-slate-200 text-sm border-slate-400 border-1 rounded-lg text-center min-w-[220px] mt-0" type="text" placeholder="Subtítulo" name={'subtitle'} value={ecardData.subtitle} onChange={handleFields}/>
              </div>
              <div className="mb-4">
              <h4 className="text-lg font-semibold">Selecionar Fonte</h4>
                <div className="mt-2">
                  <FontPicker setFont={handleFont}/>
                </div>
              </div>
              <div className="w-[100%] flex flex-col mt-2">
                <h4 className="text-[12pt] font-semibold">Borda da foto:</h4>
                <div className="w-full justify-end">
                  <ColorPicker current={ecardData.ring_color} setColor={handleColor} field={'ring_color'}/>
                </div>
              </div>
              <hr />
              <div className="w-[100%] flex flex-col">
                <h4 className="text-[12pt] font-semibold">Cor de fundo:</h4>
                <div className="w-full justify-end">
                  <ColorPicker current={ecardData.background_color} setColor={handleColor} field={'background_color'}/>
                </div>
              </div>
              <hr />
              <div className="w-[100%] flex flex-col">
                <h4 className="text-[12pt] font-semibold">Cor de texto:</h4>
                <div className="w-full justify-end">
                  <ColorPicker current={ecardData.font_color} setColor={handleColor} field={'font_color'}/>
                </div>
              </div>
              <hr />
              <div className="w-[100%] flex flex-col">
                <h4 className="text-[12pt] font-semibold">Cor dos botões:</h4>
                <div className="w-full justify-end">
                  <ColorPicker current={ecardData.button_color} setColor={handleColor} field={'button_color'}/>
                </div>
              </div>
              <div className="w-[100%] flex flex-col">
                <h4 className="text-[12pt] font-semibold">Cor do texto dos botões:</h4>
                <div className="w-full justify-end">
                  <ColorPicker current={ecardData.button_text_color} setColor={handleColor} field={'button_text_color'}/>
                </div>
              </div>
            </div>
            <div className='flex gap-1 justify-between'>
            <label className='mr-2'>
              Receber cadastros de emails? 
            </label>
            <input
                type="checkbox"
                name="lead_active"
                checked={ecardData.lead_active === 1}
                onChange={handleFields}
              />
            </div>
            <br/>
            <div className='flex gap-1 justify-between'>
            <label className='mr-2'>
              Ecard Ativo? 
            </label>
            <input
                type="checkbox"
                name="active"
                checked={ecardData.active === 1}
                onChange={handleFields}
              />
            </div>
            

            <hr/>
            <div className="flex flex-col h-[100%]">
              <h4 className="text-lg font-semibold">Botões / Links</h4>
              <div className='w-full h-[100%] flex flex-col overflow-auto'>
              {ecardData.links && ecardData.links.length > 0 ? (
                ecardData.links
                  .filter(item => item.active === 1) // Filtra links ativos
                  .map(item => (
                    <div key={item.id} className="mb-2 flex justify-between">
                      <button 
                        className={`bg-gradient-to-bl from-blue-500 to-red-500 hover:bg-gradient-to-bl hover:from-blue-400 hover:to-red-400 text-slate-200 font-semibold p-[2px] rounded-sm shadow-md h-[30px] text-[9pt] w-[60%]`} 
                        onClick={() => toggleEditModal(item.id)}
                      >
                        {item.name}
                      </button>
                      <span 
                        className='flex justify-center h-[30px] align-middle px-2 m-0 shadow-md hover:bg-red-500 cursor-pointer bg-red-400 rounded-sm text-white text-[9pt] p-1' 
                        onClick={() => handleRemoveLink(item.id)}
                      >
                        <p>excluir</p>
                      </span>
                    </div>
                  ))
              ) : (
                <p>crie seus links!</p>
              )}
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-4">
              <button className="bg-blue-500 text-white p-2 rounded" onClick={toggleModal}>
                Novo Link
              </button>
            </div>
            <div className="flex flex-col gap-4 mt-4">
              <button className="bg-blue-500 text-white p-2 rounded" onClick={handleSubmit}>
                Salvar Ecard
              </button>
            </div>
    
          </div>
        </div>
        <button className="fixed top-15 right-10 z-50 bg-blue-300 p-2 rounded-full shadow-md" onClick={toggleDrawer}>
        <Settings/>
        </button>
          
          {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-4 rounded w-[80%]">
                <h2 className="text-xl mb-4">Novo Link</h2>
                <div className="mb-4">
                </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Titulo</label>
                    <input type="text" name={'name'} value={formData.name} onChange={handleTituloChange} className="mt-1 block w-full pl-3 pr-10 py-2 border-[1px] text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" />
                  </div>
                  <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">URL</label>
                      <input type="text" value={urlValue} placeholder={urlTemplate} onChange={handleUrlInputChange} className="mt-1 block w-full pl-3 border-[1px] pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" />    
                  </div>
                  <CustomDropdown icones={icones} formData={formData} handleIcon={handleIcon} />
                
                <div>
                {modalMsg && (<p>{modalMsg}</p>)}
                </div>
                
                <div className='w-full flex flex-row-reverse gap-2'>
                  <button className="bg-blue-500 text-white p-1 px-2 rounded-sm" onClick={handleFormSubmit}>
                    Confirmar
                  </button>
                  <button className="bg-red-500 text-white p-1 px-2 rounded-sm ml-2" onClick={handleButtonClose}>
                    Cancelar
                  </button>
                </div>

              </div>
            </div>
          )}
          {isEditOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-4 rounded w-[80%]">
                <h2 className="text-xl mb-4">Editar Link</h2>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Titulo</label>
                    <input type="text" name={'name'} value={editFormData.name} onChange={handleEditNameChange} className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 border-[1px] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">URL</label>
                    <input type="text" value={editUrlValue} placeholder={urlTemplate} onChange={(e) => setEditUrlValue(e.target.value)} className="mt-1 block w-full pl-3 pr-10 border-[1px] py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" />
                  </div>
                  <CustomDropdown icones={icones} formData={editFormData} handleIcon={handleEditFormIcon} />
                <div>
                  {modalMsg && (<p>{modalMsg}</p>)}
                </div>
                <div className='w-full flex flex-row-reverse gap-2'>
                  <button className="bg-blue-500 text-white  p-1 px-2 rounded-sm" onClick={handleUpdate}>
                    Confirmar
                  </button>
                  <button className="bg-red-500 text-white  p-1 px-2 rounded-sm"  onClick={() => setIsEditOpen(false)}>
                    Cancelar
                  </button>
                </div>

              </div>
            </div>
          )}
        </>
      )}

    </div>
  );
}

export default EcardMakerV2;





{/*
        {currentPage === 'choose-img' ? 
        <FileInput onImageSelected = {onImageSelected} />
        : currentPage === 'crop-img' ? 
        <ImageCrooper 
          image={image} 
          onCropDone={onCropDone} 
          onCropCancel={onCropCancel}/> : <div></div>}
*/}