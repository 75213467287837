import { CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { GenGet } from 'state/api';

function CarrosselPlanos({handlePost}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [planos, setPlanos] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % planos.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + planos.length) % planos.length);
  };

  const dataSet = (planos) => {
    const formattedPlanos = planos.map((item) => ({
      id: item.id,
      nome: item.nome,
      descricao: item.descricao,
      preco: `${item.valor}/${item.periodo} ${item.tipo_periodo}`
    }));
    setPlanos(formattedPlanos);
  };

  useEffect(()=>{
    async function getPlanos() {
      const response = await GenGet('/open/planos');
      dataSet(response);
      setLoading(false);
    }
    getPlanos();
  },[])

  return (
    <div className="flex relative items-center justify-center align-middle w-[90%]">
      {loading?(
        <div className='p-1 mt-[10vh] mb-[10vh] w-full flex justify-center align-middle items-center'><CircularProgress color="success"/></div>
      ):(
        <>
        <div className="overflow-hidden z-0">
          <div className="flex transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
            {planos.map((plano) => (
              <div key={plano.id} className="w-[100%]  flex-shrink-0 p-3">
                <div className="bg-gray-100 rounded-lg h-[400px]  shadow-md p-4">
                  <h3 className="font-extrabold text-xl text-blue-900">{plano.nome}</h3>
                  <p className="text-gray-600 p-4 h-[200px]">{plano.descricao}</p>
                  <p className="text-blue-600 text-lg text-end">Apenas {plano.preco}</p>    
                  <div className='flex justify-end mt-2 w-[100%] '>
                    <button className='text-[14px] p-1 bg-blue-500 rounded-lg font-medium hover:bg-blue-600 shadow-md px-4 text-slate-100' onClick={() => handlePost({id:plano.id, valor:plano.preco})}>ASSINAR</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button onClick={handlePrev} className="z-1 absolute left-0 h-[90%] items-center justify-center bg-gray-300 text-grey hover:bg-gray-100 bg-opacity-70 rounded-s-md text-[28pt] px-2">
          ‹
        </button>
        <button onClick={handleNext} className="z-1  absolute right-0 items-center h-[90%] justify-center bg-gray-300 text-grey hover:bg-gray-100 bg-opacity-70 rounded-e-md text-[28pt] px-2">
          ›
        </button>        
        </>
      )}

    </div>
  );
}

export default CarrosselPlanos;