import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GenGet } from 'state/api';


const mockSubs = [
  { email: 'Carregando', name: '-', date: '-' },
];

function UserSubs() {
  const [subs, setSubs] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredSubs, setFilteredSubs] = useState([]);
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({});

  useEffect(() => {
    async function getData() {
      try {
        const response = await GenGet(`/leads/dashbord`);
        setData(response);
        setLoading(false)
        setFilteredSubs(response.lead_list)
      } catch (err) {

      }
    }

    getData();
    
    // 
    setSubs(mockSubs);
  }, []);


  useEffect(() => {
    setFilteredSubs(
      subs.filter(sub =>
        sub.name.toLowerCase().includes(filter.toLowerCase()) ||
        sub.email.toLowerCase().includes(filter.toLowerCase()) ||
        new Date(sub.date).toLocaleDateString().includes(filter)
      )
    );
  }, [filter, subs]);

  const exportEmailsToCSV = () => {
    const emails = filteredSubs.map(sub => sub.email).join(',');
    const csvContent = `data:text/csv;charset=utf-8,${emails}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'emails.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="p-4 bg-slate-100 min-h-screen text-[#256a9a]">
       <h2 className="text-2xl font-semibold">Painel de inscrições</h2>
      {loading ? (<div className='h-[200px] flex justify-center align-middle items-center'> <CircularProgress color="info" sx={{marginTop:'150px'}} /></div>) : (
              <div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
             
                <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
                  <p className="text-xl font-semibold">Total</p>
                  <p className="text-3xl">{data.totalLeads}</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
                  <p className="text-xl font-semibold">hoje</p>
                  <p className="text-3xl">{data.totalLeads}</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
                  <p className="text-xl font-semibold">essa semana</p>
                  <p className="text-3xl">{data.totalLeadsWeek}</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
                  <p className="text-xl font-semibold">esse mês</p>
                  <p className="text-3xl">{data.totalLeadsMonth}</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
                  <p className="text-xl font-semibold">esse ano</p>
                  <p className="text-3xl">{data.totalLeadsYear}</p>
                </div>
              </div>
        
              <div className="mb-2 flex flex-row gap-2">
                <input
                  type="text"
                  placeholder="Filtrar por nome, email ou data"
                  className="w-full p-2 border border-gray-300 rounded-lg"
                  value={filter}
                  onChange={e => setFilter(e.target.value)}
                />
                <button className='w-[150px] flex justify-center align-middle items-center border-[1px] rounded-md hover:bg-[#83b7dd] border-[#256a9a]' onClick={exportEmailsToCSV}>Baixar CSV</button>
              </div>
        
              <div className="overflow-x-auto">
                <table className="w-full bg-white border min-w-[500px] overflow-x-scroll">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border">Email</th>
                      <th className="px-4 py-2 border">Nome</th>
                      <th className="px-4 py-2 border">Data de Registro</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSubs.length > 0  ? filteredSubs.map((sub, index) => (
                      <tr key={index} className="even:bg-gray-100">
                        <td className="px-4 py-2 border">{sub.email}</td>
                        <td className="px-4 py-2 border">{sub.nome}</td>
                        <td className="px-4 py-2 border">{new Date(sub.created_at).toLocaleDateString()}</td>
                      </tr>
                    )) : (
                      <tr className="even:bg-gray-100">
                        <td className="px-4 py-2 border">Nenhum lead cadastrado</td>
                        <td className="px-4 py-2 border">-</td>
                        <td className="px-4 py-2 border">-</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              </div>
      )}

      

    </div>
  );
}

export default UserSubs;