import React, {useEffect} from 'react';

function BubblesBG() {
        useEffect(() => {
          // Nenhuma lógica adicional necessária aqui, pois a lógica de manipulação do mouse já está sendo tratada no arquivo bubbleMouseEvent.js
        }, []);
    return (
      <div className='gradiant-bg inferior-layer no-scrollbar'>
        <svg xmlns='http://www.w3.org/2000/svg'>
        <defs>
            <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation='10' result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8" result='goo' />
            <feBlend in="SourceGraphic" in2="goo" />
            </filter>
        </defs>
        </svg>
        <div className='gradiants-container'>
            <div className='g1'></div>
            <div className='g2'></div>
            <div className='g3'></div>
            <div className='g4'></div>
            <div className='g5'></div>
            <div className='interactive'></div>
        </div>   
      </div>
    );
  }
  
  export default BubblesBG;