import { CircularProgress } from "@mui/material";
import React,  { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GenPost } from "state/api";

function PassForgot() {
  const nav = useNavigate();
  const [ confirmed, setConfirmed ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData ] = useState({
    email:'',
    token:'',
    password:'',
    password_confirmation:''
  })
  const [msg, setMsg ] = useState(null)
  {/* /auth/request_new_pass / /auth/register */}
  const handleRequest = () => {
    async function requestPass(){
      try {
        setLoading(true);
        const response = await GenPost('/auth/request_new_pass', {email:formData.email});
        setLoading(false);
        setMsg(response.message)
        setConfirmed(true);
        setTimeout(() => {
          setMsg(null);
        }, 3000);

      } catch (err){
        setLoading(false);
        setMsg(err.message)
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    requestPass()
  }

  const handleChange = (e) =>{
    setFormData({
      ...formData,
      [e.target.name]:e.target.value
    })
  }

  const handlePassword = () => {
    if (formData.password !== formData.password_confirmation){
      setMsg("Senhas não conferem.");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }

    async function requestPass(){
      try {
        const response = await GenPost('/auth/reset_pass', formData);
        setMsg(response.message)
        setTimeout(() => {
          setMsg(null);
          nav('/login');
        }, 3000);
        

      } catch (err){
        setMsg(err.message)
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }
    }
    requestPass()
  }

  return (
    <div className="w-full flex h-[80vh] flex-col gap-4 justify-center items-center bg-blue-950 py-10 pt-0">
    <div className="w-full text-left px-4 bg-blue-950">
      <h3 className="archivo-black-regular -mb-0 mt-4 text-3xl lg:text-[24pt] md:text-[20pt]">
        PAINEL E-CARD!
      </h3>
      <h3 className="archivo-black-regular2">
        insira um email válido para recuperação!
      </h3>
    </div>
    <div className="flex flex-col w-[100%] justify-center items-center">
      <div className="flex flex-col relative max-w-[350px] w-full p-4 bg-gradient-to-bl from-red-500 to-blue-500 gap-2 text-slate-200 rounded-md shadow-lg m-10">
        <h3 className="archivo-black-regular text-[16pt]">Recuperar senha</h3>
        <hr />
        {confirmed ? (
          <>
          <input
            className="p-1 rounded-lg custom-input text-slate-600"
            placeholder="código de verificação"
            value={formData.token}
            name='token'
            onChange={handleChange}
            />   
          <input
            className="p-1 rounded-lg custom-input text-slate-600"
            placeholder="nova senha"
            name='password'
            type="password"
            value={formData.password}
            onChange={handleChange}
            />
          <input
            className="p-1 rounded-lg custom-input text-slate-600"
            placeholder="confirme a senha"
            name='password_confirmation'
            type="password"
            value={formData.password_confirmation}
            onChange={handleChange}
            />   
          </>

        ) : (
          <input
            className="p-1 rounded-lg custom-input text-slate-600"
            placeholder="seu e-mail"
            name='email'
            value={formData.email}
            onChange={handleChange}
            />
        )}

          <div className="flex w-full min-h-[30px] p-1 justify-center align-middle items-center">
            {loading && !msg && (<CircularProgress size={30}/>)}
            {!loading && msg && (<p>{msg}</p>)}
          </div>
        <div className="flex justify-end w-100%">
          {confirmed ? (          
          <button className="text-[14px] p-1 bg-blue-500 rounded-lg font-medium hover:bg-blue-600 shadow-md px-4" onClick={handlePassword}>
          Alterar
          </button>) : (
          <button className="text-[14px] p-1 bg-blue-500 rounded-lg font-medium hover:bg-blue-600 shadow-md px-4" onClick={handleRequest}>
          Solicitar
          </button>)}
        </div>

        <br />
        <br />
        <div className="flex flex-col gap-1 justify-center items-center w-[100%] bottom-0 left-0 absolute">
          <p className="text-[8pt] m-0 p-0">
            entrar em sua conta?{" "}
            <a
              className="cursor-pointer underline text-cyan-400 font-semibold hover:text-cyan-500"
              onClick={() => nav("/login")}
            >
              clique aqui
            </a>
          </p>
          <p className="text-[8pt] ">
            Não tem uma conta?{" "}
            <a
              className="cursor-pointer underline text-cyan-400 font-semibold hover:text-cyan-500"
              onClick={() => nav("/signup")}
            >
              cadastrar
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default PassForgot