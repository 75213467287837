import { Box, Modal } from '@mui/material';
import CarrosselPlanos from 'Loja/components/compLoja/CarrosselPlanos'
import MercadoPago from 'Loja/components/compLoja/MercadoPago';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
const GLOBAL_URL = process.env.REACT_APP_BASE_URL;

function UserSubscribe() {
  const nav = useNavigate();
    const [msg, setMsg] = useState(null);
    const [mpInit, setMpInit] = useState(null);

    const handlePlan = (data) => {
        setMpInit({
          valor:parseFloat(data.valor),
          plano_id:data.id,
        })
      };

    const handleCloseModal = () =>{
          setMpInit(null);
          nav('/userpannel');
      }

  return (
    <div className='p-2 flex flex-col bg-blue-950 text-white min-h-[98vh]'>
        <h3 className=' ml-2 mt-2 archivo-black-regular2 text-white'>Conclua sua assinatura</h3>
        <p className='ml-4 mt-1 text-wrap'>Para Acessar o app você precisa de uma assinatura, <br/> caso já tenha assinado aguarde o processamento de sua assinatura.</p>
        <div className='relative flex flex-col justify-center items-center align-middle overflow-hidden w-[100%] mt-2'>
        <CarrosselPlanos handlePlano={true} handlePost={handlePlan}/>
        <div className='flex justify-center align-middle items-center p-2 h-[50px]'>
        {msg && (
          <p className='text-white font-semibold p-2 rounded-md px-2 border-[1px] border-white'>{msg}</p>
        )}
        </div>
        <div className='w-full px-4 bg-blue-950 text-right'>
          <h3 className='archivo-black-regular6 -mb-0 mt-1 text-3xl lg:text-[24pt] md:text-[20pt]'>NÃO FIQUE OFF,</h3>
          <h3 className='archivo-black-regular2'>ASSINE JÁ!</h3>
        </div>
        <Modal
            open={mpInit !== null}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            >
                <Box className='no-scrollbar' sx={{ 
                position: 'absolute', 
                top: '50%', 
                left: '50%',
                maxHeight:'90%',
                overflow:'scroll', 
                transform: 'translate(-50%, -50%)', 
                width: 400, 
                bgcolor: '#1E3A8A',
                color:'white',
                borderRadius:2,
                minWidth:'70%',  
                boxShadow: 24, 
                p: 4 
                }}>
                <h3 id="modal-title" className='text-[14pt]'>Realize seu pagamento</h3>
                <p id="modal-description" className='my-0 pl-2 -pt-1 mb-2 text-[10pt]'>
                    aguarde, o carregamento do pagamento pode demorar alguns segundos.
                </p>
                <div className='h-full my-2'>
                {mpInit && <MercadoPago valor={mpInit.valor} id={mpInit.plano_id} user_data={null}/>}
                </div>
                
                <div className='p-2 w-full rounded-md flex border-white border-[1px] flex-col sticky bottom-0'>
                <p id="modal-title" className='text-[10pt] m-0 p-0 '>observação:</p>
                <p id="modal-description" className='my-0 pl-2 -pt-1 text-[9pt] flex gap-1'>
                    Após a conclusão e finalização de pagamento, <p className='text-cyan-300 cursor-pointer' onClick={handleCloseModal}>clique aqui</p> para fechar e ser redirecionado para seu painel.
                </p>
                </div>

                </Box>

        </Modal>
      </div>
    </div>
  )
}

export default UserSubscribe