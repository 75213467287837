import React, { useState, useEffect } from 'react'
import { GenGet } from 'state/api';
import { CircularProgress } from '@mui/material';

function UserMessages() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [avisos, setAvisos] = useState([]);
  const [aviso, setAviso] = useState({});
  const [lidos, setLidos] = useState(false);
  const [refresh, setRefresh] = useState(false);

  async function getData() {
    try {
      const response = await GenGet("/myavisos")
      setData(response);
      setAvisos(response['nao-lidos'])
      setLoading(false)
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (refresh) {
      getData();
      setRefresh(false); // Reset refresh after data is fetched
    }
  }, [refresh]);

  const handleLidos = (status) => {
    if (status === 'lidos') {
      setLidos(true);
      setAvisos(data.lidos);
      setAviso({});
    } else {
      setLidos(false);
      setAvisos(data['nao-lidos']);
      setAviso({});
    }
  }

  const handleAviso = (aviso) => {
    if (aviso.status === 'enviado') {
      async function postData() {
        try {
          const response = await GenGet(`avisos-status/${aviso.id}`)
          setRefresh(true);
        } catch (err) {
          console.error(err);
        }
      }
      postData();
    }
    setAviso(aviso);
  }

  if (loading) {
    return (
        <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
            <div className='w-[100vw] sm:w-[85vw] h-[30vh] p-2 flex items-center justify-center'>
                <div className='flex flex-col items-center'>
                <CircularProgress />
                </div>
            </div>
        </div>
    );
  }

  return (
    <div className="p-4 bg-slate-100 text-[#256a9a]">
       <h2 className="text-2xl font-semibold">Avisos e mensagens</h2>
      <div className='w-full flex flex-col md:flex-row'>
        <div className='flex flex-col w-full md:w-1/3 min-h-[300px] max-h-[500px] p-2 rounded-md'>
          <div className='w-full flex flex-row justify-between rounded-t-md text-white'>
            <div className={`w-1/2 p-1 rounded-s-md ${!lidos ? 'bg-[#256a9a]' : 'bg-[#38729b]'} hover:bg-[#144b72] cursor-pointer`} onClick={() => handleLidos('nao-lidos')}>
              <p>Não Lidos</p>
            </div>

            <div className={`w-1/2 p-1 rounded-e-md ${lidos ? 'bg-[#256a9a]' : 'bg-[#6a899f]'} hover:bg-[#144b72] cursor-pointer`} onClick={() => handleLidos('lidos')}>
              <p>Lidos</p>
            </div>
          </div>
          <div className='p-2 flex flex-col bg-slate-200 gap-1 overflow-y-auto mt-2 flex-grow rounded-md text-white'>
            {avisos && avisos.map(item => (
              <div
                key={item.id}
                className='p-1 bg-slate-100 hover:bg-blue-100 border-2 border-[#256a9a] rounded-sm cursor-pointer text-[#256a9a]'
                onClick={() => handleAviso(item)}
              >
                <p>{item.titulo}</p>
              </div>
            ))}
          </div>
        </div>

        <div className='flex flex-col w-full md:w-2/3 min-h-[300px] max-h-[500px] overflow-y-auto p-2 rounded-md'>
          {Object.keys(aviso).length === 0 ? (
            <>
              <div className='w-full flex flex-row bg-[#256a9a] p-1 rounded-t-md'>
                <p>Escolha um aviso</p>
              </div>
              <div className='p-4 flex text-wrap flex-col gap-2 border-2 border-[#256a9a] bg-slate-200 flex-grow rounded-b-md'>
                {/* Espaço reservado */}
              </div>
            </>
          ) : (
            <>
              <div className='w-full flex flex-row bg-[#256a9a] p-1 rounded-t-md text-white'>
                <p>{aviso.titulo}</p>
              </div>
              <div className='p-4 flex text-wrap flex-col gap-2 border-2 border-[#256a9a] bg-slate-200 flex-grow rounded-b-md'>
                <p>{aviso.aviso}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserMessages
