import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GenPost } from "state/api";
import { cookie } from "state/axios";

function LoginPage() {
  const nav = useNavigate();
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    await cookie.get('/sanctum/csrf-cookie');
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      nav('/userpannel');
    }
  }, []);

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    await handlePost();
    setLoading(false);
  };

  const handlePost = async () => {
    if (!formData.email || !formData.password){
      setMsg('Preencha todos os campos');
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return
    }
        
    try {
      const response = await GenPost('/auth/authenticate', formData);
      if (response?.token) {
        localStorage.setItem('token', response.token);
        setMsg(response?.message);
        setTimeout(() => {
          setMsg(null);
          window.location.reload();
        }, 3000);

      } else {
        setMsg(response?.message || 'Erro ao tentar fazer login');
        setTimeout(() => {
          setMsg(null);
        }, 3000);
      }

    } catch (err) {
      setMsg(err?.message || 'Erro ao tentar fazer login');
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="w-full flex h-[80vh] flex-col gap-4 justify-center items-center bg-blue-950 py-10 pt-0">
      <div className="w-full text-left px-4 bg-blue-950">
        <h3 className="archivo-black-regular -mb-0 mt-4 text-3xl lg:text-[24pt] md:text-[20pt]">
          PAINEL E-CARD!
        </h3>
        <h3 className="archivo-black-regular2">
          entre para gerenciar seu catão virtual!
        </h3>
      </div>
      <div className="flex flex-col w-[100%] justify-center items-center">
        <div className="flex flex-col relative max-w-[350px] w-full p-4 bg-gradient-to-bl from-red-500 to-blue-500 gap-2 text-slate-200 rounded-md shadow-lg m-10">
          <h3 className="archivo-black-regular text-[16pt]">Entre em sua conta</h3>
          <hr />
          <input
            className="p-1 rounded-lg custom-input text-slate-600"
            placeholder="seu e-mail"
            name={'email'}
            value={formData.email}
            onChange={handleChange}
          />
          <input
            className="p-1 rounded-lg custom-input text-slate-600"
            placeholder="sua senha"
            name={'password'}
            type={'password'}
            value={formData.password}
            onChange={handleChange}
          />
          <div className="flex w-full min-h-[30px] p-1 justify-center align-middle items-center">
            {loading && !msg && (<CircularProgress size={30}/>)}
            {!loading && msg && (<p>{msg}</p>)}
          </div>
          <div className="flex justify-end w-100%">
            <button className="text-[14px] p-1 bg-blue-500 rounded-lg font-medium hover:bg-blue-600 shadow-md px-4" onClick={handleClick}>
              ENTRAR
            </button>
          </div>
          <br />
          <br />
          <br />
          <div className="flex flex-col gap-1 justify-center items-center w-[100%] bottom-0 left-0 absolute">
            <p className="text-[8pt] m-0 p-0">
              Esqueceu a senha?{" "}
              <a
                className="cursor-pointer underline text-cyan-400 font-semibold hover:text-cyan-500"
                onClick={() => nav("/forgotpass")}
              >
                clique aqui
              </a>
            </p>
            <p className="text-[8pt] ">
              Não tem uma conta?{" "}
              <a
                className="cursor-pointer underline text-cyan-400 font-semibold hover:text-cyan-500"
                onClick={() => nav("/signup")}
              >
                cadastrar
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
