import { React} from "react";
import { Outlet } from "react-router-dom";
import PainelHeader from "Loja/components/PainelHeader";

const PainelLayout = () => {

  return (
    <div className="m-0 p-0 w-[100vw] h-[100vh] overflow-x-scroll no-scrollbar">
      <PainelHeader/>
      <Outlet />
    </div>
          
  );
};

export default PainelLayout;