import React, { useState } from 'react'
import ParamsForm from './ParamsForm'

function Configuracoes() {
  const [refresh, setRefresh] = useState(false)
  return (
    <div className='p-2 text-[10pt]'>
      <h3 className='text-[14pt] m-0'>Painel de Configurações</h3>
      <p className='text-[9pt] -mt-1 py-0 pl-4'>gerencie as suas configurações gerais.</p>
      <hr/>
      <ParamsForm/>
    </div>
  )
}

export default Configuracoes