import React, {useState, useEffect, createContext} from 'react'
import {Navigate, Route, Routes } from "react-router-dom";

import Admin from"./Admin/scenes/admin/Index"
import NewPannel from './Admin/scenes/newPannel/NewPannel';
import UsuariosIndex from 'Admin/scenes/Usuarios/UsuariosIndex';
import NovoUsuario from 'Admin/scenes/Usuarios/NovoUsuario';
import EditarUsuario from 'Admin/scenes/Usuarios/EditarUsuario';
import Assinaturas from 'Admin/scenes/Assinaturas/Assinaturas';
import PainelPlanos from 'Admin/scenes/Assinaturas/PainelPlanos';
import FinanceiroIndex from 'Admin/scenes/Financeiro/FinanceiroIndex';
import PainelTickets from 'Admin/scenes/Tickets/PainelTickets';
import UsabilidadeIndex from 'Admin/scenes/Usabilidade/UsabilidadeIndex';
import CampanhasIndex from 'Admin/scenes/Campanhas/CampanhasIndex';
import Cupoms from 'Admin/scenes/Campanhas/Cupoms';
import CampanhasEmail from 'Admin/scenes/Campanhas/CampanhasEmail';
import CampanhaAvisos from 'Admin/scenes/Campanhas/CampanhaAvisos';
import LeadsIndex from 'Admin/scenes/Leads/LeadsIndex';
import PainelPagSeguro from 'Admin/scenes/Integracoes/PainelPagSeguro';
import Configuracoes from 'Admin/scenes/Configuracoes/Configuracoes';
import EcardsIndex from 'Admin/scenes/Ecards/EcardsIndex';
import EcardMaker from 'Admin/scenes/Ecards/EcardMaker';
import NovoEcard from 'Admin/scenes/Ecards/NovoEcard';
import NovaAssinatura from 'Admin/scenes/Assinaturas/NovaAssinatura';
import NovoLead from 'Admin/scenes/Leads/NovoLead';

function AdminRoot() {

  return (
        <div>
          <Routes>

            <Route element={<NewPannel />}>
              <Route exact path="/" element={<Admin />} />              
              <Route path="/admin" element={<Navigate to="/admin" replace />} />
              
              <Route path="/sales" element={<Navigate to="/admin" replace />} />
              <Route path="/alerts" element={<Navigate to="/admin" replace />} />
              <Route path="/messages" element={<Navigate to="/admin" replace />} />
              <Route path="/subscriptions" element={<Navigate to="/admin" replace />} />
              
              <Route path="/users" element={<Navigate to="/admin" replace />} />
                <Route path="/users/new" element={<Navigate to="/admin" replace />} />
                
              <Route path="/subscribers" element={<Navigate to="/admin" replace />} />            
                <Route path="/subscribers/new" element={<Navigate to="/admin" replace />} />
              
              <Route path="/integrations/mercado_pago" element={<Navigate to="/admin" replace />} />
              <Route path="/integrations/melhor_envio" element={<Navigate to="/admin" replace />} />
              
              <Route path="/usuarios" element={<UsuariosIndex />} />
              <Route path="/usuario/:id" element={<Navigate to="/admin" replace />} />
              <Route path="/usuario/assinaturas" element={<Navigate to="/admin" replace />} />
              <Route path="/usuario/assinatura/:id" element={<Navigate to="/admin" replace />} />
              <Route path="/usuario/financeiro/:id" element={<Navigate to="/admin" replace />} />
              <Route path="/usuario/movimentacao/:id" element={<Navigate to="/admin" replace />} />
              <Route path="/create/usuario" element={<NovoUsuario />} />
              <Route path="/edit/usuario/:id" element={<EditarUsuario />} />

              <Route path="/ecards/dashboard" element={<EcardsIndex/>} />
              <Route path="/ecards/gerenciar/:id" element={<EcardMaker/>} />
              <Route path="/create/ecard" element={<NovoEcard/>} />

              <Route path="/assinaturas" element={<Assinaturas />} />
              <Route path="/assinatura/nova" element={<NovaAssinatura />} />
              
              <Route path="/planos" element={<PainelPlanos />} />

              <Route path="/financeiro" element={<FinanceiroIndex />} />
              <Route path="/financeiro/pagamentos" element={<Navigate to="/admin" replace />} />
              <Route path="/financeiro/pagamento/:id" element={<Navigate to="/admin" replace />} />
              <Route path="/tickets" element={<PainelTickets />} />
              <Route path="/tickets/:id" element={<Navigate to="/admin" replace />} />
              
              <Route path="/usabilidade" element={<UsabilidadeIndex />} />
              
              <Route path="/campanhas" element={<CampanhasIndex />} />
              <Route path="/campanhas/cupoms" element={<Cupoms />} />
              <Route path="/campanhas/emails" element={<CampanhasEmail />} />
              <Route path="/campanhas/avisos" element={<CampanhaAvisos />} />
              
              <Route path="/leads" element={<LeadsIndex />} />
              <Route path="/create/lead" element={<NovoLead />} />

              <Route path="/integracoes/pagseguro" element={<PainelPagSeguro />} />
              <Route path="/configuracoes" element={<Configuracoes />} />

              <Route path="/*" element={<Navigate to="/admin" replace />} />
              <Route path="/subsdetails/:id" element={<Navigate to="/admin" replace />} />
            </Route>
          </Routes>
    </div>
  );
}

export default AdminRoot;