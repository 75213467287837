import React, {useState, useEffect} from 'react'
import BubblesBG from '../BubblesBG';

function Carousel({ children }) {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % children.length);
    };
  
    const handlePrev = () => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + children.length) % children.length);
    };
  
    const handleDotClick = (index) => {
      setCurrentIndex(index);
    };
  
    return (
      <div className="relative w-full overflow-hidden">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {React.Children.map(children, (child, index) => (
            <div className="w-full flex-shrink-0">{child}</div>
          ))}
        </div>
        <div className="absolute inset-y-0 left-0 flex items-center">
          <button onClick={handlePrev} className="bg-black text-white p-2">‹</button>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center">
          <button onClick={handleNext} className="bg-black text-white p-2">›</button>
        </div>
        <div className="absolute bottom-0 left-0 right-0 flex justify-center p-2">
          {children.map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full mx-1 ${index === currentIndex ? 'bg-white' : 'bg-gray-500'}`}
              onClick={() => handleDotClick(index)}
            ></button>
          ))}
        </div>
      </div>
    );
  }

const First = (props) => {
const { title, content } = props.data;
const direction = props.direction;
  return (
    <div className='flex justify-around p-4 relative'>
      <div className='flex w-[48%] items-center justify-center p-2'>
        <h2 className='text-[14pt] font-semibold archivo-black-regular2'>{title}</h2>
      </div>
      <div className='flex w-[9%] justify-center items-center relative'>
        <div className='flex justify-center z-10 items-center w-[40px] h-[40px] p-2 bg-cyan-500  rounded-full'>
          <p className='p-0 m-0 text-red-500 font-extrabold'>A</p>
        </div>
        <div className='absolute h-full w-1 bg-cyan-500  z-0 left-1/2 top-1/2 transform -translate-x-1/2'></div>
      </div>
      <div className='flex w-[48%] justify-center items-center p-2'>
        <div className='flex w-[100%] justify-center items-center p-2 bg-cyan-700 bg-opacity-60 rounded  shadow-lg'>
          <p className='text-[11pt] font-semibold p-2'>{content}</p>
        </div>
      </div>
    </div>
  );
}

const Center = (props) => {
    const direction = props.direction;
    const {title, content} = props.data;
    if (direction === 'right') {
        return (
            <div className='flex justify-around p-4 relative -mt-20'>
                <div className='flex w-[48%] justify-center items-center p-2'>
                    <div className='flex w-[100%] justify-center items-center p-2 bg-cyan-700 bg-opacity-60 rounded  shadow-lg'>
                        <p className='text-[11pt] font-semibold p-2'>{content}</p>
                    </div>
                </div>
                <div className='flex w-[9%] justify-center items-center relative'>
                    <div className='flex justify-center z-10 items-center w-[40px] h-[40px] p-2 bg-cyan-500  rounded-full'>
                        <p className='p-0 m-0 text-red-500 font-extrabold'>A</p>
                    </div>
                    <div className='absolute h-full w-1 bg-cyan-500  z-0 left-1/2 transform -translate-x-1/2'></div>
                </div>
                <div className='flex w-[48%] items-center justify-center p-2'>
                    <h2 className='text-[14pt] font-semibold archivo-black-regular2'>{title}</h2>
                </div>
            </div>
        );
    } else {

        return (
            <div className='flex justify-around p-4 relative -mt-20'>
                <div className='flex w-[48%] items-center justify-center p-2'>
                    <h2 className='text-[14pt] font-semibold archivo-black-regular2'>{title}</h2>
                </div>
                <div className='flex w-[9%] justify-center items-center relative'>
                    <div className='flex justify-center z-10 items-center w-[40px] h-[40px] p-2 bg-cyan-500  rounded-full'>
                        <p className='p-0 m-0 text-red-500 font-extrabold'>A</p>
                    </div>
                    <div className='absolute h-full w-1 bg-cyan-500  z-0 left-1/2 transform -translate-x-1/2'></div>
                </div>
                <div className='flex w-[48%] justify-center items-center p-2'>
                    <div className='flex w-[100%] justify-center items-center p-2 bg-cyan-700 bg-opacity-60 rounded  shadow-lg'>
                        <p className='text-[11pt] font-semibold p-2'>{content}</p>
                    </div>
                </div>
            </div>
        );
    }
}
  
  const EndBlock = (props) => {
    const direction = props.direction;
    const {title, content} = props.data
    if (direction === 'right') {
        return (
            <div className='flex justify-around p-4 relative -mt-20'>
                <div className='flex w-[48%] justify-center items-center p-2'>
                    <div className='flex w-[100%] justify-center items-center p-2 bg-cyan-700 bg-opacity-60 rounded  shadow-lg'>
                        <p className='text-[11pt] font-semibold p-2 '>{content}</p>
                    </div>
                </div>
                <div className='flex w-[9%] justify-center items-center relative'>
                    <div className='flex justify-center z-10 items-center w-[40px] h-[40px] p-2 bg-cyan-500  rounded-full'>
                        <p className='p-0 m-0 text-red-500 font-extrabold'>A</p>
                    </div>
                    <div className='absolute h-full w-1 bg-cyan-500  z-0 left-1/2 bottom-1/2 transform -translate-x-1/2'></div>
                </div>
                <div className='flex w-[48%] items-center justify-center p-2'>
                    <h2 className='text-[14pt] font-semibold archivo-black-regular2'>{title}</h2>
                </div>
            </div>
        );
    } else {
        return (
            <div className='flex justify-around p-4 relative -mt-20'>
                <div className='flex w-[48%] items-center justify-center p-2 '>
                    <h2 className='text-[14pt] font-semibold archivo-black-regular2'>{title}</h2>
                </div>
                <div className='flex w-[9%] justify-center items-center relative'>
                    <div className='flex justify-center z-10 items-center w-[40px] h-[40px] p-2 bg-cyan-500  rounded-full'>
                        <p className='p-0 m-0 text-slate-red-500 font-extrabold'>A</p>
                    </div>
                    <div className='absolute h-full w-1 bg-cyan-500  z-0 left-1/2 bottom-1/2 transform -translate-x-1/2'></div>
                </div>
                <div className='flex w-[48%] justify-center items-center p-2'>
                    <div className='flex w-[100%] justify-center items-center p-2 bg-cyan-700 bg-opacity-60 rounded  shadow-lg'>
                        <p className='text-[11pt] font-semibold p-2'>{content}</p>
                    </div>
                </div>
            </div>
        );
    }
}
  
function PipeLineObj(props) {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 600);
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  return (
    <div className='flex relative flex-col w-full h-full'>
    <div className='flex flex-col w-full h-full superior-layer no-scrollbar'>
    {isSmallScreen ? (
        <Carousel>
          <div className="flex flex-col gap-4 w-full p-10">
            <div className="w-full shadow-md border-1 border-gray-400 border-opacity-80 flex p-12 bg-gray-200 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">
              <div className='flex flex-col'>
                <h3 className="archivo-black-titles text-[18pt]">Todas suas redes sociais</h3>
                <hr/>
                <p className="px-2 text-slate-100">
                Tudo em um só lugar, aumente seu engajamento e crie uma corrente em suas redes sociais!
                </p>
              </div>
            </div>


          </div>
          <div className="flex flex-col gap-4 w-full p-10">
          <div className="w-full shadow-md border-1 border-gray-400 border-opacity-80 flex p-12 bg-gray-200 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">
              <div className='flex flex-col'>
                <h3 className="archivo-black-titles text-[18pt]">Apresentação Profissional</h3>
                <hr/>
                <p className="px-2 text-slate-100">
                De um ar profissional em sua apresentação, com sua identidade criando seu e-card personalizado!
                </p>
              </div>
            </div>

          </div>
          <div className="flex flex-col gap-4 w-full p-10">
          <div className="w-full shadow-md border-1 border-gray-400 border-opacity-80 flex p-12 bg-gray-400 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">
              <div className='flex flex-col'>
                <h3 className="archivo-black-titles text-[18pt]">Saiba a quantidade de acessos!</h3>
                <hr/>
                <p className="px-2 text-slate-100">
                Com nosso painel, saiba a movimentação de cada um de seus links, assim você mensura o cresimento do seu tráfego de visitas!
                </p>
              </div>
            </div>

          </div>
          <div className="flex flex-col gap-4 w-full p-10">
            <div className="w-full shadow-md border-1 border-gray-400 border-opacity-80 flex p-12 bg-gray-400 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">
              <div className='flex flex-col'>
                <h3 className="archivo-black-titles text-[18pt]">Fique atualizado(a)</h3>
                <hr/>
                <p className="px-2 text-slate-100">
                Ter um canal profissional que centraliza seus ativos digitais, passa mais credibilidade e confiança. Valorizando seu posicionamento profissional!
                </p>
              </div>
            </div>
          </div>
        </Carousel>
      ) : (
        <div className="flex flex-col gap-4">
          <Carousel>
            <div className="flex flex-row gap-4 w-full p-10">
            <div className="w-full shadow-md border-1 border-gray-400 border-opacity-80 flex p-12 bg-gray-400 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">
                <div className='flex flex-col'>
                  <h3 className="archivo-black-titles text-[18pt]">Todas suas redes sociais</h3>
                  <hr/>
                  <p className="px-2 text-slate-100">
                  Tudo em um só lugar, aumente seu engajamento e crie uma corrente em suas redes sociais!
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full p-10">
            <div className="w-full shadow-md border-1 border-gray-400 border-opacity-80 flex p-12 bg-gray-400 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">
                <div className='flex flex-col'>
                  <h3 className="archivo-black-titles text-[18pt]">Apresentação Profissional</h3>
                  <hr/>
                  <p className="px-2 text-slate-100">
                  De um ar profissional em sua apresentação, com sua identidade criando seu e-card personalizado!
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full p-10">             
            <div className="w-full shadow-md border-1 border-gray-400 border-opacity-80 flex p-12 bg-gray-400 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">              
                <div className='flex flex-col'>
                  <h3 className="archivo-black-titles text-[18pt]">Saiba a quantidade de acessos!</h3>
                  <hr/>
                  <p className="px-2 text-slate-100">
                  Com nosso painel, saiba a movimentação de cada um de seus links, assim você mensura o cresimento do seu tráfego de visitas!
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full p-10">             
            <div className="w-full shadow-md border-1 border-gray-400 border-opacity-80 flex p-12 bg-gray-400 bg-opacity-20 rounded-md h-[350px] justify-center items-center text-center backdrop-blur-lg backdrop-filter">              
                <div className='flex flex-col'>
                  <h3 className="archivo-black-titles text-[18pt]">Fique atualizado(a)</h3>
                  <hr/>
                  <p className="px-2 text-slate-100">
                  Ter um canal profissional que centraliza seus ativos digitais, passa mais credibilidade e confiança. Valorizando seu posicionamento profissional!
                  </p>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      )}        
    </div>
    <BubblesBG />
    </div>
  )
}

export default PipeLineObj