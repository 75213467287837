import React from 'react'
import AlwaysLogo from '../assets/minilogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from "react-router-dom";

function Footer() {
  const handleAlwaysClick = () => {
    navigate('/');
  };

  const handleInstagramClick = () => {
    window.location.href = 'https://www.instagram.com/always_online_br/';
  };

  const handleWhatsappClick = () => {
    window.location.href = 'https://api.whatsapp.com/send?phone=5544984133876';
  };

  const handleFacebookClick = () => {
    window.location.href ='https://www.facebook.com/always.online.br';
  };

  const navigate = useNavigate();
  return (
    <div className='bg-slate-950 p-4 text-slate-200 flex flex-row justify-around'>
        <div className='flex flex-col items-center'><img onClick={handleAlwaysClick} src={AlwaysLogo} className="cursor-pointer"/></div>
        <div className='flex flex-row items-center gap-2'>
          <FontAwesomeIcon onClick={handleInstagramClick} icon={faInstagram} size='lg' className='bg-red-600 p-1 rounded-full cursor-pointer'/>
          <FontAwesomeIcon onClick={handleWhatsappClick} icon={faWhatsapp} size='lg' className='bg-green-600 p-1 rounded-full cursor-pointer'/>
          <FontAwesomeIcon onClick={handleFacebookClick} icon={faFacebook} size='lg' className='bg-blue-600 p-1 rounded-full cursor-pointer'/>          
          </div>
    </div>
  )
}

export default Footer