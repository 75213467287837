import React, { useState } from 'react';
import MiniLogo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import useLogout from 'hooks/useLogout';
import { Logout } from '@mui/icons-material';

function PainelHeader() {
    const logout = useLogout();
    const [isOpen, setIsOpen] = useState(false);
    const nav = useNavigate();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='sticky top-0 z-10'>
            <div className='flex flex-row bg-gray-200 justify-between text-blue-900 gap-2 p-2'>
                <div>
                    <img src={MiniLogo} alt="React Logo" className='w-[140px] p-2 cursor-pointer' onClick={() => nav('/')} />
                </div>
                <div className='block'>
                    <button onClick={toggleMenu} className='p-2'>
                        {isOpen ? <CloseIcon size={24} /> : <MenuIcon size={24} />}
                    </button>
                </div>
            </div>
            {/* Drawer */}
            <div className={`fixed top-0 left-0 h-full flex flex-col justify-between bg-gray-200 text-blue-900 z-20 transition-transform transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                <div className='flex flex-col p-4 space-y-4'>
                    <img src={MiniLogo} alt="React Logo" className='w-[140px] p-2 cursor-pointer mb-4' onClick={() => { nav('/'); setIsOpen(false); }} />
                    <p className='font-semibold hover:underline hover:text-blue-700 shadow-sm hover:bg-gray-100 rounded-md cursor-pointer py-2 px-4 m-0' onClick={() => { nav('/userpannel'); setIsOpen(false); }}>Painel</p>
                    <p className='font-semibold hover:underline hover:text-blue-700 shadow-sm hover:bg-gray-100 rounded-md cursor-pointer py-2 px-4 m-0' onClick={() => { nav('/ecard-make'); setIsOpen(false); }}>Ecard</p>                                   
                </div>
                <div className='flex flex-col w-full p-4 space-y-4'>
                    <hr className='bg-gray-400 border-[1px] w-full'/>
                    <div className='flex flex-row justify-between py-2 p-1 shadow-sm hover:bg-gray-100 rounded-md cursor-pointer items-center align-middle' onClick={logout}> 
                        <p className='font-semibold hover:underline hover:text-blue-700 cursor-pointer p-0 m-0' >Sair </p> 
                        <Logout />                                   
                    </div>
                   
                </div>
            </div>
            {/* Overlay */}
            {isOpen && <div className='fixed top-0 left-0 w-full h-full bg-black opacity-50 z-10 lg:hidden' onClick={toggleMenu}></div>}
        </div>
    );
}

export default PainelHeader;