import { React} from "react";
import { Outlet } from "react-router-dom";
import { Box} from "@mui/material";
import Header from "Loja/components/Header";
import Footer from "Loja/components/Footer";

const Layout = () => {

  return (
    <div className="m-0 p-0 w-[100vvw] overflow-hidden">
      <Header/>
      <Outlet />
      <Footer />
    </div>
          
  );
};

export default Layout;