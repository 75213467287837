
import React, {useState, useEffect} from 'react'
import EcardCarrocel from 'Loja/components/EcardCarrocel';
import { useNavigate } from 'react-router-dom';
import PipeLineObj from 'Loja/components/compLoja/PipeLineObj';




function EcardIndex() {

  const nav = useNavigate();
  return (
    <div className='flex flex-col gap-2 p-1 w-full bg-blue-950'>
      <div className='flex flex-col lg:flex-row justify-between w-full'>
        <div className='flex flex-col justify-center align-middle w-full lg:w-1/2'>
          <div className='p-10 flex-col gap-2 text-slate-200 bg-blue-950'>
            <div className='text-right flex flex-col w-full lg:w-2/5 m-2 items-start'>
              <h3 className='archivo-black-regular -mb-0 text-4xl lg:text-3xl md:text-2xl'>Ecards,</h3>
              <h3 className='archivo-black-regular2'> Personalizados</h3>
              <button 
                className='flex justify-center align-middle bg-green-500 text-slate-200 w-[220px] font-extrabold p-2 rounded-lg shadow-md cursor-pointer hover:bg-green-400'
                onClick={()=>nav('/signup')}>
                  CADASTRE JÁ
              </button>
            </div>
            <div className='font-semibold w-full p-2 text-[11pt]'>    
              <p className='text-[11pt] font-semibold p-2'>Você está pronto para elevar a sua presença online a novos patamares? <br/><br/>Apresentamos os <span className='font-extrabold text-red-500 text-[14pt]'>E-Cards </span>Personalizados, a ferramenta essencial para destacar o seu negócio de forma única e memorável. Um <span className='font-extrabold text-yellow-500 text-[14pt]'>E-Card</span> feito sob medida é uma das chaves para se destacar no mundo digital.</p>
            </div>
          </div>        
        </div>
        <div className='flex w-full lg:w-1/2 justify-center align-middle items-center'>
          <div className='flex w-full min-h-[400px] max-w-[500px] relative p-2 justify-center align-middle'>
            <EcardCarrocel/>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <h2 className='archivo-black-regular5 font-semibold p-2'>SAIBA AS VANTAGENS!</h2>

        <PipeLineObj />
      </div>
      <div className='p-10 flex flex-col lg:flex-row gap-2 text-slate-200 bg-blue-950'>
        <div className='lg:w-2/5 m-2 flex flex-col justify-center items-start lg:text-right'>
          <h3 className='archivo-black-regular -mb-0 text-3xl lg:text-[24pt] md:text-[20pt]'>VAMOS JUNTOS,</h3>
          <h3 className='archivo-black-regular2'> Transformar sua Presença Online?!</h3>
          <div className='bg-green-500 text-slate-200 font-extrabold p-3 rounded-xl shadow-md cursor-pointer hover:bg-green-400'> CADASTRE AGORA</div>
        </div>
        <div className='w-full lg:w-3/5 p-4'>
          <p className='font-semibold text-base lg:text-lg xl:text-xl'>
            <span className='font-extrabold text-red-500 text-xl lg:text-2xl'>Não se contente com o comum.</span><br/>
            Invista em E-Card personalizado e destaque-se na multidão digital.<br/><br/>
            Sua apresentação online nunca foi tão importante, e um E-Card é a chave para garantir que sua mensagem seja ouvida.<br/>
            <span className='text-yellow-400 text-xl lg:text-2xl'> Faça a diferença</span>, conquiste sua audiência e defina um novo padrão de excelência com nossos E-Cards feitos sob medida para você.
            <br/><br/>
            <span className='text-yellow-400 text-xl lg:text-2xl'>Transforme sua presença online hoje!</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default EcardIndex