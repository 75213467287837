import React, { useState } from 'react';
import MiniLogo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Menu, MenuItem } from '@mui/material';

function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const nav = useNavigate();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className='sticky top-0 z-10'>
            <div className='flex flex-row bg-gray-200 justify-between text-blue-900 gap-2 p-2'>
                <div>
                    <img src={MiniLogo} alt="React Logo" className='w-[140px] p-2 cursor-pointer' onClick={() => nav('/')} />
                </div>
                <div className='flex flex-row gap-1'>
                    <div className='block lg:hidden'>
                        <button onClick={toggleMenu} className='p-2'>
                            {isOpen ? <CloseIcon size={24} /> : <MenuIcon size={24} />}
                        </button>
                    </div>
                    <div className='block lg:hidden'>
                        <button onClick={handleMenuOpen} className='p-2'>
                            <AccountCircleIcon size={24} />
                        </button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => { nav('/meu-painel'); handleMenuClose(); }}>Meu Painel</MenuItem>
                        </Menu>
                    </div>
                </div>

                <div className='hidden lg:flex flex-row flex-grow justify-center gap-4 p-2 mt-1 rounded-l-md rounded-b-md border-l border-b border-blue-500 border-opacity-30'>
                    <p className='p-0 m-0 font-semibold hover:underline hover:text-blue-700 cursor-pointer' onClick={() => nav('/')}>Inicio</p>
                    <p className='p-0 m-0 font-semibold hover:underline hover:text-blue-700 cursor-pointer' onClick={() => nav('/sites')}>Sites</p>
                    <p className='p-0 m-0 font-semibold hover:underline hover:text-blue-700 cursor-pointer' onClick={() => nav('/lojas')}>Lojas</p>
                    <p className='p-0 m-0 font-semibold hover:underline hover:text-blue-700 cursor-pointer' onClick={() => nav('/apps')}>Aplicativos</p>
                    <p className='p-0 m-0 font-semibold hover:underline hover:text-blue-700 cursor-pointer' onClick={() => nav('/ecard')}>Ecards</p>
                </div>
                <div className='hidden lg:block'>
                    <button onClick={handleMenuOpen} className='p-2'>
                        <AccountCircleIcon size={24} />
                    </button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => { nav('/userpannel'); handleMenuClose(); }}><p className='p-2 h-[30px]'>Meu Painel</p></MenuItem>
                    </Menu>
                </div>
            </div>
            {/* Drawer */}
            <div className={`fixed top-0 left-0 h-full bg-gray-200 text-blue-900 z-20 transition-transform transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} lg:hidden`}>
                <div className='flex flex-col p-4 space-y-4'>
                    <img src={MiniLogo} alt="React Logo" className='w-[140px] p-2 cursor-pointer' onClick={() => { nav('/'); setIsOpen(false); }} />
                    <p className='font-semibold hover:underline hover:text-blue-700 cursor-pointer' onClick={() => { nav('/'); setIsOpen(false); }}>Inicio</p>
                    <p className='font-semibold hover:underline hover:text-blue-700 cursor-pointer' onClick={() => { nav('/sites'); setIsOpen(false); }}>Sites</p>
                    <p className='font-semibold hover:underline hover:text-blue-700 cursor-pointer' onClick={() => { nav('/lojas'); setIsOpen(false); }}>Lojas</p>
                    <p className='font-semibold hover:underline hover:text-blue-700 cursor-pointer' onClick={() => { nav('/apps'); setIsOpen(false); }}>Aplicativos</p>
                    <p className='font-semibold hover:underline hover:text-blue-700 cursor-pointer' onClick={() => { nav('/ecard'); setIsOpen(false); }}>Ecards</p>
                </div>
            </div>
            {/* Overlay */}
            {isOpen && <div className='fixed top-0 left-0 w-full h-full bg-black opacity-50 z-10 lg:hidden' onClick={toggleMenu}></div>}
        </div>
    );
}

export default Header;
