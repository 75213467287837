import React, { useState, useEffect, useContext } from 'react';
import { initMercadoPago, Payment, StatusScreen  } from '@mercadopago/sdk-react';
import { GenPost } from 'state/api';

const PUBLIC_KEY = 'APP_USR-68727607-712c-44c9-a7f0-89b507ce2c67';
initMercadoPago(PUBLIC_KEY, {locale: 'pt-BR'});

export default function MercadoPago(props) {
  const valor = props.valor;
  const [isLoading, setIsLoading] = useState(true);
  const [ordem_mp, setordem_mp] = useState(null);
  const [StatusInit, setStatusInit] = useState(null);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);
  
  useEffect(() => {
      if(ordem_mp){
        setStatusInit({
          paymentId: ordem_mp,
          customization: {
            visual: {
              style: {
                theme: "dark",
              }
            }
          }      
        });
      };
  }, [ordem_mp]);
  
  const initialization = {
    amount: valor,
    customization: {
      visual: {
        style: {
          theme: "dark",
        }
      }
    }
  }
  
 
  const customization = {
    visual: {
      style: {
        theme: "dark",
      }
    },
    paymentMethods: {
      bankTransfer: 'all',
      creditCard: 'all',
      debitCard: 'all',
    },
    backUrls: {
      return: '<https://alwaysonline.com.br/userpannel>'
    }
  };


  const onSubmit = async ({ selectedPaymentMethod, formData }) => {
  
    let payload = {
      plano_id:props.id,
      mp_request:formData
    }

    try {

      const response = await GenPost(`/checkout`, payload);
      setordem_mp(response.mercado_pago.original.id);
      return response;

    } catch (error) {
      throw error; 
    }
  };

  const onError = async (error) => {

  };

  const onReady = async () => {
  setIsLoading(false)
  };


if (isLoading)
  return (<>Carregando..</>)
  
  else 
  
  return (<>
          {!StatusInit ? (
            <Payment
            initialization={initialization}
            customization={customization}
            onSubmit={onSubmit}
            onReady={onReady}
            onError={onError}
          />
        ) : null}

        {StatusInit ? (
          <StatusScreen
            initialization={StatusInit}
            onReady={onReady}
            onError={onError}
          />
        ) : null}
  </>
  )

}


{/* 
  {
      "user_res": {
          "user": {
              "name": "BrunoBuzzeto",
              "email": "brunobuzzeto.dev@gmail.com",
              "role": "Usr",
              "telefone": "44980836070",
              "verify_token": "JNrJRqxjIioniWsqC8RL9YCBf",
              "updated_at": "2024-07-20T02:42:48.000000Z",
              "created_at": "2024-07-20T02:42:48.000000Z",
              "id": 25
          },
          "token": "33|EFqkGDHmOdPPKbmB9Oxw4Ebzb40tX7CKlY279sBx",
          "aviso": "Aviso cadastrado com sucesso.",
          "verify_token": "JNrJRqxjIioniWsqC8RL9YCBf"
      },
      "mercado_pago": {
          "headers": {},
          "original": {
              "id": 1318952012,
              "message": "Pagamento realizado com sucesso!"
          },
          "exception": null
      }
  }

*/}