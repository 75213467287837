import React, { useEffect, useState } from 'react';
import { GenGet } from 'state/api';
import { CircularProgress } from '@mui/material';
import CustomDataGrid from 'Admin/components/AdminComponents/CustomDataGrid';

function EcardsIndex() {
    const [loading, setLoading] = useState(true);
    const [ativos, setAtivos] = useState(null);
    const [inativos, setInativos] = useState(null);
    const [metricas, setMetricas] = useState(null);
    async function getEcards() {
        try {
          const response = await GenGet(`/ecards/dashboard`);
          console.log(response)
          setAtivos(response.ecards.ativos);
          setInativos(response.ecards.inativos);
          setMetricas(response.metricas);
          setLoading(false);
        } catch (err) {
          console.error("Error fetching data:", err);
        }
      }
    
      useEffect(() => {
        getEcards();
      }, []);
    const cols = ['id','Titulo','ID de Usuário','Usuário'];

  return (
    <>
    {!loading ? (
        <div>
          <h3 className='text-[14pt]'>Painel de Ecards</h3>
          <p className='text-[9pt] -mt-2'>visualize os ecards cadastrados.</p>
          <div className='flex flex-row w-full flex-wrap justify-between'>
            <div className='p-2 rounded-md border-[1px]'>
            <p>Cadastrados: {metricas.cadastrados}</p>
            </div>

            <div className='p-2 rounded-md border-[1px]'>
            <p>Ativos: {metricas.ativos}</p>
            </div>

            <div className='p-2 rounded-md border-[1px]'>
            <p>Inativos: {metricas.inativos}</p>
            </div>
          </div>
          {ativos && <CustomDataGrid rows={ativos} columns={cols} per_page={25} title={'Assinaturas ativas'} />}
          {inativos && <CustomDataGrid rows={inativos} columns={cols} per_page={25} title={'Assinaturas inativas'} />}
        </div>
      ) : (
        <div className="rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll">
          <div className="w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center">
            <div className="flex flex-col items-center">
              <CircularProgress />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default EcardsIndex
