import React, { useState } from 'react';
import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/Always_logo.png'

const raiz = [
    {
        'title':'Dashboard',
        'endpoint':'/admin/dashboard',
        'hasdrop':false,
       
    },
    {
        'title':'Assinaturas',
        'hasdrop':true,
        'dropData':[
            {
                'title':'Painel de Assinaturas',
                'endpoint':'/admin/assinaturas',
            },
            {
                'title':'Painel de Planos',
                'endpoint':'/admin/planos'
            },
            {
                'title':'Nova Assinatura',
                'endpoint':'/admin/assinatura/nova'
            }
            
        ]
    },
    {
        'title':'Usuários',
        'hasdrop':true,
        'dropData':[
            {
                'title':'Usuários',
                'endpoint':'/admin/usuarios',
            },
            {
                'title':'Novo Usuário',
                'endpoint':'/admin/create/usuario'
            }
            
        ]
    },
    {
      'title':'Ecards',
      'hasdrop':true,
      'dropData':[
          {
              'title':'Ecards',
              'endpoint':'/admin/ecards/dashboard',
          },
          {
              'title':'Ecard Admin',
              'endpoint':'/admin/create/ecard'
          }
          
      ]
    },
    {
      'title':'SalesForce',
      'hasdrop':true,
      'dropData':[
          {
              'title':'Leads',
              'endpoint':'/admin/leads',
          },
          {
              'title':'Cadastrar Lead',
              'endpoint':'/admin/create/lead'
          },
          {
            'title':'Cupoms de desconto',
            'endpoint':'/admin/campanhas/cupoms',
          },
          {
            'title':'Envio de Avisos',
            'endpoint':'/admin/campanhas/avisos'
          },
          {
              'title':'Campanhas de Email',
              'endpoint':'/admin/campanhas/emails'
          }
          
      ]
    },
    {
        'title':'Integrações',
        'hasdrop':true,
        'dropData':[
            {
                'title':'PagSeguro',
                'endpoint':'/admin/integracoes/pagSeguro',
            },
            {
              'title':'MercadoPago',
              'endpoint':'/admin/integracoes/mercadoPago',
          }          
        ]
    },
    {
      'title':'Financeiro',
      'endpoint':'/admin/financeiro',
      'hasdrop':false,
    },
    {
      'title':'Tickets',
      'endpoint':'/admin/tickets',
      'hasdrop':false,
    },
    {
        'title':'Configurações',
        'endpoint':'/admin/configuracoes',
        'hasdrop':false,
    }
]

function NavigateItem({ data }) {
  const nav = useNavigate();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleSubMenu = () => {
      setIsSubMenuOpen(!isSubMenuOpen);
  };

  if (!data.hasdrop) {
      return (
          <li className="cursor-pointer">
              <p onClick={() => nav(data.endpoint)} className="flex items-center px-1 -mx-4 text-gray-900 rounded-lg dark:text-slate-100 hover:bg-gray-100 dark:hover:text-slate-700 group">
                  <span className="ms-3">{data.title}</span>
              </p>
          </li>
      );
  } else {
      return (
          <li>
              <button
                  type="button"
                  className="flex items-center w-full p-2 px-1 -mx-4 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-slate-100 dark:hover:text-slate-700 dark:hover:bg-gray-100"
                  onClick={toggleSubMenu}
              >
                  <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">{data.title}</span>
                  <svg className={`w-3 h-3 transform transition-transform ${isSubMenuOpen ? '-rotate-180' : 'rotate-0'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                  </svg>
              </button>
              <ul className={`${isSubMenuOpen ? 'block' : 'hidden'} py-1 transition duration-1000 ease-in-out`}>
                  {data.dropData.map((item, index) => (
                      <li key={index} className="cursor-pointer transition duration-300 ease-in-out" >
                          <p onClick={() => nav(item.endpoint)} className="flex items-center w-full text-gray-900 transition duration-2 rounded-lg px-0 -mx-10 group hover:bg-gray-100 dark:text-slate-100 dark:hover:text-slate-700 dark:hover:bg-gray-100">
                              <span className="ms-3 text-[10pt]">{item.title}</span>
                          </p>
                      </li>
                  ))}
              </ul>
          </li>
      );
  }
}
  
  function NewPannel() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
  
    const closeSidebar = () => {
      setIsSidebarOpen(false);
    };
  
    return (
      <div>
        {!isSidebarOpen ? (
          <button
            onClick={toggleSidebar}
            data-drawer-target="sidebar-multi-level-sidebar"
            data-drawer-toggle="sidebar-multi-level-sidebar"
            aria-controls="sidebar-multi-level-sidebar"
            type="button"
            className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-red-300 dark:focus:ring-gray-600"
          >
            <span className="sr-only">Open sidebar</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
              ></path>
            </svg>
          </button>
        ) : null}
  
        <aside
          id="sidebar-multi-level-sidebar"
          className={`fixed top-0 left-0 z-40 w-52 h-screen transition-transform ${
            isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          } sm:translate-x-0`}
          aria-label="Sidebar"
          onBlur={closeSidebar}
          tabIndex={0}
        >
          <div className="h-full py-1 no-scrollbar overflow-y-auto bg-gray-50 dark:bg-slate-800">
            <img src={Logo} alt='Logo' className='w-[180px] pl-4 pt-2 mb-2'/>
            <ul className="font-medium">
              {raiz.map((item, index) => (
                <NavigateItem key={index} data={item} />
              ))}
            </ul>
          </div>
        </aside>
  
        <div
          className={`pt-4 pr-4 sm:ml-64 ${
            isSidebarOpen ? 'backdrop-blur-sm' : ''
          }`}
          onClick={closeSidebar}
        >
          <div className="border-2 border-gray-200 rounded-lg dark:border-slate-300 h-[95vh] overflow-scroll no-scrollbar">
            <Outlet />
          </div>
        </div>
      </div>
    );
  }
  
  export default NewPannel;