import React, { useState, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { GenGet } from 'state/api';
import { CircularProgress } from '@mui/material';

const UserMetrics = () => {
  const [totalAccesses, setTotalAccesses] = useState(0);
  const [todayAccesses, setTodayAccesses] = useState(0);
  const [weekAccesses, setWeekAccesses] = useState(0);
  const [monthAccesses, setMonthAccesses] = useState(0);
  const [yearAccesses, setYearAccesses] = useState(0);
  const [monthClicks, setMonthClicks] = useState(0);
  const [todayClicks, setTodayClicks] = useState(0);
  const [weekClicks, setWeekClicks] = useState(0);
  const [yearClicks, setYearClicks] = useState(0);
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(true);

  const [accessData, setAccessData] = useState([
  ]);

  const [clickData, setClickData] = useState([
  ]);

  useEffect(()=>{
    async function getData() {
      try {
        const response = await GenGet('/myecard/metrics');
        setTotalAccesses(response.totalAccesses);
        setTodayAccesses(response.totalAccessesToday);
        setWeekAccesses(response.totalAccessesWeek);
        setMonthAccesses(response.totalAccessesMonth);
        setYearAccesses(response.totalAccessesYear);
        setMonthClicks(response.totalClicksMonth);
        setTodayClicks(response.totalClicksToday);
        setWeekClicks(response.totalClicksWeek);
        setYearClicks(response.totalClicksYear);
        setAccessData(response.accessLastFiveMonths);
        setClickData(response.clicksLastFiveMonths);
        setLinks(response.links)
        setLoading(false);
      } catch (err){
        //
      }
    }
    getData();
  },[]);


  const accessSeries = [
    {
      id: 'Acessos',
      data: accessData,
    },
  ];

  const clickSeries = [
    {
      id: 'Clicks',
      data: clickData,
    },
  ];

  return (
    <div className="p-6 bg-slate-100 min-h-screen text-[#256a9a]">
      {loading ? (<div className='h-[200px] flex justify-center align-middle items-center'> <CircularProgress color="info" sx={{marginTop:'150px'}} /></div>) : (
        <>
      <h1 className="text-3xl font-bold mb-6 ">Painel de métricas</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
        <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
          <p className="text-xl font-semibold">Acessos no total</p>
          <p className="text-3xl">{totalAccesses}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
          <p className="text-xl font-semibold">Hoje</p>
          <p className="text-3xl">{todayAccesses}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
          <p className="text-xl font-semibold">Essa semana</p>
          <p className="text-3xl">{weekAccesses}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
          <p className="text-xl font-semibold">Esse mês</p>
          <p className="text-3xl">{monthAccesses}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
          <p className="text-xl font-semibold">Esse ano</p>
          <p className="text-3xl">{yearAccesses}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
          <p className="text-xl font-semibold">Clicks hoje</p>
          <p className="text-3xl">{todayClicks}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
          <p className="text-xl font-semibold">Clicks na semana</p>
          <p className="text-3xl">{weekClicks}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
          <p className="text-xl font-semibold">Clicks no mês</p>
          <p className="text-3xl">{monthClicks}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md border-[1px] border-[#256a9a]">
          <p className="text-xl font-semibold">Clicks no ano</p>
          <p className="text-3xl">{yearClicks}</p>
        </div>
      </div>
      <div className="bg-white p-1 md:p-6 rounded-lg shadow-md mb-6 border-[1px] border-[#256a9a]">
      <h2 className="text-2xl font-bold mb-4">Interações com links</h2>
      <div className='w-full flex flex-col gap-1'>
      {
        links.filter(item => item.active === 1).map(item => (
          <div className='flex flex-col p-2 border-1' key={item.id}>
            <p className='p-0 m-0'>Título: <b>{item.name}</b></p>
            <p className='p-0 m-0'>Cliques: <b>{item.clicks}</b></p>
          </div>
        ))
      }
      </div>

      </div>
      <div className="bg-white p-1 md:p-6 rounded-lg shadow-md mb-6 border-[1px] border-[#256a9a]">
        <h2 className="text-2xl font-bold mb-4">Crescimento de acesso</h2>
        <div className="h-80">
          <ResponsiveLine
            data={accessSeries}
            margin={{ top: 10, right: 40, bottom: 50, left: 45 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Período',
              legendOffset: 36,
              legendPosition: 'middle'
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 2,
              tickPadding: 2,
              tickRotation: 0,
              legend: 'Clicks',
              legendOffset: -40,
              legendPosition: 'middle'
            }}
            colors={{ scheme: 'nivo' }}
            pointSize={3}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
          />
        </div>
      </div>
      <div className="bg-white p-1 md:p-6 rounded-lg shadow-md border-[1px] border-[#256a9a]">
        <h2 className="text-2xl font-bold mb-4">Crescimento de cliques</h2>
        <div className="h-80">
          <ResponsiveLine
            data={clickSeries}
            margin={{ top: 10, right: 40, bottom: 50, left: 45 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Período',
              legendOffset: 36,
              legendPosition: 'middle'
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 2,
              tickPadding: 2,
              tickRotation: 0,
              legend: 'Clicks',
              legendOffset: -40,
              legendPosition: 'middle'
            }}
            colors={{ scheme: 'nivo' }}
            pointSize={3}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
          />
        </div>
      </div>        
        </>
      )}

    </div>
  );
};

export default UserMetrics;
