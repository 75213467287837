import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import LogoFull from "../../assets/LogoFull.png";
import BG from "../../assets/index-assets/bggradient.png";

function Apresentacao() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleWhatsappClick = () => {
    window.location.href = "https://api.whatsapp.com/send?phone=554499433101";
  };
  return (
    <div>
      <div>
        {isSmallScreen && (
          <Box
            className="container-flex flex-row text-slate-700"
            boxSizing="border-box"
            flexDirection={"column"}
            padding="10px"
          >
            <div className="p-4 bg-slate-200 bg-opacity-80 rounded-lg">
              <h1 className="titulo-apresentacao font-extrabold archivo-black-regular2 -mb-0 text-3xl">
                {" "}
                SEU NEGÓCIO, <br />
                ONLINE!
              </h1>
              <h2 className="subtitulo-apresentacao p-2">
                {" "}
                Você já percebeu que não da para ficar fora da internet
                atualmente! <br />
                Seja você quem for, o primeiro passo para o sucesso online
                começa com um site impressionante.
              </h2>
              <br />
              <div className="flex flex-row gap-2">
                <div
                  onClick={handleWhatsappClick}
                  className="cursor-pointer p-4 h-[40px] rounded-md border-blue-600 border-2 flex items-center justify-center hover:bg-blue-200"
                >
                  <p className="font-bold m-0 p-0 text-blue-600">
                    QUERO ESTAR ONLINE!
                  </p>
                </div>
              </div>
            </div>
          </Box>
        )}
        {/* O restante do seu código */}
      </div>

      <div
        className="container-flex flex-row text-slate-700 sm:flex hidden xs:flex-col p-[80px]"
        style={{ backgroundImage: `url(${BG})` }}
      >
        <div className="p-4 bg-slate-200 bg-opacity-80 rounded-lg">
          <h1 className="titulo-apresentacao font-extrabold archivo-black-regular2 -mb-0 text-2xl">
            {" "}
            SEU NEGÓCIO, <br />
            ONLINE!
          </h1>
          <h2 className="subtitulo-apresentacao p-4">
            {" "}
            Você já percebeu que não da para ficar fora da internet atualmente!{" "}
            <br />
            Seja você quem for, o primeiro passo para o sucesso online começa
            com um site impressionante.
          </h2>
          <br />
          <div className="flex flex-row gap-2">
            <div
              onClick={handleWhatsappClick}
              className="cursor-pointer p-4 h-[40px] rounded-md border-blue-600 border-2 flex items-center justify-center hover:bg-blue-200"
            >
              <p className="font-bold m-0 p-0 text-blue-600">
                QUERO ESTAR ONLINE!
              </p>
            </div>
          </div>
        </div>
        <div className="md:w-[100%] min-w-[300px] flex flex-col gap-4 items-center">
          <img src={LogoFull} style={{ width: "300px", height: "auto" }} />
        </div>
      </div>
    </div>
  );
}

export default Apresentacao;
