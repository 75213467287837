import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
function Accordion({ title, content }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="accordion-container px-4">
      <div className={`accordion ${isOpen ? 'open' : ''}`}>
        <div className="accordion-header" onClick={toggleAccordion}>
          <span className='font-extrabold text-slate-900 mr-4'>{title}</span>
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="accordion-icon " />
        </div>
        {isOpen && (
          <div className="accordion-content">
            <p className='font-medium '>{content}</p>
          </div>
        )}
      </div>
    </div>
  );
}

function ProductDrawer() {
  const produtos = [
    { id: 1, title: 'Sites Personalizados', content: 'Sites personalizados, são sites mais completos, composto por multi páginas. São ideais para empresas que possui multi produtos, e necessita de maior especificação para atendimento, venda e apresentação. Neles podemos implementar qualquer tecnologia que for nescessária.' },
    { id: 2, title: 'Cartões Virtuais', content: 'E-cards (Cartões digitais) são Paginas na web, que normalmente possuem links para outros sites, redes sociais, contendo uma foto de logomarca ou de perfil, uma breve apresentação mais focada no redirecionamento, assim como cartões de visita, um E-card é uma mini aplicação que cumpre seu papel!' },
    { id: 3, title: 'Landing Pages', content: 'Landing Pages, são páginas como esta na qual você esta agora, escolhemos ela, pois se compõe de apenas uma página, comunicativa e dinâmica, direta ao ponto! São ideais para apresentação de um Produto único, Info-produtos, estabelecimentos físicos como restaurantes, barbearias etc. Assim como para apresentação pessoal e Profissional.' },
    { id: 3, title: 'Lojas e Ecommerce', content: 'E commerce são sistemas web, apresentados como um site (Loja Virtual) no qual possui sistemas de pagamento, frete, gerenciamento de estoque, envio de emais automáticos, etc. todo o que uma loja virtual precisa para existir em um só lugar!' },
    { id: 3, title: 'Aplicativos Mobile', content: 'Aqui, também trabalhamos com aplicativos, são desenvolvidos tanto para Android quanto para IOS, com eles você pode criar diversas funcionalidades, ter o seu próprio aplicativo, seja para organizar seu dia a dia, dar suporte para alguma demanda de sua empresa, ou algum produto atrativo para gerar receita através deles.' },
    // Adicione mais produtos conforme necessário
  ];
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
        <div className="p-4 flex flex-col text-slate-800 justify-center items-center">
        <h3 className='text-[18pt] mb-4 text-start archivo-black-regular3 '>Conheça mais sobre cada um!</h3>
        <div className=' flex w-full flex-col justify-center align-middle items-center p-4 bg-slate-300 rounded-lg'>
          {produtos.map((produto) => (
            <Accordion key={produto.id} title={produto.title} content={produto.content} />
          ))}
        </div>
        </div>
  )
}
export default ProductDrawer