import React, { useState, useEffect } from 'react'
import NovoPlano from './NovoPlano'
import { GenGet } from 'state/api';
import { CircularProgress } from '@mui/material';

const PlanoItem = ({ plano }) => {
  const descricaoLimitada = plano.descricao.length > 150
    ? plano.descricao.substring(0, 60) + '...'
    : plano.descricao;

  return (
    <div className='flex flex-col max-w-[200px] h-[250px] p-4 rounded-md shadow-md bg-slate-50'>
      <h4 className='text-left text-[12pt]'>{plano.nome}</h4>
      <p className='text-left text-[10pt] pl-2'>{descricaoLimitada}</p>
      <p className='text-left text-[10pt] pl-2'>R${plano.valor}</p>
      <div className='flex w-[100%] justify-end mt-auto'>
        <button className='p-1 shadow-md bg-blue-400 rounded-md hover:bg-blue-500 admin-button-small'>Ver mais</button>
      </div>
    </div>
  );
}

function PainelPlanos() {
  const [list, setList] = useState([]);
  const [planos, setPlanos] = useState([]);
  const [refresh, setRefresh]= useState(false);
  const [loading, setLoading]= useState(true);

  async function getPlanos() {
    try {
      const response = await GenGet(`/planos/index`);
      setPlanos(response);

      const planosAtivos = response.filter(plano => plano.ativo === 1);
      setList(planosAtivos);

      setLoading(false);
      setRefresh(false)
    } catch (err) {
    }
  }

  // Definir a lista inicial com planos ativos
  useEffect(() => {
      getPlanos();    
  }, []);

  // Atualiza listagem
  useEffect(() => {
    if(refresh){
      setLoading(true);
      getPlanos();
    }
  }, [refresh]);
    
  const handleChange = (e) => {     
      const value = e.target.value;
  
      const filteredPlanos = planos.filter(plano => {
          if (value === 'ativo') {
              return plano.ativo === 1;
          } else if (value === 'inativo') {
              return plano.ativo === 0;
          }
          return true; // Retorna todos os planos se nenhum filtro for aplicado
      });
  
      setList(filteredPlanos);
  };

  return (<>
    {!loading ? (
      <div className='text-[10pt]'>
      <div className='rounded-md flex w-full bg-slate-50 overflow-x-hidden overflow-y-scroll no-scrollbar'>
      <div className=' w-[100%] p-2 min-h-[35vh]'>
        <div className='flex flex-col text-left overflow-hidden'>
          <NovoPlano handleRefresh={setRefresh}/>
          
          <h2 className='input-classes text-lg'>Seus Planos</h2>
          <div className='flex flex-row justify-start gap-4'>
          <div className='flex flex-row justify-start gap-2'>
              <label className=''>Status:</label>
              <input 
                  className={` bg-slate-300 rounded m-2 flex p-1.5`}                 
                  id="ativo"
                  name="ativo"
                  type="radio"
                  value="ativo"
                  onChange={handleChange}
              />
              <label htmlFor="ativo">Ativos</label>
              <input 
                  className={` bg-slate-300 rounded m-2 flex p-1.5`}                 
                  id="inativo"
                  name="ativo"
                  type="radio"
                  value="inativo"
                  onChange={handleChange}
              />
              <label htmlFor="inativo">Inativos</label>
          </div>
          </div>
        </div>
        <div className='flex flex-wrap gap-1 flex-row'>
          {list.map((item) => {
              return <PlanoItem key={item.nome} plano={item} />;
          })}
      </div>
      </div>
      </div>
    </div>
    ) : (
      <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
          <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
              <div className='flex flex-col items-center'>
                  <CircularProgress />
              </div>
          </div>
      </div>
    )}
    </>
    
  )
}

export default PainelPlanos