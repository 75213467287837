import React, { useState, useEffect, useRef } from 'react';
import fonts from "./fonts.json";

function FontPicker({setFont}) {

    const [searchTerm, setSearchTerm] = useState("");
    const [popUpVisible, setPopUpVisible] = useState(false);
    const popUpRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (popUpRef.current && !popUpRef.current.contains(event.target)) {
            setPopUpVisible(false);
          }
        };
  
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [popUpRef]);

    const togglePopUp = () => {
        setPopUpVisible(!popUpVisible);
      };

    const renderFonts = () => {
        return Object.entries(fonts)
            .filter(([classKey, classValue]) => {
                const fontKeys = Object.keys(classValue);
                return fontKeys.some(fontKey =>
                    fontKey.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    classValue[fontKey].className.toLowerCase().includes(searchTerm.toLowerCase())
                );
            })
            .map(([classKey, classValue]) => {
                return (
                    <div key={classKey}>
                        <h3 className='text-[11pt] font-medium'>{classKey}</h3>
                        <div style={{ marginLeft: '20px' }} >
                            {renderFontsInClass(classValue)}
                        </div>
                    </div>
                );
            });
    };

    const renderFontsInClass = (fontClass) => {
        return Object.entries(fontClass)
            .filter(([fontKey, fontValue]) =>
                fontKey.toLowerCase().includes(searchTerm.toLowerCase()) ||
                fontValue.className.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map(([fontKey, fontValue]) => {
                let value = {'font':fontKey, 'className':fontValue.className}
                return (
                    <div key={fontKey} className={`${fontValue.className} cursor-pointer hover:bg-slate-200 p-1 rounded-lg text-[9pt]`} onClick={()=>setFont(fontValue.className)}>
                        {fontKey}
                    </div>
                );
            });
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div className='relative'>
            <button
                className={`bg-yellow-400 hover:bg-yellow-300 text-slate-800 font-medium p-[2px] rounded-sm shadow-md text-[9pt] w-[70px] relative italic`}
                onClick={togglePopUp}
            >
                Fonte
            </button>
            {popUpVisible && (
                <div ref={popUpRef} className='p-2 flex flex-col w-[220px] h-[260px] rounded-md shadow-lg z-10 absolute right-0 top-[40px] bg-slate-100'>
                    <input
                        className='custom-input-left sticky'
                        type="text"
                        placeholder="Search fonts..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <div className='p-2 overflow-scroll overflow-x-hidden'>
                        {renderFonts()}
                    </div>      
                </div>
            )}
        </div>
    );
}

export default FontPicker;