import React, { useState } from 'react'
import { GenPost } from 'state/api';

function DadosCliente({user_id}) {
  const [data, setData]= useState({});
  const [formData, setFormData] = useState({
    nome:                 "",
    sobrenome:            "",   
    cpf:                  "", 
  });

const [validationErrors, setValidationErrors] = useState({
  nome:                 "",
  sobrenome:            "",   
  cpf:                  "", 
  });

  const handleChange = (e) => {     
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    console.log(formData)
    setValidationErrors({
      ...validationErrors,
      [e.target.name]: e.target.value ? "" : "Campo obrigatório.",
    });
}

  const handlePost = () =>{
    async function postData() {
      const finalFormData = {...formData, user_id: user_id};
      try {
        const response = await GenPost("/clientes/store", finalFormData);
        setData(response);
        console.log(response)
      } catch (err) {
        console.log(formData);
      }
    }
    postData();
  }

  return (
    <div className='rounded-md flex w-full bg-slate-50 overflow-x-hidden overflow-y-scroll no-scrollbar'>
                    <div className=' w-[100%] p-2 '>
                        <div className='flex flex-col text-left overflow-hidden'>
                            <h2 className='input-classes text-lg'>Cadastrar dados do Cliente</h2>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Nome: 
                                </label><input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="nome"
                                    name="nome"
                                    value={formData.nome}
                                    onChange={handleChange}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Sobrenome: 
                                </label><input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="sobrenome"
                                    name="sobrenome"
                                    value={formData.sobrenome}
                                    onChange={handleChange}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>CPF: 
                                </label><input className={`min-w-[98%] bg-slate-300 rounded m-2 flex p-1.5`}                 
                                    id="cpf"
                                    name="cpf"
                                    value={formData.cpf}
                                    onChange={handleChange}  
                                    />
                            </div>

                            <div className='flex w-full justify-end gap-2 mt-4 p-4'>
                                <button className='bg-cyan-600 w-[200px] rounded hover-bg-cyan-500' onClick={handlePost}>
                                    Cadastrar
                                </button>

                            </div>
                        </div>

                    </div>
                </div>
  )
}

export default DadosCliente