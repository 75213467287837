import React from 'react';
import { GenPost } from 'state/api';

function TicketModal({ data, onClose, setRefresh }) {
  const handleSolved = async (id) => {
    try {
      await GenPost(`/tickets/update/${id}`, { resolvido: 1 });
      setRefresh(true);
      onClose();
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div className='flex flex-col rounded-md border-0'>
      <h3 className='font-semibold'>Relato de ocorrência</h3>
      <p className='text-[10pt]'><span className='font-semibold'>Usuário:</span> {data.user.name} - <span className='font-semibold'>id:</span> {data.user.name}</p>
      <p className='text-[10pt]'><span className='font-semibold'>Data:</span> {data.data}</p>
      <br />
      <p className='text-[10pt]'><span className='font-semibold'>Ticket:</span> <br /> {data.ticket}</p>
      <div className='w-full flex justify-end my-4'>
        <button onClick={() => handleSolved(data.id)} className='p-2 rounded-sm bg-slate-500 hover:bg-slate-400 text-slate-100 font-semibold h-[30px] flex justify-center align-middle items-center'>Resolvido</button>
      </div>
    </div>
  );
}

export default TicketModal;